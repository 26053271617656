import React, { useEffect, useState, useRef } from "react";
import ContactBlock from "./ContentBlock/ContactBlock";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/pro-regular-svg-icons";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import { faList } from "@fortawesome/pro-regular-svg-icons";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { faRightFromBracket } from "@fortawesome/pro-regular-svg-icons";
import { useGlobalState } from "./GlobalContext";

export default function Sidebar(props) {
    const [menuItems, setMenuItems] = useState();
    const menuItemRefs = useRef([]);
    const [intialMenuItem, setInitialMenuItem] = useState(false);
    const { loggedInUser } = useGlobalState();
    const navigate = useNavigate();

    // This useEffect is used to create a new object with the menu item titles and icons, and then set the state of menuItems to that object.
    useEffect(() => {
        if (!!props.textData) {
            const menuItemsData = {
                order_overview: {
                    "menu-item": props.textData["account/order_overview"]["menu_item_title"],
                    "menu-icon": <FontAwesomeIcon icon={faCartShopping} size="lg" />,
                    href: props.getPageUrlByName("account_orders"),
                },
                quotations: {
                    "menu-item": props.textData["account/quotations"]["menu_item_title"],
                    "menu-icon": <FontAwesomeIcon icon={faFile} size="lg" />,
                    href: props.getPageUrlByName("account_quotations"),
                },
                product_library: {
                    "menu-item": props.textData["account/product_library"]["menu_item_title"],
                    "menu-icon": <FontAwesomeIcon icon={faList} size="lg" />,
                    href: props.getPageUrlByName("product_library"),
                },
                my_details: {
                    "menu-item": props.textData["account/my_details"]["menu_item_title"],
                    "menu-icon": <FontAwesomeIcon icon={faBuilding} size="lg" />,
                    href: props.getPageUrlByName("account_details"),
                },
                logout: {
                    "menu-item": props.textData["account/my_account"]["logout"],
                    "menu-icon": <FontAwesomeIcon icon={faRightFromBracket} size="lg" />,
                    href: props.getPageUrlByName("account_logout"),
                    logout: true,
                },
            };

            if (loggedInUser.company === null) {
                delete menuItemsData.product_library;
            }

            setMenuItems(menuItemsData);
        }
    }, [props.textData]);

    // This useEffect is used to set the correct menu item to active when the page loads.
    useEffect(() => {
        if (menuItems && !intialMenuItem) {
            const index = Object.values(menuItems).findIndex((item) => item.href.path === window.location.pathname);
            if (index >= 0) {
                handleMenuItemClick(index);
                setInitialMenuItem(true);
            }
        }
    });

    const handleMenuItemClick = (index) => {
        // Remove active-item class from existing li element(s)
        menuItemRefs.current.forEach((element) => {
            element.classList.remove("active-item");
        });

        // Get the menu item object based on the clicked button text
        const menuItem = Object.values(menuItems).find(
            (item) => item["menu-item"] === menuItemRefs.current[index].querySelector("button").innerText
        );

        // If the menu item has a removeLocalStorage property, remove the local storage
        if (menuItem && menuItem.logout && menuItem.href) {
            window.localStorage.clear();
            window.location = menuItem.href.path;
        } else if (menuItem && menuItem.href) {
            // If the menu item has a href property, navigate to it
            navigate(menuItem.href.path);
        }

        // Add active-item class to clicked li element
        menuItemRefs.current[index].classList.add("active-item");
    };

    return (
        <>
            {!props.responsive && (
                <div className={"sidebar-container"}>
                    <div className="sidebar-menu">
                        <div className="sidebar-logo">
                            <Link to={"/"}>
                                <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                            </Link>
                        </div>
                        <div className="sidebar-menu-items">
                            <ul>
                                {!!menuItems &&
                                    Object.keys(menuItems).map((key, index) => (
                                        <li
                                            key={index}
                                            ref={(el) => (menuItemRefs.current[index] = el)}
                                            onClick={() => handleMenuItemClick(index)}
                                        >
                                            {menuItems[key]["menu-icon"]}
                                            <button>{menuItems[key]["menu-item"]}</button>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                    {!!props.textData && !!props.textData["content_blocks/contact"] && (
                        <ContactBlock textData={props.textData["content_blocks/contact"]} />
                    )}
                </div>
            )}
            {!!props.responsive && (
                <div className={props.responsive + "sidebar-container"}>
                    <div className="sidebar-card-label">
                        {!!props.textData && props.textData["account/order_overview"].title}
                    </div>
                    <div className="sidebar-card-content">
                        <div className="sidebar-menu">
                            <div className="sidebar-menu-items">
                                <ul>
                                    {!!menuItems &&
                                        Object.keys(menuItems).map((key, index) => (
                                            <li
                                                key={index}
                                                ref={(el) => (menuItemRefs.current[index] = el)}
                                                onClick={() => handleMenuItemClick(index)}
                                            >
                                                {menuItems[key]["menu-icon"]}
                                                <button>{menuItems[key]["menu-item"]}</button>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
