import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/pro-regular-svg-icons";
export const Accordion = ({ header, content, model }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <Fragment>
            <div className="accordion-item">
                <div className="accordion-title">
                    <div className={isActive ? "accordion-title-active" : null}>{header}</div>
                </div>
                {isActive && <div className="accordion-content">{content}</div>}
            </div>
            {!isActive && (
                <div className="accordion-actions">
                    <span className="accordion-price">
                        {" "}
                        {model.pivot.discount_multiplier != 1 && !/[a-z]/i.test(model.pivot.price) && (
                            <s>
                                {!/[a-z]/i.test(model.pivot.price) && <>€</>}
                                {!/[a-z]/i.test(model.pivot.price) &&
                                    (model.pivot.amount * model.pivot.price).toFixed(2)}
                            </s>
                        )}
                        {!isNaN(model.pivot.price) && <>€</>}
                        {typeof model.pivot.price === "string" &&
                            !/[a-z]/i.test(model.pivot.price) &&
                            (
                                parseFloat(model.pivot.price) *
                                model.pivot.amount *
                                model.pivot.discount_multiplier
                            ).toFixed(2)}
                        {typeof model.pivot.price === "string" && /[a-z]/i.test(model.pivot.price) && model.pivot.price}
                    </span>
                    <FontAwesomeIcon
                        icon={faPlus}
                        size="2x"
                        style={{ color: "#022955" }}
                        onClick={() => setIsActive(!isActive)}
                    />
                </div>
            )}
            {isActive && (
                <FontAwesomeIcon
                    icon={faMinus}
                    size="2x"
                    style={{ color: "#022955" }}
                    onClick={() => setIsActive(!isActive)}
                />
            )}
        </Fragment>
    );
};
