import { useEffect } from "react";
import { useGlobalState } from "../GlobalContext";
import RetrieveProductSettings from "../ProductSettings/RetrieveProductSettings";

const ColorUtil = (props) => {
    const { productSettings, setProductSettings, setMandatoryProductSettings } = useGlobalState();

    useEffect(() => {
        if (productSettings === null || typeof productSettings === "undefined") getProductSettings();
    }, []);

    async function getProductSettings() {
        return RetrieveProductSettings({ setProductSettings, setMandatoryProductSettings });
    }

    // HEX Color to RGB Format eg. #ffffff to rgb(255, 255, 255)
    function hexToRgb(hex) {
        if (hex === undefined || hex === null) {
            return null;
        }
        hex = hex.replace("#", "");
        const hexInt = parseInt(hex, 16);
        const r = (hexInt >> 16) & 255;
        const g = (hexInt >> 8) & 255;
        const b = hexInt & 255;
        return { r, g, b };
    }

    async function getAvailableColors() {
        // Filter through productsettings to get all colors

        const loopSettings = (data) => {
            let result = new Map();
            data.forEach((element) => {
                const colors = element.settings
                    .map((item) => item["color"])
                    .filter((item) => item !== null && item.name !== null)
                    .forEach((color) => {
                        result.set(color.name, color);
                    });
            });
            return new Promise((resolve) => {
                resolve([...result.values()]);
            });
        };

        if (productSettings === null || typeof productSettings === "undefined" || productSettings.length === 0) {
            return await getProductSettings().then((data) => {
                return loopSettings(data.productSettings);
            });
        } else {
            return await loopSettings(productSettings);
        }
    }

    // Input is the color name (eg. Default, Neon yellow, etc) and output is the color in rgb format
    async function getColorInRgb(color) {
        let result = await getAvailableColors().then((colors) => {
            if (colors.find((x) => x.name === color) !== undefined) {
                return new Promise((resolve) => {
                    resolve(hexToRgb(colors.find((x) => x.name === color).hex));
                });
            } else {
                return new Promise((resolve) => {
                    resolve("rgb(160, 160, 160)"); // Cant find color, return gray
                });
            }
        });
        return new Promise((resolve) => {
            resolve(result);
        });
    }

    return getColorInRgb;
};

export default ColorUtil;
