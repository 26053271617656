import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";

const TableQuantityInput = ({ index, model, handleQuotationModel, changedQuantity }) => {
    const [localAmount, setLocalAmount] = useState(model.amount);

    useEffect(() => {
        setLocalAmount(model.amount);
    }, [model.amount]); // Only re-run the effect if model.amount changes

    const onChange = useCallback(
        (e) => {
            changedQuantity(true);
            const newValue = e.target.value;
            if (newValue < 9999 && newValue > -1) {
                setLocalAmount(newValue); // Update local state immediately
                handleQuotationModel("quantity", model, index, newValue);
            }
        },
        [handleQuotationModel, model, index] // Add callbackChangedQuantity to the dependency array
    );

    return <input id={"amount-" + index} onChange={onChange} type="number" value={localAmount} min={1} max={9999} />;
};

const areEqual = (prevProps, nextProps) => {
    return prevProps.model.amount === nextProps.model.amount && prevProps.index === nextProps.index;
};

export default React.memo(TableQuantityInput, areEqual);
