import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../css/app.scss";

export default function Page(props) {
    const { slug } = useParams();
    const { textData } = props;
    const [pageData, setPageData] = useState(null);
    const [pageError, setPageError] = useState(null);

    useEffect(() => {
        const fetchPageData = async () => {
            try {
                const response = await axios.get(window.location.origin + "/api/page/" + slug);
                setPageData(response.data);
            } catch (error) {
                setPageError(error);
                document.title = "YourOceanz - Pagina niet gevonden";
            }
        };

        const urlPath = window.location.pathname;
        console.log(urlPath);

        if (urlPath === "/thank-you" || urlPath === "/thank-you-payment-pending") {
            setTimeout(() => {
                window.location.href = "/";
            }, 5000);
        }

        fetchPageData();
    }, []);

    useEffect(() => {
        if (pageData && !pageError) {
            document.title = `YourOceanz - ${pageData.title}`;
        } else if (!pageData && pageError && textData) {
            document.title = `YourOceanz - ${textData["errors/page_not_found"]["page_title"]}`;
        }
    }, [pageData, pageError, textData]);

    return (
        <div className={"root"}>
            <div className={"screen-left"}>&nbsp;</div>
            <div className={"screen-right"}>
                {pageData && (
                    <div className="page-content-container">
                        <div className="block-head">
                            <h1>{pageData.title}</h1>

                            <div dangerouslySetInnerHTML={{ __html: pageData.content }} />
                            <button
                                className="btn-thank-you"
                                onClick={() => {
                                    window.location.href = "/";
                                }}
                            >
                                Return to homepage
                            </button>
                        </div>
                    </div>
                )}
                {!pageData && pageError && textData && (
                    <div className="page-content-container">
                        <div className="block-head">
                            <h1>{textData["errors/page_not_found"]["title"]}</h1>

                            <div>{textData["errors/page_not_found"]["content"]}</div>
                            <div>
                                <a href={"/"} className={"button"}>
                                    {textData["errors/page_not_found"]["button"]}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
