import Table from "../Table/Table";
import ContactBlock from "../ContentBlock/ContactBlock";
import axios from "axios";
import SearchField from "./SearchField";
import React, { useEffect, useState, useCallback } from "react";
import { useGlobalState } from "../GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";

export default function OrderOverview(props) {
    const { textData, responsive } = props;
    const { loggedInUser } = useGlobalState();
    let [orderData, setOrderData] = useState([]);
    let [allOrders, setAllOrders] = useState([]);
    const [openPopup, setOpenPopup] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        debouncedGetQuotations(currentPage);
    }, [currentPage]);

    useEffect(() => {
        loadSelectedOrder();
    }, [orderData]);

    const loadSelectedOrder = () => {
        if (openPopup !== null) return;
        if (typeof orderData[0]?.orders !== "undefined" && orderData[0]?.orders.length !== 0) {
            // get id from url param
            let urlParams = new URLSearchParams(window.location.search);
            let id = urlParams.get("id");

            if (id !== null) {
                for (let orderId in orderData[0]?.orders) {
                    if (orderData[0]?.orders[orderId].project_number == id) {
                        setOpenPopup({ id: orderData[0]?.orders[orderId].project_number, type: "order" });
                        break;
                    }
                }
            }
        }
    };

    const getQuotations = (page) => {
        setLoading(true);
        // Make a GET request for the uploaded models
        const quotationAPI = window.location.origin + "/api/orders";
        axios
            .get(quotationAPI, {
                params: {
                    user_id: loggedInUser.user.id,
                    page: page,
                    per_page: 5,
                },
            })
            .then((response) => {
                let result = response.data;
                result.section = "orders";
                setOrderData([result]);
                setAllOrders([result]);
                setTotalPages(response.data.total_pages); // Assuming the API returns total_pages
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    // Create a debounced version of the getQuotations function
    const debouncedGetQuotations = useCallback(
        debounce((page) => getQuotations(page), 500),
        []
    );

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <>
            {typeof orderData[0]?.orders !== "undefined" && orderData[0]?.orders.length !== 0 && (
                <>
                    <div className="orders-overview-title">
                        <SearchField
                            textData={textData}
                            orderData={orderData}
                            setOrderData={setOrderData}
                            allOrders={allOrders}
                            setLoading={setLoading}
                            setAllOrders={setAllOrders}
                            setTotalPages={setTotalPages}
                            currentPage={currentPage}
                        />
                    </div>
                    <Table
                        responsive={responsive}
                        data={orderData}
                        textData={!!textData && textData["account/order_overview"]}
                        textDataLabel={!!textData && textData["sections/label"]}
                        navigateToPageName={props.navigateToPageName}
                        allTextData={!!textData && textData}
                        openPopup={openPopup}
                        loading={loading}
                    />
                    <div className="pagination-controls">
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <span>
                            &nbsp;Page {currentPage} of {totalPages}&nbsp;
                        </span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </>
            )}
            {(typeof orderData[0]?.orders === "undefined" || orderData[0]?.orders.length === 0) && !loading && (
                <h2>{!!textData && textData["account/order_overview"]["table"]["no_data"]}</h2>
            )}
            {loading && orderData.length === 0 && (
                <div className="modelviewer-loading centered">
                    <span className="spinner"></span>
                </div>
            )}
            {!!responsive && !!textData && !!textData["content_blocks/contact"] && (
                <ContactBlock responsive={responsive} textData={textData["content_blocks/contact"]} />
            )}
        </>
    );
}
