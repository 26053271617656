import React from "react";
import { useState, useEffect } from "react";
import InformationPopup from "../Popups/InformationPopup";

export default function InfoButton(props) {
    const [infoPopup, setInfoPopup] = useState(null);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        let info = props.info;
        if (info.length > 0) {
            setInfo(info[0]);
        }
    }, []);

    useEffect(() => {
        if (!infoPopup) {
            document.querySelector("html").style.overflow = "";
        } else {
            document.querySelector("html").style.overflow = "hidden";
        }
    }, [infoPopup]);

    function closeInfoPopup() {
        setInfoPopup(null);
        let modals = document.getElementsByClassName("modal-background");
        if (typeof modals !== "undefined" && modals.length > 0) {
            modals[0].remove();
        }
    }

    function onInfoClick(event) {
        const infoText = event.target.dataset.explanation ?? "";
        const category = event.target.dataset.category ?? "";
        const category_header_image = event.target.dataset.header ? `${event.target.dataset.header}` : "";
        const read_more_link = event.target.dataset.readmore ?? "";
        const pdf_path = event.target.dataset.pdf ? `${event.target.dataset.pdf}` : "";
        const popup = (
            <InformationPopup
                content={
                    <div>
                        <div className="header-img-container">
                            <img className="header-img" src={category_header_image} alt="" />
                            <h1>{category}</h1>
                        </div>
                        <div className="info-text">
                            <span className="close-btn" onClick={closeInfoPopup}></span>
                            <div dangerouslySetInnerHTML={{ __html: infoText }} />
                        </div>
                        <div className="info-footer">
                            <a className="read-more" href={read_more_link} target={"_blank"}>
                                {props.textData.read_more}
                            </a>
                            <a className="download" href={pdf_path} download>
                                {props.textData.download_pdf}
                            </a>
                        </div>
                    </div>
                }
            />
        );
        // create big gray background to contrast the modal
        // get body element
        const body = document.getElementsByTagName("body")[0];
        const modalBackground = document.createElement("div");
        modalBackground.classList.add("modal-background");
        modalBackground.addEventListener("click", closeInfoPopup);
        body.appendChild(modalBackground);
        setInfoPopup(popup);
    }

    return (
        <>
            {info !== null && (
                <>
                    <span
                        className="product-setting-info "
                        onClick={onInfoClick}
                        data-explanation={info.explanation.en}
                        data-header={info.header_image_url}
                        data-readmore={info.read_more_link}
                        data-category={info.category_name}
                        data-pdf={info.pdf_url}
                    >
                        i
                    </span>
                    {infoPopup !== null && <div>{infoPopup}</div>}
                </>
            )}
            {info === null && <br />}
        </>
    );
}
