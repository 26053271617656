export const getSettingsId = (selectedSettings, productSettings) => {
    if (
        (productSettings !== undefined && Object.keys(productSettings).length === 0) ||
        typeof selectedSettings === "undefined" ||
        selectedSettings === null
    ) {
        return "";
    }

    // copy of selectedSettings (to avoid mutation of original object)
    const selectedSettingsCopy = { ...selectedSettings };

    // filter clinicalUse and practitioner and case from selectedSettings
    if (selectedSettingsCopy?.clinicalUse !== null && selectedSettingsCopy?.clinicalUse !== undefined) {
        delete selectedSettingsCopy.clinicalUse;
    }
    if (selectedSettingsCopy?.practitioner !== null && selectedSettingsCopy?.practitioner !== undefined) {
        delete selectedSettingsCopy.practitioner;
    }
    if (selectedSettingsCopy?.case !== null && selectedSettingsCopy?.case !== undefined) {
        delete selectedSettingsCopy.case;
    }

    // if sterile is "true" or "false" convert to boolean
    if (selectedSettingsCopy?.sterile === "true" || selectedSettingsCopy?.sterile === "false") {
        selectedSettingsCopy.sterile = selectedSettingsCopy.sterile === "true";
    }

    let result = "";
    productSettings.forEach((application) => {
        if (application.name === selectedSettingsCopy.application) {
            result = application.settings.filter((setting) => {
                let settingClinicalUse = selectedSettingsCopy.clinicalUse;
                if (settingClinicalUse === "No") {
                    settingClinicalUse = false;
                } else if (settingClinicalUse === "Yes") {
                    settingClinicalUse = true;
                }

                let settingSterile = selectedSettingsCopy.sterile;
                if (settingSterile === "No") {
                    settingSterile = false;
                } else if (settingSterile === "Yes") {
                    settingSterile = true;
                }

                let settingCustom = selectedSettingsCopy.custom;
                if (settingCustom === "No") {
                    settingCustom = false;
                } else if (settingCustom === "Yes") {
                    settingCustom = true;
                }

                let settingIntendedUse = selectedSettingsCopy.intendedUse;
                if (settingIntendedUse === "No") {
                    settingIntendedUse = false;
                } else if (settingIntendedUse === "Yes") {
                    settingIntendedUse = true;
                }

                const coatingCheck =
                    setting.coating === selectedSettingsCopy.coating ||
                    selectedSettingsCopy.coating == null ||
                    selectedSettingsCopy.coating === "";
                const materialCheck =
                    setting.material.name === selectedSettingsCopy.material ||
                    selectedSettingsCopy.material == null ||
                    selectedSettingsCopy.material.name === null ||
                    selectedSettingsCopy.material.name === "";
                const orientationCheck =
                    setting.orientation === selectedSettingsCopy.orientation ||
                    selectedSettingsCopy.orientation == null ||
                    selectedSettingsCopy.orientation === "";
                const polishCheck =
                    setting.polish === selectedSettingsCopy.polish ||
                    selectedSettingsCopy.polish == null ||
                    selectedSettingsCopy.polish === "";
                const colorCheck =
                    setting.color.name === selectedSettingsCopy.color ||
                    selectedSettingsCopy.color == null ||
                    selectedSettingsCopy.color.name === null ||
                    selectedSettingsCopy.color === "";
                const sterileCheck =
                    setting.sterile === settingSterile ||
                    selectedSettingsCopy.sterile == null ||
                    selectedSettingsCopy.sterile === "";
                const intendedUseCheck =
                    setting.intendedUse === settingIntendedUse ||
                    selectedSettingsCopy.intendedUse == null ||
                    selectedSettingsCopy.intendedUse === "";
                const clinicalUseCheck =
                    setting.clinicalUse === settingClinicalUse ||
                    selectedSettingsCopy.clinicalUse == null ||
                    selectedSettingsCopy.clinicalUse === "";
                const customCheck =
                    setting.custom === settingCustom ||
                    selectedSettingsCopy.custom == null ||
                    selectedSettingsCopy.custom === "";

                // log all checks
                // console.log({
                //     "coatingCheck": coatingCheck,
                //     "materialCheck": materialCheck,
                //     "orientationCheck": orientationCheck,
                //     "polishCheck": polishCheck,
                //     "colorCheck": colorCheck,
                //     "sterileCheck": sterileCheck,
                //     "intendedUseCheck": intendedUseCheck,
                //     "clinicalUseCheck": clinicalUseCheck,
                //     "customCheck": customCheck
                // },
                // {
                //     "setting": setting,
                //     "selectedSettings": selectedSettings
                // })

                return (
                    coatingCheck &&
                    materialCheck &&
                    orientationCheck &&
                    polishCheck &&
                    colorCheck &&
                    sterileCheck &&
                    intendedUseCheck &&
                    clinicalUseCheck &&
                    customCheck
                );
            });
        }
    });

    if (result && result !== "" && result.length > 0) {
        return result[0].id;
    }

    return "";
};
