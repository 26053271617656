import { useState, useEffect } from "react";
import InformationPopup from "../../Popups/InformationPopup";
import InfoButton from "../InfoButton";

export default function Coating(props) {
    const [selected, setSelected] = useState(null);
    const [buttons, setButtons] = useState([]);
    const [infoPopup, setInfoPopup] = useState(null);
    const [deliveryTimeText, setDeliveryTimeText] = useState("");
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        renderButtons();
    }, [props.materials]);

    useEffect(() => {
        if (clicked === true) {
            setTimeout(() => {
                // Submit the form
                submit();
            }, 100);
        }
    }, [selected]);

    function clickSelect(e, i) {
        select(e, i);
        setClicked(true);
    }

    function closeInfoPopup() {
        setInfoPopup(null);
        let modals = document.getElementsByClassName("modal-background");
        if (typeof modals !== "undefined" && modals.length > 0) {
            modals[0].remove();
        }
    }

    function onInfoClick(event) {
        const infoText = event.target.dataset.explanation ?? "";
        const category = event.target.dataset.category ?? "";
        const category_header_image = event.target.dataset.header ? `./storage/${event.target.dataset.header}` : "";
        const read_more_link = event.target.dataset.readmore ?? "";
        const pdf_path = event.target.dataset.pdf ? `./storage/${event.target.dataset.pdf}` : "";
        const popup = (
            <InformationPopup
                content={
                    <div>
                        <div className="header-img-container">
                            <img className="header-img" src={category_header_image} alt="" />
                            <h1>{category}</h1>
                        </div>
                        <div className="info-text">
                            <span className="close-btn" onClick={closeInfoPopup}></span>
                            <div dangerouslySetInnerHTML={{ __html: infoText }} />
                        </div>
                        <div className="info-footer">
                            <a className="read-more" href={read_more_link}>
                                {props.textData.read_more}
                            </a>
                            <a className="download" href={pdf_path} download>
                                {props.textData.download_pdf}
                            </a>
                        </div>
                    </div>
                }
            />
        );
        // create big gray background to contrast the modal
        // get body element
        const body = document.getElementsByTagName("body")[0];
        const modalBackground = document.createElement("div");
        modalBackground.classList.add("modal-background");
        modalBackground.addEventListener("click", closeInfoPopup);
        body.appendChild(modalBackground);
        setInfoPopup(popup);
    }

    function renderButtons() {
        let categories = props.materials;
        let buttonsArray = [];

        if (categories !== null) {
            buttonsArray = Object.keys(categories).map((category, i) => {
                return (
                    <div className="material-category-container" key={i}>
                        <div className="category-h2">
                            <h2 className="h2-info-parent">{categories[category][0].category_name}</h2>
                            <InfoButton
                                info={props.productSettingExplanations
                                    .map((info) => {
                                        if (info.type === "material_sls" && categories[category][0].category_id == 1) {
                                            info.category_name = categories[category][0].category_name;
                                        } else if (
                                            info.type === "material_slm" &&
                                            categories[category][0].category_id == 2
                                        ) {
                                            info.category_name = categories[category][0].category_name;
                                        }
                                        return info;
                                    })
                                    .filter((info) => {
                                        if (info.type === "material_sls" && categories[category][0].category_id == 1) {
                                            return true;
                                        } else if (
                                            info.type === "material_slm" &&
                                            categories[category][0].category_id == 2
                                        ) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    })}
                                textData={props.infoTextData}
                            />
                        </div>
                        <br></br>
                        {categories[category].map((value, j) => {
                            return (
                                <div className="inner-btn" key={j}>
                                    {value.image_url !== null && value.image_url !== undefined ? (
                                        <img src={value.image_url} />
                                    ) : (
                                        <span className="color-preview" style={{ backgroundColor: "#808080" }}></span>
                                    )}
                                    <button
                                        className={`btn btn-option
                                        ${props.config.material === null && j === 0 ? "selected" : ""}
                                        ${props.config.material === value.name ? "selected" : ""}`}
                                        onClick={(e) => clickSelect(e, i)}
                                        deliverytime={value.delivery_time}
                                    >
                                        {value.name}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                );
            });
        }
        setButtons(buttonsArray);
        setTimeout(() => {
            checkIfSelected();
        }, 100);
    }

    function checkIfSelected() {
        // Check if there is a selected button and set delivery time text if needed
        const container = document.getElementsByClassName("materials-settings-details");
        if (typeof container !== "undefined" && container !== null && container.length > 0) {
            const buttons = container[0].getElementsByClassName("btn-option");
            for (let i = 0; i < buttons.length; i++) {
                if (buttons[i].classList.contains("selected")) {
                    if (
                        buttons[i].getAttribute("deliverytime") !== "" &&
                        buttons[i].getAttribute("deliverytime") !== null &&
                        typeof buttons[i].getAttribute("deliverytime") !== "undefined"
                    ) {
                        setDeliveryTimeText(
                            `${props.textData.delivery_estimate}: ${buttons[i].getAttribute("deliverytime")} ${
                                parseInt(buttons[i].getAttribute("deliverytime")) > 1
                                    ? props.textData.days
                                    : props.textData.day
                            }`
                        );
                    } else {
                        setDeliveryTimeText(props.textData.no_estimate_available);
                    }
                }
            }
        }
    }

    function select(e, i) {
        // Remove selected class from all buttons
        const container = document.getElementsByClassName("materials-settings-details");
        const buttons = container[0].getElementsByClassName("btn-option");
        for (let i = 0; i < buttons.length; i++) buttons[i].classList.remove("selected");
        // Add selected class to clicked button
        setSelected(e.target);
        e.target.classList.add("selected");
        // Set delivery time text
        if (
            e.target.getAttribute("deliverytime") !== "" &&
            e.target.getAttribute("deliverytime") !== null &&
            typeof e.target.getAttribute("deliverytime") !== "undefined"
        ) {
            setDeliveryTimeText(`${props.textData.delivery_estimate}: ${e.target.getAttribute("deliverytime")}`);
        } else {
            setDeliveryTimeText(props.textData.no_estimate_available);
        }
        //setDeliveryTimeText(props.materials[i][0].delivery_time);
    }

    function submit() {
        if (selected !== null) {
            props.updateSetting("material", selected.innerText);
        } else {
            // Check if there is a button with state selected
            let btn = document.getElementsByClassName("btn-option selected")[0];
            if (btn !== null && typeof btn !== "undefined") {
                props.updateSetting("material", btn.innerText);
            }
        }
        props.goNext("overview");
        props.clearSettingsThatAreNotAllowed(false, "material");
    }

    function goBack() {
        props.goNext("application");
    }

    return (
        <div className="materials-settings-details">
            <h1>{props.textData.title}</h1>
            <br />
            <p className="subtitle">{props.textData.subtitle}</p>
            <br />
            <div className="material-category-btns">{buttons}</div>
            {/*{props.materials !== null && typeof props.materials !== "undefined" ? (*/}
            {/*    <p className="deliverytime">{deliveryTimeText}</p>*/}
            {/*) : (*/}
            {/*    <p>{props.textData.not_available}</p>*/}
            {/*)}*/}
        </div>
    );
}
