import React, { useRef, useState } from "react";
import { Formik, Field } from "formik";
import Button from "../Button";
import { handleInputType } from "../Functions/functions";
import { useGlobalState } from "../GlobalContext";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.min.css";

export default function Settings(props) {
    const { loggedInUser, setLoggedInUser } = useGlobalState();
    const [countries, setCountries] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const hasCompany = useRef(false);
    const { textData, responsive } = props;

    const fetchLoginData = async () => {
        try {
            const response = await axios.get(window.location.origin + "/currentuser");
            return new Promise((resolve, reject) => {
                resolve(response.data);
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // Get countries
        const fetchCountries = async () => {
            try {
                const response = await axios.get(window.location.origin + "/api/countries");
                const error = response.data.error;
                if (!error) {
                    return new Promise((resolve) => {
                        resolve(response.data.countries);
                    });
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchCountries().then((result) => {
            let countries = [];
            result.forEach((country) => {
                countries.push({
                    value: country,
                    label: country,
                });
            });
            setCountries(countries);
        });
    }, []);

    const getInitCompanyValues = () => {
        if (loggedInUser.company === null || typeof loggedInUser.company === "undefined") {
            let company = {
                company_name: typeof loggedInUser?.user?.company === "undefined" ? "" : loggedInUser?.user?.company,
                vat: typeof loggedInUser?.user?.btwnumber === "undefined" ? "" : loggedInUser?.user?.btwnumber,
                coc: typeof loggedInUser?.user?.kvk === "undefined" ? "" : loggedInUser?.user.kvk,
                country: typeof loggedInUser?.user?.country === "undefined" ? "" : loggedInUser?.user.country,
                postal_code: typeof loggedInUser?.user?.zipcode === "undefined" ? "" : loggedInUser?.user.zipcode,
                house_number:
                    typeof loggedInUser?.user?.streetnumber === "undefined" ? "" : loggedInUser?.user.streetnumber,
                street_name: typeof loggedInUser?.user?.street === "undefined" ? "" : loggedInUser?.user.street,
                city: typeof loggedInUser?.user?.city === "undefined" ? "" : loggedInUser?.user.city,
                additional_address_info:
                    typeof loggedInUser?.user?.additional_address_information === "undefined"
                        ? ""
                        : loggedInUser?.user.additional_address_information,
            };
            return company;
        } else {
            let company = {
                company_name: typeof loggedInUser?.company?.name === "undefined" ? "" : loggedInUser?.company.name,
                vat: typeof loggedInUser?.company?.vat_number === "undefined" ? "" : loggedInUser?.company.vat_number,
                coc: typeof loggedInUser?.company?.kvk_number === "undefined" ? "" : loggedInUser?.company.kvk_number,
                country: typeof loggedInUser?.company?.country === "undefined" ? "" : loggedInUser?.company.country,
                postal_code: typeof loggedInUser?.company?.zip === "undefined" ? "" : loggedInUser?.company.zip,
                house_number:
                    typeof loggedInUser?.company?.house_number === "undefined"
                        ? ""
                        : loggedInUser?.company.house_number,
                street_name: typeof loggedInUser?.company?.address === "undefined" ? "" : loggedInUser?.company.address,
                city: typeof loggedInUser?.company?.city === "undefined" ? "" : loggedInUser?.company.city,
                additional_address_info:
                    typeof loggedInUser?.company?.additional_address_information === "undefined"
                        ? ""
                        : loggedInUser?.company.additional_address_information,
            };
            hasCompany.current = true;
            return company;
        }
    };

    useEffect(() => {
        setInitialValues({
            ...{
                firstname: loggedInUser.user.first_name === null ? "" : loggedInUser.user.first_name,
                lastname: loggedInUser.user.last_name === null ? "" : loggedInUser.user.last_name,
                job_title: loggedInUser.user.job_title === null ? "" : loggedInUser.user.job_title,
                tel_phone: loggedInUser.user.phone === null ? "" : loggedInUser.user.phone,
                mobile_phone: loggedInUser.user.mobile_phone === null ? "" : loggedInUser.user.mobile_phone,
                email: loggedInUser.user.email === null ? "" : loggedInUser.user.email,
            },
            ...getInitCompanyValues(),
        });
    }, [loggedInUser]);

    const handleSubmit = (values) => {
        const formData = {
            firstname: values.firstname,
            lastname: values.lastname,
            job_title: values.job_title,
            tel_phone: values.tel_phone,
            mobile_phone: values.mobile_phone,
            email: values.email,
            company_name: values.company_name,
            vat: values.vat,
            coc: values.coc,
            country: values.country,
            postal_code: values.postal_code,
            house_number: values.house_number,
            street_name: values.street_name,
            city: values.city,
            additional_address_info: values.additional_address_info,
        };

        // Get all formData that is not null not undefined and not empty
        let filledFormData = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key] !== null && typeof formData[key] !== "undefined") {
                filledFormData[key] = formData[key];
            }
        });

        let p1 = saveUserDetails(formData);
        let p2 = hasCompany.current ? saveCompanyData(formData) : new Promise((resolve) => resolve());

        Promise.all([p1, p2])
            .then((values) => {
                toast.success("Je gegevens zijn opgeslagen!");
                fetchLoginData().then((data) => {
                    setLoggedInUser(data); // Get new user data (since its updated)
                });
            })
            .catch((error) => {
                console.log(error.response.data.errors);
                // loop over objects
                Object.entries(error.response.data.errors).forEach(([key, value]) => {
                    toast.error(value[0]);
                });
            });
    };

    const saveUserDetails = (formValues) => {
        if (loggedInUser.user.id === null) {
            return new Promise((resolve) => {
                resolve();
            });
        }

        let payload = {
            id: loggedInUser.user.id,
        };

        // User
        if (typeof formValues.firstname !== "undefined" && formValues.firstname !== null) {
            payload.firstname = formValues.firstname;
        }
        if (typeof formValues.lastname !== "undefined" && formValues.lastname !== null) {
            payload.lastname = formValues.lastname;
        }
        if (typeof formValues.job_title !== "undefined") {
            payload.job_title = formValues.job_title;
        }
        if (typeof formValues.tel_phone !== "undefined") {
            payload.phone = formValues.tel_phone;
        }
        if (typeof formValues.mobile_phone !== "undefined") {
            payload.mobile_phone = formValues.mobile_phone;
        }
        if (typeof formValues.email !== "undefined" && formValues.email !== null) {
            payload.email = formValues.email;
        }

        // Company
        if (typeof formValues.company_name !== "undefined" && formValues.company_name !== null) {
            payload.company = formValues.company_name;
        }
        if (typeof formValues.vat !== "undefined" && formValues.vat !== null) {
            payload.btwnumber = formValues.vat;
        }
        if (typeof formValues.coc !== "undefined" && formValues.coc !== null) {
            payload.kvk = formValues.coc;
        }
        if (typeof formValues.country !== "undefined" && formValues.country !== null) {
            payload.country = formValues.country;
        }
        if (typeof formValues.postal_code !== "undefined" && formValues.postal_code !== null) {
            payload.zipcode = formValues.postal_code;
        }
        if (typeof formValues.house_number !== "undefined" && formValues.house_number !== null) {
            payload.streetnumber = formValues.house_number;
        }
        if (typeof formValues.street_name !== "undefined" && formValues.street_name !== null) {
            payload.street = formValues.street_name;
        }
        if (typeof formValues.city !== "undefined" && formValues.city !== null) {
            payload.city = formValues.city;
        }
        if (typeof formValues.additional_address_info !== "undefined") {
            payload.additional_address_info = formValues.additional_address_info;
        }
        // Save user details data
        return axios.put(window.location.origin + "/api/user", payload);
    };

    const saveCompanyData = (formValues) => {
        let payload = {
            company_id: loggedInUser.company.id,
        };

        if (typeof formValues.company_name !== "undefined" && formValues.company_name !== null) {
            payload.name = formValues.company_name;
        }
        if (typeof formValues.vat !== "undefined" && formValues.vat !== null) {
            payload.vat_number = formValues.vat;
        }
        if (typeof formValues.coc !== "undefined" && formValues.coc !== null) {
            payload.kvk_number = formValues.coc;
        }
        if (typeof formValues.country !== "undefined" && formValues.country !== null) {
            payload.country = formValues.country;
        }
        if (typeof formValues.postal_code !== "undefined" && formValues.postal_code !== null) {
            payload.zip = formValues.postal_code;
        }
        if (typeof formValues.house_number !== "undefined" && formValues.house_number !== null) {
            payload.house_number = formValues.house_number;
        }
        if (typeof formValues.street_name !== "undefined" && formValues.street_name !== null) {
            payload.street = formValues.street_name;
        }
        if (typeof formValues.city !== "undefined" && formValues.city !== null) {
            payload.city = formValues.city;
        }
        if (typeof formValues.additional_address_info !== "undefined" && formValues.additional_address_info !== null) {
            payload.additional_address_information = formValues.additional_address_info;
        }

        // Save company data
        return axios.put(window.location.origin + "/api/company", payload);
    };

    return (
        <div
            className={!!responsive ? responsive + "main-form-container my-details-container" : "my-details-container"}
        >
            {!!textData && countries.length > 0 && (
                <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
                    {({ values, handleSubmit, handleChange, setFieldValue }) => (
                        <>
                            {!!textData["account/my_details"]["forms"] &&
                                Object.values(textData["account/my_details"]["forms"]).map((form, index) => (
                                    <div key={index} className="form-container">
                                        <h2>{form.title}</h2>
                                        <form id="my-details-form" onSubmit={handleSubmit}>
                                            {Object.keys(form.data).length > 0 && (
                                                <div
                                                    className={
                                                        !!responsive
                                                            ? responsive + "form-group form-group"
                                                            : "form-group"
                                                    }
                                                >
                                                    {Object.entries(form.data).map(
                                                        ([fieldName, fieldLabel], fIndex) => (
                                                            <React.Fragment key={fIndex}>
                                                                {fieldName === "country" && (
                                                                    <React.Fragment key={index}>
                                                                        <Select
                                                                            id="country"
                                                                            name="country"
                                                                            multiple={false}
                                                                            options={countries}
                                                                            placeholder={
                                                                                textData["account/my_details"][
                                                                                    "select_country"
                                                                                ]
                                                                            }
                                                                            defaultValue={{
                                                                                value: initialValues.country,
                                                                                label: initialValues.country,
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setFieldValue("country", e.value);
                                                                            }}
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    primary: "#022955",
                                                                                    color: "#022955",
                                                                                    borderColor: "#dadada",
                                                                                    "&:hover": {
                                                                                        borderColor: "white",
                                                                                        color: "white",
                                                                                    },
                                                                                },
                                                                            })}
                                                                            styles={{
                                                                                control: (baseStyles, state) => ({
                                                                                    ...baseStyles,
                                                                                    borderColor: "#dadada",
                                                                                    color: "#022955",
                                                                                    ":hover": {
                                                                                        borderColor: "#2461ce",
                                                                                        color: "white",
                                                                                    },
                                                                                    fontFamily: "sans-serif",
                                                                                    fontSize: "14px",
                                                                                }),
                                                                                input: (base) => ({
                                                                                    ...base,
                                                                                    color: "#022955",
                                                                                }),
                                                                                singleValue: (base) => ({
                                                                                    ...base,
                                                                                    color: "#022955",
                                                                                }),
                                                                            }}
                                                                        />
                                                                    </React.Fragment>
                                                                )}
                                                                {!fieldName.startsWith("diff") &&
                                                                    fieldName !== "country" && (
                                                                        <div>
                                                                            <label htmlFor={`${fieldName}`}>
                                                                                {fieldLabel}
                                                                            </label>
                                                                            <input
                                                                                id={`${fieldName}`}
                                                                                name={`${fieldName}`}
                                                                                type={handleInputType(fieldName)}
                                                                                onChange={handleChange}
                                                                                value={
                                                                                    values[fieldName] === null
                                                                                        ? ""
                                                                                        : values[fieldName]
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                ))}
                            <Button
                                navigateToPageName={props.navigateToPageName}
                                data={{
                                    class: "save-button",
                                    name: "save-details",
                                    form: "my-details-form",
                                    type: "submit",
                                    text: textData["account/my_details"]["save_button"],
                                }}
                            />
                        </>
                    )}
                </Formik>
            )}
        </div>
    );
}
