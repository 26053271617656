import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Overview(props) {
    const [subtitle, setSubtitle] = useState("");

    const applications = {
        retail: "Retail & Industrial",
        industrial: "Retail & Industrial",
        food: "Food Contact",
        dental: "Dental",
        medical: "Medical",
    };

    useEffect(() => {
        if (props.type === "coating") {
            return;
        }

        //chooseSetting(getFirstAllowedOption());
        if (typeof props.config !== "undefined" && props.config[props.type] !== null) {
            if (props.type === "sterile" || props.type === "clinicalUse") {
                setSubtitle(
                    props.config[props.type] === true || props.config[props.type] === "true"
                        ? props.textData.yes_btn
                        : props.textData.no_btn
                );
            } else {
                setSubtitle(props.config[props.type]);
            }
        }
    }, [props.config]);

    // Get first allowed option for a given type (application, color, etc.)
    // function getFirstAllowedOption() {
    //     let allowed = getAllowedChoices();
    //     if (allowed.length > 0) {
    //         return allowed[0];
    //     }
    //     return '-';
    // }

    function routeTo() {
        props.setCurrentScreen(props.type);
    }

    return (
        <>
            {props.type !== "coating" && (
                <div className={"product-setting-btn"}>
                    <button
                        className={`btn btn-primary ${subtitle ? "filled" : ""}`}
                        onClick={routeTo}
                        disabled={props.disabled && !subtitle}
                    >
                        <span className="setting-title">{props.title}</span>
                        <br />
                        <span className="setting-subtitle">{subtitle ? subtitle : "-"}</span>
                    </button>
                </div>
            )}
        </>
    );
}
