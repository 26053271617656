import Table from "../Table/Table";
import React, { useEffect, useState, useCallback } from "react";
import ContactBlock from "../ContentBlock/ContactBlock";
import axios from "axios";
import { useGlobalState } from "../GlobalContext";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function QuotationOverview(props) {
    const { loggedInUser } = useGlobalState();
    const { textData, responsive } = props;
    const [loading, setLoading] = useState(true);
    const [quotationData, setQuotationData] = useState([]);
    const [openPopup, setOpenPopup] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        // Check in url if a message is given
        let urlParams = new URLSearchParams(window.location.search);
        let message = urlParams.get("message");
        if (typeof message !== "undefined" && message !== null) {
            // Show notification
            message = atob(message);
            toast.error(message);
        }

        getQuotations(currentPage);
    }, [currentPage]);

    useEffect(() => {
        loadSelectedQuotation();
    }, [quotationData]);

    const loadSelectedQuotation = () => {
        if (openPopup !== null) return;
        if (typeof quotationData[0]?.orders !== "undefined" && quotationData[0]?.orders.length !== 0) {
            // get id from url param
            let urlParams = new URLSearchParams(window.location.search);
            let id = urlParams.get("id");

            if (id !== null) {
                for (let orderId in quotationData[0]?.orders) {
                    if (quotationData[0]?.orders[orderId].project_number == id) {
                        setOpenPopup({ id: quotationData[0]?.orders[orderId].project_number, type: "quotation" });
                        break;
                    }
                }
            }
        }
    };

    const fetchLoginData = async () => {
        try {
            const response = await axios.get(window.location.origin + "/currentuser");
            return new Promise((resolve, reject) => {
                resolve(response.data);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const getQuotations = (page) => {
        if (typeof loggedInUser !== "undefined") {
            getQuotationData(loggedInUser, page);
        } else {
            fetchLoginData().then((user) => {
                getQuotationData(user, page);
            });
        }
    };

    const getQuotationData = (user, page) => {
        setLoading(true);
        // Make a GET request for the uploaded models
        const quotationAPI = window.location.origin + "/api/quotations";
        axios
            .get(quotationAPI, {
                params: {
                    user_id: user.id,
                    page: page,
                    per_page: 5,
                },
            })
            .then((response) => {
                let result = response.data;
                result.section = "savedQuotation";
                setQuotationData([result]);
                setTotalPages(response.data.total_pages); // Set total pages from API response
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    // Create a debounced version of the getQuotations function
    const debouncedGetQuotations = useCallback(
        debounce((page) => getQuotations(page), 500),
        []
    );

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <>
            {typeof quotationData[0]?.orders !== "undefined" && quotationData[0]?.orders.length !== 0 && (
                <>
                    <h1 style={responsive ? { margin: "0 10px" } : {}}>
                        {textData["account/quotations"]["table"]["title"]}
                    </h1>
                    <Table
                        responsive={responsive}
                        data={quotationData}
                        textData={!!textData && textData["account/quotations"]}
                        textDataLabel={!!textData && textData["sections/label"]}
                        navigateToPageName={props.navigateToPageName}
                        allTextData={!!textData && textData}
                        openPopup={openPopup}
                        loading={loading}
                    />
                    <div className="pagination-controls">
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </>
            )}
            {loading && quotationData.length === 0 && (
                <div className="modelviewer-loading centered">
                    <span className="spinner"></span>
                </div>
            )}
            {!!responsive && !!textData && !!textData["content_blocks/contact"] && (
                <ContactBlock responsive={responsive} textData={textData["content_blocks/contact"]} />
            )}
            {(typeof quotationData[0]?.orders === "undefined" || quotationData[0]?.orders.length === 0) && !loading && (
                <h2>{!!textData && textData["account/quotations"]["table"]["no_data"]}</h2>
            )}
        </>
    );
}
