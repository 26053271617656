import Popup from "reactjs-popup";
import React, { useEffect, useState } from "react";
import Button from "../Button";
import { showFormLabel } from "../Functions/functions";
import { useFormik } from "formik";
import axios from "axios";
import { LoginUserForm } from "../Forms/forms";

export default function LoginPopup(props) {
    const [handleLogin, setHandleLogin] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    // @TODO: Fix error messages for login form in login popup, gather text from translations
    const loginBtnData = {
        name: "login-account",
        text: props.textData["login_btn"],
        type: "submit",
        className: "primary-button login-account-btn",
    };
    const loginUserFormHandler = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: (values) => {
            const loginFormData = new FormData();
            loginFormData.append("email", values["email"]);
            axios
                .post(window.location.origin + "/api/login", loginFormData)
                .then(function (response) {
                    setHandleLogin(true);
                    setErrorMessage({});
                })
                .catch(function (error) {
                    console.log(error.response.data.errors);
                    setHandleLogin(false);
                    setErrorMessage(error.response.data.errors);
                });
        },
    });
    return (
        <>
            {!!props.textData && (
                <Popup open={true} modal nested className="login-popup">
                    {(close) => (
                        <div className="modal">
                            <button className="close" onClick={close}>
                                &times;
                            </button>
                            <div className="header">
                                <div className="title-section">
                                    <h1>{props.textData["title"]}</h1>
                                    <p className={!!errorMessage["email"] ? "error-message" : "success-message"}>
                                        {!handleLogin ? props.textData["subtitle"] : props.textData["success_message"]}
                                    </p>
                                </div>
                            </div>
                            {!handleLogin && (
                                <div className="content-body">
                                    <LoginUserForm
                                        formik={loginUserFormHandler}
                                        textData={props.textData}
                                        formButton={loginBtnData}
                                        errorMessage={errorMessage}
                                        navigateToPageName={props.navigateToPageName}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </Popup>
            )}
        </>
    );
}
