import React, { useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import axios from "axios";
import { RegisterUserForm } from "./Forms/forms";
import { useGlobalState } from "./GlobalContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export default function Register(props) {
    const { productSettings, setProductSettings, setMandatoryProductSettings, loggedInUser } = useGlobalState();
    const [formButton, setFormButton] = useState(null);
    const [created, setCreated] = useState(false);
    const [loginForm, setLoginForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [handleLogin, setHandleLogin] = useState(false);
    const requiredFields = ["full_name", "company", "zipcode", "address", "email", "country", "city"];

    useEffect(() => {
        console.log(localStorage.getItem("registration-email"));
        if (!!props.textData && !formButton) {
            if (!loginForm) {
                const saveQuotationBtnData = {
                    name: "save-quotation",
                    className: "save-quotation-btn",
                    icon: "save-quotation-icon",
                    text: props.textData["request_btn"],
                    type: "submit",
                };
                setFormButton(saveQuotationBtnData);
            }
        }
    }, [props.textData, loginForm]);

    const extractFirstNameAndLastName = (fullName) => {
        const [firstName, ...lastNameArray] = fullName.trim().split(" ");
        const lastName = lastNameArray.join(" ");

        return {
            firstName,
            lastName,
        };
    };

    const getCharactersFromString = (str, whiteSpaceIndex = str.indexOf(" ")) => {
        if (str === null || typeof str === "undefined") {
            return "";
        }
        let characters = str.match(/[a-zA-Z]/g);
        characters.splice(whiteSpaceIndex, 0, " ");
        return characters ? characters.join("") : "";
    };

    const getNumbersFromString = (str) => {
        if (str === null || typeof str === "undefined") {
            return "";
        }
        let numbers = str.match(/\d+/g);
        return numbers ? numbers.join("") : "";
    };

    const handleLoginSection = () => {
        setLoginForm(true);
        setErrorMessage({});
    };

    const formikRegisterUserHandler = useFormik({
        validateOnChange: false,
        initialValues: {
            full_name: "",
            company: "",
            btw: "",
            kvk: "",
            reference: "",
            zipcode: "",
            full_address: "",
            email:
                localStorage.getItem("registration-email") === null ? "" : localStorage.getItem("registration-email"),
            country: "Netherlands",
            mobile_phone: "",
            phone: "",
            city: "",
            additional_address_information: "",
        },
        validate: (values) => {
            // Loop values
            // let result = true;
            // Object.keys(values).forEach((value, key) => {
            //     console.log(value, values[value]);
            //     console.log(props);
            //     if ((values[value] === "" || values[value] === null) && requiredFields.includes(value)) {
            //         toast.error(props.textData["popups/login"]["form_errors"][value]);
            //         result = false;
            //     }
            // });

            let address = values["address"];
            let housenr = getNumbersFromString(address);
            if (address === null) {
                toast.error(props.textData["popups/login"]["housenr_error"]);
                toast.error(props.textData["popups/login"]["address_error"]);
                return false;
            }

            try {
                address = getCharactersFromString(address);
            } catch (e) {
                toast.error(props.textData["popups/login"]["address_error"]);
                return false;
            }
            if (housenr === "") {
                toast.error(props.textData["popups/login"]["housenr_error"]);
                return false;
            }
            if (address === "") {
                toast.error(props.textData["popups/login"]["address_error"]);
                return false;
            }
            return true;
        },
        onSubmit: (values) => {
            const registerFormData = new FormData();
            const { firstName, lastName } = extractFirstNameAndLastName(values["full_name"]);
            let createdUserId;

            registerFormData.append("firstname", firstName);
            registerFormData.append("lastname", lastName);
            registerFormData.append("email", values["email"]);
            registerFormData.append("reference", values["reference"]);
            registerFormData.append("zipcode", values["zipcode"]);
            registerFormData.append(
                "street",
                typeof values["address"] !== "undefined" ? getCharactersFromString(values["address"]) : ""
            );
            registerFormData.append(
                "streetnumber",
                typeof values["address"] !== "undefined" ? getNumbersFromString(values["address"]) : ""
            );
            registerFormData.append("country", values["country"]);
            registerFormData.append("city", values["city"]);
            registerFormData.append("mobile_phone", values["mobile_phone"]);
            registerFormData.append("phone", values["phone"]);
            registerFormData.append("additional_address_information", values["additional_address_information"]);

            let privateUse = document.getElementById("private");
            if (privateUse !== null && !privateUse.checked) {
                registerFormData.append("company", values["company"]);
                registerFormData.append("kvk", values["kvk"]);
                registerFormData.append("btwnumber", values["btw"]);
            }

            axios
                .post(window.location.origin + "/api/register", registerFormData)
                .then(function (response) {
                    setCreated(true);
                    if (localStorage.getItem("registration-email") !== null) {
                        localStorage.removeItem("registration-email");
                    }
                    if (!!response.data["user_id"]) {
                        createdUserId = response.data["user_id"];
                    }
                })
                .catch(function (error) {
                    setErrorMessage(error.response.data.errors);
                    Object.entries(error.response.data.errors).forEach((element) => {
                        toast.error(element[1][0], {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
                });
        },
    });

    return (
        <>
            <div className="register-container">
                <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                {!created ? (
                    <>
                        <div className="header">
                            <>
                                <div className="title">{props.textData["title"]}</div>
                                <div className="description">{props.textData["subtitle"]}</div>
                            </>
                        </div>
                        <div className="content">
                            <div className="content-header">
                                <div className="title">{props.textData["account_create_text"]}</div>
                                <a onClick={() => handleLoginSection()} className="account-login">
                                    {props.textData["account_login_text"]}
                                </a>
                            </div>
                            <h1>{props.textData["login/register"]["title"]}</h1>
                            <div className="content-body">
                                <RegisterUserForm
                                    formik={formikRegisterUserHandler}
                                    errorMessage={errorMessage}
                                    textData={props.textData["login/register"]}
                                    formButton={{
                                        name: "save-quotation",
                                        className: "save-quotation-btn",
                                        icon: "save-quotation-icon",
                                        text: props.textData["login/register"]["request_btn"],
                                        type: "submit",
                                    }}
                                    cancelButton={{
                                        name: props.textData["popups/login"]["cancel_btn"],
                                        path: props.textData["urls/login"]["unique_name"],
                                    }}
                                    navigateToPageName={props.navigateToPageName}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="header">
                            <div className="title">{props.textData["popups/login"]["account_created"]}</div>
                            <div className="description">{props.textData["popups/login"]["check_email_to_login"]}</div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
