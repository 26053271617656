const calculateTransitTime = (productSettings, model) => {
    if (typeof model.delivery !== "undefined" && model.delivery !== null && isNumeric(model.delivery)) {
        return parseInt(model.delivery);
    }

    // Old method: get the transit time from the product settings material
    let result = 0;
    productSettings.forEach((application) => {
        if (application.name === model.settings.application) {
            application.settings.forEach((element) => {
                if (element.material.name === model.settings.material) {
                    result = element.material.delivery_time;
                }
            });
        }
    });

    if (result !== 0) {
        if (result === "") {
            result = 0;
        } else {
            result = parseInt(result);
        }
    }
    if (typeof model.road_transit_days_time !== "undefined") {
        result += parseInt(model.road_transit_days_time);
    }
};

const calculateTransitTimeForOrder = (productSettings, models) => {
    let result = 0;
    let options = [];
    models.forEach((model) => {
        productSettings.forEach((application) => {
            if (application.name === model.settings.application) {
                application.settings.forEach((element) => {
                    if (element.material.name === model.settings.material) {
                        if (typeof model.road_transit_days_time !== "undefined") {
                            options.push(element.material.delivery_time + parseInt(model.road_transit_days_time));
                        } else {
                            options.push(element.material.delivery_time);
                        }
                    }
                });
            }
        });
    });
    options.forEach((option) => {
        if (option !== "" && parseInt(option) > result) {
            result = parseInt(option);
        }
    });

    return result;
};

const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
};

export { calculateTransitTime, calculateTransitTimeForOrder };
