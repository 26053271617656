import React, { useState, useEffect } from "react";
import { useGlobalState } from "../GlobalContext";
import RetrieveProductSettings from "../ProductSettings/RetrieveProductSettings";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";

export default function ShippingPopup(props) {
    const [textData, setTextData] = useState(null);
    const { productSettings, loggedInUser } = useGlobalState();
    const [mandatoryProductSettings, setMandatoryProductSettings] = useState([]);

    useEffect(() => {
        RetrieveProductSettings({ setMandatoryProductSettings });
        setTextData(props.textData);
    }, [props.textData]);

    return (
        <>
            {textData && (
                <Popup open={true} modal nested className="shipping-overview-popup">
                    {(close) => (
                        <div className="modal">
                            <div>
                                <div className="header header-small">
                                    <img src={"/Oceanz_logo_nieuw_FC.png"} className={"logo"} alt="logo" />
                                    <div className="title-section">
                                        <p>
                                            {props.type === "savedQuotation"
                                                ? textData["popups/order_overview"]["quotation"]
                                                : textData["popups/order_overview"]["order"]}{" "}
                                            #{props.order.project_number}
                                        </p>
                                        <span className={props.order.orderStatus}>{props.order.orderStatus}</span>
                                    </div>
                                    <div className="tags">
                                        {props.order.reference !== null && <p>{props.order.reference}</p>}
                                        <p>{props.order.orderDate}</p>
                                    </div>
                                </div>
                                {props.order.trackingInfo.map((info, i) => {
                                    return (
                                        <div className="shipment-item">
                                            <p>
                                                {textData["popups/order_overview"]["delivery_date"] +
                                                    ": " +
                                                    info["delivery_date"]}
                                            </p>
                                            <p>
                                                {textData["popups/order_overview"]["tracking_link"] + ": "}
                                                <a
                                                    target="_blank"
                                                    href={
                                                        "https://kdz.com/nl/track-trace?nr=" + info["tracking_number"]
                                                    }
                                                    rel="noreferrer"
                                                >
                                                    Link
                                                </a>
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </Popup>
            )}
        </>
    );
}
