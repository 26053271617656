import { useGlobalState } from "./GlobalContext";
import QuotationPopup from "./Popups/QuotationPopup";
import { useEffect, useState } from "react";
import LoginPopup from "./Popups/LoginPopup";

export default function Button(props) {
    const { activeNavigationMenuItem, setActiveNavigationMenuItem, currentPage, loggedInUser } = useGlobalState();
    const [openQuotationPopup, setOpenQuotationPopup] = useState(false);
    const [authenticated, setAuthenticated] = useState("init");
    const [infoPopup, setInfoPopup] = useState(null);
    const { data } = props;

    const toCheckout = () => {
        // Check if user is logged in
        setAuthenticated("init");
        if (loggedInUser === null || typeof loggedInUser === "undefined") {
            setAuthenticated(false);
        } else {
            props.navigateToPageName("checkout");
            setActiveNavigationMenuItem("");
        }
    };

    const showLoadingSpinner = () => {
        // get class popup-quotation-btn
        const btn = document.querySelector(".popup-quotation-btn");
        // add class loading
        btn.classList.add("popup-btn-active");
    };

    return (
        <>
            {/** Check the url */}
            {data.name === "quotation-form-btn" && currentPage === "account_edit_quotation" && (
                <div>
                    <button
                        onClick={() => {
                            props.saveQuotation();
                            if (typeof props.setSaveFooterSettings !== "undefined") {
                                props.setSaveFooterSettings(true);
                            }
                            // clear localstorage
                            localStorage.removeItem("modelsData");
                        }}
                        className={`${data.className}}`}
                    >
                        {data.text}
                    </button>
                    {props.disableCheckoutButton && <p className="cant_checkout">{props.popupData["cant_checkout"]}</p>}
                </div>
            )}
            {data.name === "quotation" && currentPage === "account_edit_quotation" && (
                <div>
                    <button
                        onClick={() => {
                            props.saveQuotation();
                            if (typeof props.setSaveFooterSettings !== "undefined") {
                                props.setSaveFooterSettings(true);
                            }
                            // clear localstorage
                            localStorage.removeItem("modelsData");
                        }}
                        className={`${data["save"].className} ${data["save"].icon}`}
                    >
                        {data["checkout"].editText}
                    </button>
                    <button
                        onClick={() => {
                            props.saveQuotationAndRedirectCheckout();
                            if (typeof props.setSaveFooterSettings !== "undefined") {
                                props.setSaveFooterSettings(true);
                            }
                            // clear localstorage
                            localStorage.removeItem("modelsData");
                        }}
                        className={`${data["checkout"].className} ${data["checkout"].icon}`}
                        disabled={props.disableCheckoutButton}
                    >
                        {data["checkout"].text}
                    </button>
                    {props.disableCheckoutButton &&
                        typeof props.popupData !== "undefined" &&
                        props.popupData !== null && <p className="cant_checkout">{props.popupData["cant_checkout"]}</p>}
                </div>
            )}
            {data.name === "quotation" &&
                currentPage !== "account_edit_quotation" &&
                (typeof props.showButtons === "undefined" || props.showButtons === true) && (
                    <div>
                        {loggedInUser !== null && typeof loggedInUser !== "undefined" && (
                            <button
                                onClick={() => {
                                    setOpenQuotationPopup(true);
                                    if (typeof props.setSaveFooterSettings !== "undefined") {
                                        props.setSaveFooterSettings(true);
                                    }
                                }}
                                className={`${data["save"].className} ${data["save"].icon}`}
                            >
                                {data["save"].text}
                            </button>
                        )}
                        <button
                            onClick={() => {
                                if (typeof props.setSaveFooterSettings !== "undefined") {
                                    props.setSaveFooterSettings(true);
                                }
                                toCheckout();
                            }}
                            className={`${data["checkout"].className} ${data["checkout"].icon}`}
                            disabled={props.disableCheckoutButton}
                        >
                            {data["checkout"].text}
                        </button>
                        <QuotationPopup
                            closePopup={setOpenQuotationPopup}
                            openState={openQuotationPopup}
                            textData={props.popupData}
                            loginData={props.loginData}
                            navigateToPageName={props.navigateToPageName}
                            onModelChange={props.onModelChange}
                            showLoadingSpinner={showLoadingSpinner}
                        />
                        {props.disableCheckoutButton && (
                            <p className="cant_checkout">{props.popupData["cant_checkout"]}</p>
                        )}
                    </div>
                )}
            {(data.name === "save-quotation" || data.name === "login-account") &&
                currentPage !== "account_edit_quotation" && (
                    <>
                        <button type={data.type} className={`${data.className} ${data.icon}`}>
                            {data.text}
                        </button>
                    </>
                )}
            {(data.name === "save-account" || data.name === "save-details") &&
                currentPage !== "account_edit_quotation" && (
                    <>
                        <button className={data.class} form={data.form} type={data.type}>
                            {data.text}
                        </button>
                    </>
                )}
            {!authenticated && <LoginPopup textData={!!props.loginData && props.loginData} />}
        </>
    );
}
