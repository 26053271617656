import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/pro-regular-svg-icons";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalState } from "./GlobalContext";

export default function NavigationMenu(props) {
    const {
        quotationTab = typeof props.textData.overview !== "undefined"
            ? props.textData.overview["your_quotation"]
            : "Your quotation",
        accountTab = typeof props.textData.overview !== "undefined"
            ? props.textData.overview["my_oceanz"]
            : "My Oceanz",
        responsive,
        authenticated,
        toggleLoginModal,
    } = props;
    const { activeNavigationMenuItem, setActiveNavigationMenuItem, loggedInUser } = useGlobalState();

    const handleMenuInteraction = (e) => {
        switch (e.target.id) {
            case "navigate-quotation":
                props.navigateToPageName("home");
                setActiveNavigationMenuItem("navigate-quotation");
                break;
            case "navigate-account":
                if (authenticated) {
                    props.navigateToPageName("account_orders");
                    setActiveNavigationMenuItem("navigate-account");
                } else {
                    toggleLoginModal(true);
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            {!responsive && (
                <div className="navigation-menu-container">
                    <div className="navigation-link">
                        <div
                            className={
                                !!activeNavigationMenuItem && activeNavigationMenuItem === "navigate-quotation"
                                    ? "icon-container active-link"
                                    : "icon-container"
                            }
                        >
                            <FontAwesomeIcon icon={faCartShopping} size="xs" />
                        </div>
                        <a id="navigate-quotation" onClick={(e) => handleMenuInteraction(e)}>
                            {quotationTab}
                        </a>
                    </div>
                    <div className="navigation-link">
                        <div
                            className={
                                !!activeNavigationMenuItem && activeNavigationMenuItem === "navigate-account"
                                    ? "icon-container active-link"
                                    : "icon-container"
                            }
                        >
                            <FontAwesomeIcon icon={faUser} size="xs" />
                        </div>
                        {loggedInUser !== null && typeof loggedInUser.user !== "undefined" && (
                            <p id="account-name">
                                {typeof props.textData.overview !== "undefined"
                                    ? props.textData.overview["logged_in_as"]
                                    : "Logged in as "}{" "}
                                {loggedInUser.user.first_name}
                            </p>
                        )}
                        <a id="navigate-account" onClick={(e) => handleMenuInteraction(e)}>
                            {accountTab}
                        </a>
                    </div>
                </div>
            )}
            {!!responsive && (
                <div className="navigation-menu-container">
                    <Link to={"/"}>
                        <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                    </Link>
                    <div className="navigation-menu">
                        <div className="navigation-link">
                            <div
                                className={
                                    !!activeNavigationMenuItem && activeNavigationMenuItem === "navigate-quotation"
                                        ? "icon-container active-link"
                                        : "icon-container"
                                }
                            >
                                <FontAwesomeIcon
                                    id="navigate-quotation"
                                    onClick={(e) => handleMenuInteraction(e)}
                                    icon={faCartShopping}
                                    size="1x"
                                />
                            </div>
                        </div>
                        <div className="navigation-link">
                            <div
                                className={
                                    !!activeNavigationMenuItem && activeNavigationMenuItem === "navigate-account"
                                        ? "icon-container active-link"
                                        : "icon-container"
                                }
                            >
                                <FontAwesomeIcon
                                    id="navigate-account"
                                    onClick={(e) => handleMenuInteraction(e)}
                                    icon={faUser}
                                    size="1x"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
