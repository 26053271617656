import Popup from "reactjs-popup";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { calcShippingCosts } from "../Checkout/Shipping";
import { useGlobalState } from "../GlobalContext";
import { Accordion } from "../Accordion/Accordion";
import RetrieveProductSettings from "../ProductSettings/RetrieveProductSettings";
import {
    getTotalPrice,
    getSubtotalPrice,
    getExtraCosts,
    getExtraOrderCosts,
    getModelPrice,
    getCompanyDiscount,
    calculateVat,
    getDiscountCodesAmount,
} from "../Functions/price-calculations";
import { isEU } from "../Functions/functions";

export default function OrderPopup(props) {
    const [editModalOpen, setEditModalOpen] = useState(null);
    const [textData, setTextData] = useState(null);
    const [showOnlyDownloadBtn, setShowOnlyDownloadBtn] = useState(false);
    const [shippingCosts, setShippingCosts] = useState(0);
    const { productSettings, loggedInUser } = useGlobalState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [disableCheckoutButton, setDisableCheckoutButton] = useState(true);
    const [mandatoryProductSettings, setMandatoryProductSettings] = useState([]);
    const [totalDiscount, setTotalDiscount] = useState(0);

    useEffect(() => {
        RetrieveProductSettings({ setMandatoryProductSettings });
        setTextData(props.textData);
        if (props.type === "order") {
            setShowOnlyDownloadBtn(true);
        }
    }, [props.textData]);

    useEffect(() => {
        setTotalPrice(
            getTotalPrice(props.order, loggedInUser, setTotalDiscount, mandatoryProductSettings, productSettings)
        );
        disableAgreeButton();
    }, [shippingCosts]);

    useEffect(() => {
        let country = null;
        if (loggedInUser.company !== null) {
            country = loggedInUser.company.country;
        }

        if (typeof props.order.delivery_price !== "undefined" && props.order.delivery_price !== null) {
            setShippingCosts(parseFloat(props.order.delivery_price));
        } else {
            if (props.order.delivery_term !== null) {
                calcShippingCosts([{ data: props.order.models }], productSettings, country).then((result) => {
                    if (typeof result.shipping_costs !== "undefined" && result.shipping_costs !== null) {
                        setShippingCosts(result.shipping_costs.road_goods_euro_price);
                    } else {
                        setShippingCosts((0).toFixed(2));
                    }
                });
            } else {
                setShippingCosts((0).toFixed(2));
            }
        }
    }, []);

    const handleDownload = () => {
        // make download link
        const downloadLink = document.createElement("a");
        downloadLink.href = window.location.origin + `/quotation/generate-pdf/` + props.order.project_number;
        var today = new Date().toLocaleDateString("nl-NL");
        today = today.replace(/-/g, "");
        downloadLink.setAttribute("download", `YO${props.order.project_number}${today}.pdf`);
        document.body.appendChild(downloadLink);
        setTimeout(() => {
            downloadLink.click();
        }, 100);
    };

    const showOrderBtw = (order) => {
        let country = null;
        if (order?.delivery_country) {
            country = order?.delivery_country;
        }

        if (
            country !== null &&
            isEU(country) &&
            loggedInUser?.company?.vat_number !== null &&
            typeof loggedInUser?.company?.vat_number !== "undefined" &&
            country !== "Netherlands"
        ) {
            return false;
        }
        return true;
    };

    const handleEdit = () => {
        // TODO: Refer to edit page
        props.navigateToPageName("account_edit_quotation", "?id=" + props.order.project_number);
    };

    const handleAgree = () => {
        props.navigateToPageName("checkout", "?id=" + props.order.project_number);
    };

    // Checkout button
    const disableAgreeButton = () => {
        let found = false;
        for (let model in props.order.models) {
            // Check if numeric or not
            if (isNaN(parseFloat(props.order.models[model].pivot.price))) {
                found = true;
            }
        }

        if (found) {
            setDisableCheckoutButton(true);
        } else {
            setDisableCheckoutButton(false);
        }
    };

    const addMandatoryOption = (mdl) => {
        let result = false;
        props.order.models.forEach((model) => {
            if (mdl.id === model.id) {
                mandatoryProductSettings.forEach((setting) => {
                    if (model.settings.application === setting.application) {
                        result = {
                            price: setting.mandatory_options[0].price.toFixed(2),
                            name: setting.mandatory_options[0].name,
                        };
                    }
                });
            }
        });
        return result;
    };

    const OrderOverviewAccordion = (model, index) => {
        return (
            <>
                <img src={model.preview_image} className={"logo"} alt="exampleModel" />
                <div className="product-container">
                    <div>
                        <p className="title">
                            {model.file} <span className="amount">{model.pivot.amount}x</span>
                        </p>
                    </div>
                    <div className="product-data">
                        {model.settings.application !== null && typeof model.settings.application !== "undefined" && (
                            <span className="product-setting">{model.settings.application}</span>
                        )}
                        {model.settings.sterile !== null &&
                            model.settings.sterile === true &&
                            model.settings.application.toLowerCase() === "medical" &&
                            typeof model.settings.sterile !== "undefined" && (
                                <span className="product-setting">
                                    {textData["sections/label"]["sterile"]["sterile"]}
                                </span>
                            )}
                        {model.settings.intendedUse !== null &&
                            (model.settings.application.toLowerCase() === "medical" ||
                                model.settings.application.toLowerCase() === "dental") &&
                            typeof model.settings.intendedUse !== "undefined" && (
                                <span className="product-setting">{model.settings.intendedUse}</span>
                            )}
                        {model.settings.clinicalUse !== null &&
                            model.settings.application.toLowerCase() === "medical" &&
                            typeof model.settings.clinicalUse !== "undefined" && (
                                <span className="product-setting">
                                    {model.settings.sterile === true
                                        ? textData["sections/label"]["clinicalUse"]["clinical"]
                                        : textData["sections/label"]["clinicalUse"]["not_clinical"]}
                                </span>
                            )}
                        {model.settings.custom !== null &&
                            model.settings.custom === true &&
                            model.settings.application.toLowerCase() === "medical" &&
                            typeof model.settings.custom !== "undefined" && (
                                <span className="product-setting">{textData["sections/custom"]["custom"]}</span>
                            )}
                        {model.settings.case !== null &&
                            model.settings.application.toLowerCase() === "medical" &&
                            typeof model.settings.case !== "undefined" && (
                                <span className="product-setting">
                                    {textData["sections/custom"]["case"]}
                                    {": "}
                                    {model.settings.case}
                                </span>
                            )}
                        {model.settings.practitioner !== null &&
                            model.settings.application.toLowerCase() === "medical" &&
                            typeof model.settings.practitioner !== "undefined" && (
                                <span className="product-setting">
                                    {textData["sections/custom"]["practitioner"]}
                                    {": "}
                                    {model.settings.practitioner}
                                </span>
                            )}
                        {model.settings.material !== null && (
                            <span className="product-setting">{model.settings.material}</span>
                        )}
                        {model.settings.color !== null && model.settings.color.toLowerCase() !== "no colouring" && (
                            <span className="product-setting">{model.settings.color}</span>
                        )}
                        {model.settings.polish !== null && model.settings.polish.toLowerCase() !== "no" && (
                            <span className="product-setting">{model.settings.polish}</span>
                        )}
                        {model.settings.coating !== null && model.settings.coating.toLowerCase() !== "no" && (
                            <span className="product-setting">{model.settings.coating}</span>
                        )}
                        {model.settings.orientation !== null && (
                            <span className="product-setting">{model.settings.orientation}</span>
                        )}
                    </div>
                </div>
                <p id="last-p">{getModelPrice(model, loggedInUser, props.order)}</p>
            </>
        );
    };

    const OrderOverviewModal = () => (
        <>
            {textData && (
                <Popup open={true} modal nested className="order-overview-popup">
                    {(close) => (
                        <div className="modal">
                            <button className="close" onClick={close}>
                                &times;
                            </button>
                            <div className="header header-small">
                                <Link to={"/"}>
                                    <img src={"/Oceanz_logo_nieuw_FC.png"} className={"logo"} alt="logo" />
                                </Link>
                                <div className="title-section">
                                    <p>
                                        {props.type === "savedQuotation"
                                            ? textData["popups/order_overview"]["quotation"]
                                            : textData["popups/order_overview"]["order"]}{" "}
                                        #{props.order.project_number}
                                    </p>
                                    <span className={props.order.orderStatus}>{props.order.orderStatus}</span>
                                </div>
                                <div className="tags">
                                    {props.order.reference !== null && <p>{props.order.reference}</p>}
                                    <p>{props.order.orderDate}</p>
                                </div>
                            </div>
                            <div className="content scrollable">
                                {typeof props.order.reject_reason !== "undefined" &&
                                    props.order.reject_reason !== null && (
                                        <>
                                            <div className="reject-reason">
                                                <p>{textData["account/order_overview"]["reject_reason"]}:</p>
                                                <p>
                                                    {props.order.reject_reason.split("\n").map((item, idx) => {
                                                        return (
                                                            <React.Fragment key={idx}>
                                                                {item}
                                                                <br />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </p>
                                            </div>
                                        </>
                                    )}

                                {(shippingCosts !== "..." || props.order.status !== "quotation") && (
                                    <>
                                        <div className="content-products">
                                            <>
                                                {!props.responsive
                                                    ? props.order.models.map((model, index) => {
                                                          return (
                                                              <div className="product-overview" key={index}>
                                                                  <img
                                                                      src={model.preview_image}
                                                                      className={"logo"}
                                                                      alt="exampleModel"
                                                                  />
                                                                  <div className="product-container">
                                                                      <div>
                                                                          <p className="title">
                                                                              {model.file}{" "}
                                                                              <span className="amount">
                                                                                  {model.pivot.amount}x
                                                                              </span>
                                                                          </p>
                                                                      </div>
                                                                      <div className="product-data">
                                                                          {model.settings.application !== null &&
                                                                              typeof model.settings.application !==
                                                                                  "undefined" && (
                                                                                  <span className="product-setting">
                                                                                      {model.settings.application}
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.sterile !== null &&
                                                                              model.settings.sterile === true &&
                                                                              model.settings.application.toLowerCase() ===
                                                                                  "medical" &&
                                                                              typeof model.settings.sterile !==
                                                                                  "undefined" && (
                                                                                  <span className="product-setting">
                                                                                      {
                                                                                          textData["sections/label"][
                                                                                              "sterile"
                                                                                          ]["sterile"]
                                                                                      }
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.intendedUse !== null &&
                                                                              (model.settings.application.toLowerCase() ===
                                                                                  "medical" ||
                                                                                  model.settings.application.toLowerCase() ===
                                                                                      "dental") &&
                                                                              typeof model.settings.intendedUse !==
                                                                                  "undefined" && (
                                                                                  <span className="product-setting">
                                                                                      {model.settings.intendedUse}
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.clinicalUse !== null &&
                                                                              model.settings.application.toLowerCase() ===
                                                                                  "medical" &&
                                                                              typeof model.settings.clinicalUse !==
                                                                                  "undefined" && (
                                                                                  <span className="product-setting">
                                                                                      {model.settings.sterile === true
                                                                                          ? textData["sections/label"][
                                                                                                "clinicalUse"
                                                                                            ]["clinical"]
                                                                                          : textData["sections/label"][
                                                                                                "clinicalUse"
                                                                                            ]["not_clinical"]}
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.custom !== null &&
                                                                              model.settings.custom === true &&
                                                                              model.settings.application.toLowerCase() ===
                                                                                  "medical" &&
                                                                              typeof model.settings.custom !==
                                                                                  "undefined" && (
                                                                                  <span className="product-setting">
                                                                                      {
                                                                                          textData["sections/custom"][
                                                                                              "custom"
                                                                                          ]
                                                                                      }
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.case !== null &&
                                                                              model.settings.application.toLowerCase() ===
                                                                                  "medical" &&
                                                                              typeof model.settings.case !==
                                                                                  "undefined" && (
                                                                                  <span className="product-setting">
                                                                                      {
                                                                                          textData["sections/custom"][
                                                                                              "case"
                                                                                          ]
                                                                                      }
                                                                                      {": "}
                                                                                      {model.settings.case}
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.practitioner !== null &&
                                                                              model.settings.application.toLowerCase() ===
                                                                                  "medical" &&
                                                                              typeof model.settings.practitioner !==
                                                                                  "undefined" && (
                                                                                  <span className="product-setting">
                                                                                      {
                                                                                          textData["sections/custom"][
                                                                                              "practitioner"
                                                                                          ]
                                                                                      }
                                                                                      {": "}
                                                                                      {model.settings.practitioner}
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.material !== null && (
                                                                              <span className="product-setting">
                                                                                  {model.settings.material}
                                                                              </span>
                                                                          )}
                                                                          {model.settings.color !== null &&
                                                                              model.settings.color.toLowerCase() !==
                                                                                  "no colouring" && (
                                                                                  <span className="product-setting">
                                                                                      {model.settings.color}
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.polish !== null &&
                                                                              model.settings.polish.toLowerCase() !==
                                                                                  "no" && (
                                                                                  <span className="product-setting">
                                                                                      {model.settings.polish}
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.coating !== null &&
                                                                              model.settings.coating.toLowerCase() !==
                                                                                  "no" && (
                                                                                  <span className="product-setting">
                                                                                      {model.settings.coating}
                                                                                  </span>
                                                                              )}
                                                                          {model.settings.orientation !== null && (
                                                                              <span className="product-setting">
                                                                                  {model.settings.orientation}
                                                                              </span>
                                                                          )}
                                                                          {addMandatoryOption(model) !== false && (
                                                                              <>
                                                                                  <span className="product-setting">
                                                                                      {
                                                                                          textData["sections/label"][
                                                                                              "mandatory"
                                                                                          ]
                                                                                      }
                                                                                      :&nbsp;
                                                                                      {addMandatoryOption(model).name}
                                                                                      &nbsp; &euro;
                                                                                      {addMandatoryOption(model).price}
                                                                                  </span>
                                                                              </>
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                                  <p id="last-p">
                                                                      {getModelPrice(model, loggedInUser, props.order)}
                                                                  </p>
                                                              </div>
                                                          );
                                                      })
                                                    : props.order.models.map((model, index) => {
                                                          return (
                                                              <div className="product-overview" key={index}>
                                                                  <Accordion
                                                                      header={model.file}
                                                                      content={OrderOverviewAccordion(model, index)}
                                                                      model={model}
                                                                  ></Accordion>
                                                              </div>
                                                          );
                                                      })}
                                                {typeof props.order.products !== "undefined" && (
                                                    <>
                                                        {props.order.products.map((libraryProduct, index) => {
                                                            return (
                                                                <div className="product-overview" key={index}>
                                                                    <img
                                                                        src={libraryProduct.product.preview_image}
                                                                        className={"logo"}
                                                                        alt="exampleModel"
                                                                        width="100"
                                                                        height="100"
                                                                    />
                                                                    <div className="product-container">
                                                                        <div>
                                                                            <p className="title">
                                                                                {libraryProduct.product.name}{" "}
                                                                                <span className="amount">
                                                                                    {libraryProduct.quantity}x
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="product-data">
                                                                            {libraryProduct.product.settings
                                                                                .application !== null &&
                                                                                typeof libraryProduct.product.settings
                                                                                    .application !== "undefined" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            libraryProduct.product
                                                                                                .settings.application
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings.sterile !==
                                                                                null &&
                                                                                libraryProduct.product.settings
                                                                                    .sterile === true &&
                                                                                libraryProduct.product.settings.application.toLowerCase() ===
                                                                                    "medical" &&
                                                                                typeof libraryProduct.product.settings
                                                                                    .sterile !== "undefined" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            textData["sections/label"][
                                                                                                "sterile"
                                                                                            ]["sterile"]
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings
                                                                                .intendedUse !== null &&
                                                                                (libraryProduct.product.settings.application.toLowerCase() ===
                                                                                    "medical" ||
                                                                                    libraryProduct.product.settings.application.toLowerCase() ===
                                                                                        "dental") &&
                                                                                typeof libraryProduct.product.settings
                                                                                    .intendedUse !== "undefined" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            libraryProduct.product
                                                                                                .settings.intendedUse
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings
                                                                                .clinicalUse !== null &&
                                                                                libraryProduct.product.settings.application.toLowerCase() ===
                                                                                    "medical" &&
                                                                                libraryProduct.product.settings
                                                                                    .sterile === true &&
                                                                                typeof libraryProduct.product.settings
                                                                                    .clinicalUse !== "undefined" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            textData["sections/label"][
                                                                                                "clinicalUse"
                                                                                            ]["clinical"]
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings.custom !==
                                                                                null &&
                                                                                libraryProduct.product.settings
                                                                                    .custom === true &&
                                                                                libraryProduct.product.settings.application.toLowerCase() ===
                                                                                    "medical" &&
                                                                                typeof libraryProduct.product.settings
                                                                                    .custom !== "undefined" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            textData["sections/custom"][
                                                                                                "custom"
                                                                                            ]
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings.case !==
                                                                                null &&
                                                                                libraryProduct.product.settings.application.toLowerCase() ===
                                                                                    "medical" &&
                                                                                typeof libraryProduct.product.settings
                                                                                    .case !== "undefined" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            textData["sections/custom"][
                                                                                                "case"
                                                                                            ]
                                                                                        }
                                                                                        {": "}
                                                                                        {
                                                                                            libraryProduct.product
                                                                                                .settings.case
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings
                                                                                .practitioner !== null &&
                                                                                libraryProduct.product.settings.application.toLowerCase() ===
                                                                                    "medical" &&
                                                                                typeof libraryProduct.product.settings
                                                                                    .practitioner !== "undefined" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            textData["sections/custom"][
                                                                                                "practitioner"
                                                                                            ]
                                                                                        }
                                                                                        {": "}
                                                                                        {
                                                                                            libraryProduct.product
                                                                                                .settings.practitioner
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings
                                                                                .material !== null && (
                                                                                <span className="product-setting">
                                                                                    {
                                                                                        libraryProduct.product.settings
                                                                                            .material
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                            {libraryProduct.product.settings.color !==
                                                                                null &&
                                                                                libraryProduct.product.settings.color.toLowerCase() !==
                                                                                    "no colouring" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            libraryProduct.product
                                                                                                .settings.color
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings.polish !==
                                                                                null &&
                                                                                libraryProduct.product.settings.polish.toLowerCase() !==
                                                                                    "no" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            libraryProduct.product
                                                                                                .settings.polish
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings.coating !==
                                                                                null &&
                                                                                libraryProduct.product.settings.coating.toLowerCase() !==
                                                                                    "no" && (
                                                                                    <span className="product-setting">
                                                                                        {
                                                                                            libraryProduct.product
                                                                                                .settings.coating
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            {libraryProduct.product.settings
                                                                                .orientation !== null && (
                                                                                <span className="product-setting">
                                                                                    {
                                                                                        libraryProduct.product.settings
                                                                                            .orientation
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                            {/* {addMandatoryOption(libraryProduct.product) !== false && (
                                                                                    <>
                                                                                        <span className="product-setting">
                                                                                            {
                                                                                                textData["sections/label"][
                                                                                                    "mandatory"
                                                                                                ]
                                                                                            }
                                                                                            :&nbsp;
                                                                                            {addMandatoryOption(libraryProduct.product).name}&nbsp;
                                                                                            &euro;
                                                                                            {addMandatoryOption(libraryProduct.product).price}
                                                                                        </span>
                                                                                    </>
                                                                                )} */}
                                                                        </div>
                                                                    </div>
                                                                    <p id="last-p">
                                                                        &euro;
                                                                        {(
                                                                            parseFloat(libraryProduct.price) *
                                                                            parseInt(libraryProduct.quantity)
                                                                        ).toFixed(2)}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </>
                                        </div>
                                        <div className="price-overview">
                                            {(props.order.extraCosts.length > 0 ||
                                                parseFloat(getExtraCosts(props.order, mandatoryProductSettings)) > 0) &&
                                                getExtraOrderCosts(props.order, mandatoryProductSettings, textData)
                                                    .length > 0 && (
                                                    <div className="extra-order-costs-border">
                                                        {getExtraOrderCosts(
                                                            props.order,
                                                            mandatoryProductSettings,
                                                            textData
                                                        )}
                                                    </div>
                                                )}
                                            <div className="total">
                                                <p>{textData["popups/order_overview"]["subtotal"]}</p>
                                                <p>
                                                    €{" "}
                                                    {getSubtotalPrice(
                                                        props.order,
                                                        loggedInUser,
                                                        mandatoryProductSettings,
                                                        productSettings
                                                    )}
                                                </p>
                                            </div>
                                            <div className="company_discount">
                                                {getDiscountCodesAmount(props.order, mandatoryProductSettings, true) >
                                                    0 && (
                                                    <>
                                                        <div className="discount-container">
                                                            <p className="discount">
                                                                {props.textData["checkout/form"]["discount_code"]}{" "}
                                                            </p>
                                                            <p className="discount">
                                                                {"€ -" +
                                                                    getDiscountCodesAmount(
                                                                        props.order,
                                                                        mandatoryProductSettings,
                                                                        true
                                                                    ).toFixed(2)}
                                                            </p>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="company_discount">
                                                {loggedInUser.company !== null &&
                                                    loggedInUser.company.discount_percentage > 0 &&
                                                    getCompanyDiscount(
                                                        props.order,
                                                        loggedInUser,
                                                        mandatoryProductSettings
                                                    ) > 0 &&
                                                    loggedInUser.company.fixed_discount === false &&
                                                    props.order.status === "quotation" &&
                                                    (typeof props.order.discount === "undefined" ||
                                                        props.order.discount === null) && (
                                                        <>
                                                            <div className="discount-container">
                                                                <p className="discount">
                                                                    {
                                                                        props.textData["checkout/form"][
                                                                            "company_discount"
                                                                        ]
                                                                    }{" "}
                                                                    {"(" +
                                                                        parseFloat(
                                                                            loggedInUser.company.discount_percentage
                                                                        ) +
                                                                        "%)"}
                                                                </p>
                                                                <p className="discount">
                                                                    {"€ -" +
                                                                        getCompanyDiscount(
                                                                            props.order,
                                                                            loggedInUser,
                                                                            mandatoryProductSettings
                                                                        ).toFixed(2)}
                                                                </p>
                                                            </div>
                                                        </>
                                                    )}
                                                {loggedInUser.company !== null &&
                                                    loggedInUser.company.discount_percentage > 0 &&
                                                    getCompanyDiscount(
                                                        props.order,
                                                        loggedInUser,
                                                        mandatoryProductSettings
                                                    ) > 0 &&
                                                    loggedInUser.company.fixed_discount === true &&
                                                    props.order.status === "quotation" &&
                                                    (typeof props.order.discount === "undefined" ||
                                                        props.order.discount === null) && (
                                                        <>
                                                            <div className="discount-container">
                                                                <p className="discount">
                                                                    {
                                                                        props.textData["checkout/form"][
                                                                            "company_discount"
                                                                        ]
                                                                    }{" "}
                                                                    {"(€-" +
                                                                        parseFloat(
                                                                            loggedInUser.company.discount_percentage
                                                                        ) +
                                                                        ")"}
                                                                </p>
                                                                <p className="discount">
                                                                    {"€ -" +
                                                                        getCompanyDiscount(
                                                                            props.order,
                                                                            loggedInUser,
                                                                            mandatoryProductSettings
                                                                        ).toFixed(2)}
                                                                </p>
                                                            </div>
                                                        </>
                                                    )}
                                                {typeof props.order.discount !== "undefined" &&
                                                    props.order.discount !== null &&
                                                    props.order.fixed_discount === false &&
                                                    getCompanyDiscount(
                                                        props.order,
                                                        loggedInUser,
                                                        mandatoryProductSettings
                                                    ) > 0 && (
                                                        <>
                                                            <div className="discount-container">
                                                                <p className="discount">
                                                                    {
                                                                        props.textData["checkout/form"][
                                                                            "company_discount"
                                                                        ]
                                                                    }{" "}
                                                                    {"(" + parseFloat(props.order.discount) + "%)"}
                                                                </p>
                                                                <p className="discount">
                                                                    {"€ -" +
                                                                        getCompanyDiscount(
                                                                            props.order,
                                                                            loggedInUser,
                                                                            mandatoryProductSettings
                                                                        ).toFixed(2)}
                                                                </p>
                                                            </div>
                                                        </>
                                                    )}
                                                {typeof props.order.discount !== "undefined" &&
                                                    props.order.discount !== null &&
                                                    props.order.fixed_discount === true && (
                                                        <>
                                                            <div className="discount-container">
                                                                <p className="discount">
                                                                    {
                                                                        props.textData["checkout/form"][
                                                                            "company_discount"
                                                                        ]
                                                                    }{" "}
                                                                    {"(€-" + parseInt(props.order.discount) + ")"}
                                                                </p>
                                                                <p className="discount">
                                                                    {"€ -" +
                                                                        getCompanyDiscount(
                                                                            props.order,
                                                                            loggedInUser,
                                                                            mandatoryProductSettings
                                                                        ).toFixed(2)}
                                                                </p>
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                            <div className="shipping_cost">
                                                <p>{textData["popups/order_overview"]["shipping_costs"]}</p>
                                                <>
                                                    <p>
                                                        {props.order.delivery_price !== null && (
                                                            <>€{parseFloat(props.order.delivery_price).toFixed(2)}</>
                                                        )}
                                                        {props.order.delivery_price === null &&
                                                            shippingCosts !== null &&
                                                            parseFloat(shippingCosts) !== 0 && <>€</>}{" "}
                                                        {(props.order.delivery_price === null &&
                                                            shippingCosts === null) ||
                                                            (props.order.delivery_price === null &&
                                                                parseFloat(shippingCosts) === 0 && (
                                                                    <>€ {parseFloat(shippingCosts).toFixed(2)}</>
                                                                ))}
                                                    </p>
                                                </>
                                            </div>
                                            {calculateVat(
                                                props.order,
                                                loggedInUser,
                                                mandatoryProductSettings,
                                                productSettings
                                            ) > 0 &&
                                                showOrderBtw(props.order) && (
                                                    <div className="tax">
                                                        <p>{textData["popups/order_overview"]["vat"]} (21%)</p>
                                                        <p>
                                                            €{" "}
                                                            {calculateVat(
                                                                props.order,
                                                                loggedInUser,
                                                                mandatoryProductSettings,
                                                                productSettings
                                                            ).toFixed(2)}
                                                        </p>
                                                    </div>
                                                )}
                                            <div className="total">
                                                <p>{textData["popups/order_overview"]["total"]}</p>
                                                <p>
                                                    €{" "}
                                                    {getTotalPrice(
                                                        props.order,
                                                        loggedInUser,
                                                        setTotalDiscount,
                                                        mandatoryProductSettings,
                                                        productSettings
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="delivery-overview">
                                            {props.order.status === "quotation" && (
                                                <p>
                                                    {textData["popups/order_overview"]["shipping_info_quotation"]
                                                        .replace("{days}", props.order.shipping_days)
                                                        .replace("{date}", props.order.orderDate)}
                                                </p>
                                            )}
                                            {props.order.status !== "quotation" &&
                                                props.order.shipping_days === null && (
                                                    <p>
                                                        {textData["popups/order_overview"][
                                                            "shipping_info_order_pickup"
                                                        ].replace("{date}", props.order.orderDate)}
                                                    </p>
                                                )}
                                            {props.order.status !== "quotation" &&
                                                props.order.shipping_days !== null && (
                                                    <p>
                                                        {textData["popups/order_overview"]["shipping_info_order"]
                                                            .replace("{days}", props.order.shipping_days)
                                                            .replace("{date}", props.order.orderDate)}
                                                    </p>
                                                )}
                                        </div>
                                    </>
                                )}
                                {shippingCosts === "..." && props.order.status === "quotation" && (
                                    <>
                                        <div className="generic-spinner">
                                            <span className="spinner"></span>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="footer">
                                {showOnlyDownloadBtn && (
                                    <>
                                        {props.order.status !== "rejected" && (
                                            <button className="button-download-only" onClick={handleDownload}>
                                                {textData["popups/accept_quotation"]["buttons"]["download_as_pdf"]}
                                            </button>
                                        )}
                                        {props.order.status === "rejected" && (
                                            <button className="button-download-only disabled" disabled>
                                                {textData["popups/accept_quotation"]["buttons"]["download_as_pdf"]}
                                            </button>
                                        )}
                                    </>
                                )}
                                {(!showOnlyDownloadBtn || typeof showOnlyDownloadBtn === "undefined") && (
                                    <>
                                        {props.order.status !== "rejected" && (
                                            <button className="button-download" onClick={handleDownload}>
                                                {textData["popups/accept_quotation"]["buttons"]["download"]}
                                            </button>
                                        )}
                                        {props.order.admin_edited !== true && (
                                            <button className="button-edit" onClick={handleEdit}>
                                                {textData["popups/accept_quotation"]["buttons"]["edit"]}
                                            </button>
                                        )}
                                        {!disableCheckoutButton && (
                                            <>
                                                <button className="button-agree" onClick={handleAgree}>
                                                    {textData["popups/accept_quotation"]["buttons"]["accept"]}
                                                </button>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </Popup>
            )}
        </>
    );
    return <OrderOverviewModal />;
}
