import { useState, useEffect } from "react";
import InfoButton from "../InfoButton";

export default function Coating(props) {
    const [selected, setSelected] = useState(null);
    const [buttons, setButtons] = useState([]);
    const [clicked, setClicked] = useState(false);
    const [closeInfoPopup, setCloseInfoPopup] = useState(null);
    const [onInfoClick, setOnInfoClick] = useState(null);

    useEffect(() => {
        renderButtons();
    }, [props.colors]);

    useEffect(() => {
        if (clicked === true) {
            setTimeout(() => {
                // Submit the form
                submit();
            }, 100);
        }
    }, [selected]);

    function clickSelect(e, i) {
        select(e, i);
        setClicked(true);
    }

    function renderButtons() {
        const buttonsArray = Object.entries(props.colors)
            .filter(([key, value]) => value.name !== "Blue" && value.name !== "Turquoise")
            .map(([key, value], i) => (
                <div className="color-container" key={i}>
                    <span className="color-preview" style={{ backgroundColor: value.hex }}></span>
                    <button
                        className={`btn btn-option 
                    ${props.config.color === null && i === 0 ? "selected" : ""} 
                    ${props.config.color == value.name ? "selected" : ""}`}
                        onClick={(e) => clickSelect(e, i)}
                    >
                        {value.name}
                    </button>
                </div>
            ));
        setButtons(buttonsArray);
    }

    function select(e, i) {
        // Remove selected class from all buttons
        const container = document.getElementsByClassName("materials-settings-details");
        const buttons = container[0].getElementsByClassName("btn-option");
        for (var i = 0; i < buttons.length; i++) buttons[i].classList.remove("selected");
        // Add selected class to clicked button
        setSelected(e.target);
        e.target.classList.add("selected");
    }

    function submit() {
        if (selected !== null) {
            props.updateSetting("color", selected.innerText);
        } else {
            // Check if there is a button with state selected
            let btn = document.getElementsByClassName("btn-option selected")[0];
            if (btn !== null && typeof btn !== "undefined") {
                props.updateSetting("color", btn.innerText);
            }
        }
        props.goNext("overview");
        props.clearSettingsThatAreNotAllowed(false, "color");
    }

    function goBack() {
        props.goNext("material");
    }

    return (
        <div className="materials-settings-details">
            <h1>{props.textData.title}</h1>
            <br />
            <p className="subtitle">{props.textData.subtitle}</p>
            <br />
            <h2 className="h2-info-parent">{props.textData.header}</h2>
            <InfoButton
                info={props.productSettingExplanations.filter((info) => {
                    return info.type === "color";
                })}
                textData={props.infoTextData}
            />
            <div className="material-btns">{buttons}</div>
            {props.colors !== null && props.colors.length > 0 ? "" : <p>{props.textData.not_available}</p>}
        </div>
    );
}
