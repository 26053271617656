import React from "react";

const handleInputType = (inputType) => {
    if (!!inputType) {
        switch (inputType) {
            case "email":
                return "email";
            case "tel-phone":
            case "mobile-phone":
                return "number";
            case "diff_address":
                return "checkbox";
            default:
                return "text";
        }
    }
};

const handleLocalStorageData = (newState, modelsData) => {
    // Check if we need to use modelsData or modelsDataQuotation
    const localStorageData = JSON.parse(window.localStorage.getItem(modelsData));
    if (!!localStorageData && localStorageData.length > 0) {
        localStorageData[0].data = newState[0].data;
        window.localStorage.setItem(modelsData, JSON.stringify(localStorageData));
    }
};

const showFormLabel = (e) => {
    const getLabelFromInput = e.target.parentElement.querySelector("label");
    if (getLabelFromInput) {
        getLabelFromInput.classList.toggle("hide-label", e.target.value === "");
    }
};

const isEU = (country) => {
    if (typeof country === "undefined" || country === null) {
        return false;
    }

    const euCountries = [
        "Austria",
        "Belgium",
        "Bulgaria",
        "Croatia",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Estonia",
        "Finland",
        "France",
        "Germany",
        "Greece",
        "Hungary",
        "Ireland",
        "Italy",
        "Latvia",
        "Lithuania",
        "Luxembourg",
        "Malta",
        "Netherlands",
        "Poland",
        "Portugal",
        "Romania",
        "Slovakia",
        "Slovenia",
        "Spain",
        "Sweden",
        "Liechtenstein",
    ];

    return euCountries.includes(country);
};
export { handleInputType, showFormLabel, handleLocalStorageData, isEU };
