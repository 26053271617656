import { useEffect, useState } from "react";
import axios from "axios";

export default function TableHeader(props) {
    const { className, headers, currentOrderTitle, tableTitle } = props.data;

    function selectAll(e) {
        if (e.target.checked) {
            // Check all
            props.onModelChange((prevState) => {
                const newState = [...prevState];
                newState[0].data.forEach((mdl) => {
                    mdl.checked = true;
                });
                return newState;
            });
            document.querySelector(".delete-all-header-btn").style.opacity = "1";
        } else {
            // Uncheck all
            props.onModelChange((prevState) => {
                const newState = [...prevState];
                newState[0].data.forEach((mdl) => {
                    mdl.checked = false;
                });
                return newState;
            });
            document.querySelector(".delete-all-header-btn").style.opacity = "0";
        }
    }

    const deleteAll = () => {
        let toDelete = [];
        props.rowData.forEach((model) => {
            if (model.checked) {
                toDelete.push(model.id);
            }
        });

        axios.post(window.location.origin + "/models", { model_ids: toDelete });

        props.onModelChange((prevState) => {
            const newState = [...prevState];
            let filtered = newState[0].data.filter((mdl) => {
                // Check if model is deleted
                if (toDelete.includes(mdl.id)) {
                    return false;
                }
                return true;
            });
            // Set localstorage
            localStorage.setItem("modelsData", JSON.stringify([{ data: filtered, section: "quotation" }]));
            return [{ data: filtered, section: "quotation" }];
        });
        document.querySelector(".delete-all-header-btn").style.opacity = "0";
        document.getElementsByClassName("checkbox--select-all")[0].checked = false;
    };

    return (
        <>
            {!props.customHeader && (
                <thead className={className}>
                    <tr>
                        <td colSpan={!!props.responsive ? "100%" : ""}>
                            {!props.responsive ? (
                                <>
                                    {className === "head--quotation" && (
                                        <input className="checkbox--select-all" type="checkbox" onChange={selectAll} />
                                    )}
                                </>
                            ) : (
                                <>
                                    {className === "head--quotation" && (
                                        <div className="mobile-table-head-header">
                                            <input
                                                className="checkbox--select-all"
                                                type="checkbox"
                                                onChange={selectAll}
                                            ></input>
                                            <label>Alle selecteren</label>
                                        </div>
                                    )}
                                </>
                            )}
                        </td>
                        {!props.responsive &&
                            !!headers &&
                            headers.map((header, index) => {
                                return !!index && index + 1 === headers.length ? (
                                    <td key={index} colSpan={className !== "head--quotation" ? "100%" : ""}>
                                        {header}
                                    </td>
                                ) : (
                                    <td key={index}>{header}</td>
                                );
                            })}
                        {className === "head--quotation" && (
                            <td id="delete-all-header-btn" colSpan="100%">
                                <div className="delete-all-header-btn" onClick={deleteAll}>
                                    <span>{props.textData["delete_all_models"]}</span>
                                </div>
                            </td>
                        )}
                    </tr>
                </thead>
            )}
            {!!props.customHeader && (
                <thead className={props.responsive + "head " + className}>
                    <tr>
                        <td colSpan="100%">
                            <div className="current-order-title">
                                <h3>{currentOrderTitle || tableTitle}</h3>
                            </div>
                        </td>
                    </tr>
                </thead>
            )}
        </>
    );
}
