import Select from "react-select";

export default function SelectCountry(props) {
    return (
        <Select
            id={props.id}
            name={props.id}
            multiple={false}
            options={props.countries}
            placeholder={props.textData["account/my_details"]["select_country"]}
            defaultValue={{
                value: props.initialValues[props.id] !== null ? props.initialValues[props.id] : "",
                label: props.initialValues[props.id] !== null ? props.initialValues[props.id] : "",
            }}
            onChange={(e) => {
                props.setFieldValue(props.id, e.value);
                if (props.id === "country" && document.getElementById("differentAddress").checked === false) {
                    props.setShippingCountry(e.value);
                    props.setLoadingShippingPrices(true);
                } else if (props.id === "diff_country") {
                    props.setShippingCountry(e.value);
                    props.setLoadingShippingPrices(true);
                }
            }}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: "#022955",
                    color: "#022955",
                    borderColor: "#dadada",
                    "&:hover": {
                        borderColor: "white",
                        color: "white",
                    },
                },
            })}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#dadada",
                    color: "#022955",
                    ":hover": {
                        borderColor: "#2461ce",
                        color: "white",
                    },
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                }),
                input: (base) => ({
                    ...base,
                    color: "#022955",
                }),
                singleValue: (base) => ({
                    ...base,
                    color: "#022955",
                }),
            }}
        />
    );
}
