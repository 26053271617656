import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { calculateModelPosition, loadLighting } from "../CalculateModelPosition";
import { calculateSurfaceAreaSTEP3MF, getVolume } from "../CalculateDimensions";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

const loadGlbModelFromUrl = (
    modelUrl,
    scene,
    camera,
    formattedColor,
    stopLoading,
    setModelDimensions,
    orientationLocked,
    makeScreenshot = false
) => {
    if (camera === null || scene === null) return;

    const loader = new GLTFLoader();

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco/");
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
    loader.load(modelUrl, function (object) {
        object = object.scene.children[0];
        //object.center();

        // get object geometry
        let volume = 0;
        let surface = 0;
        object.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
                child.castShadow = true;
                child.material.color.set(formattedColor);
                child.material.emissive.set(0x000000); // Reduce emissive light
                child.material.shininess = 50; // Increase shininess

                // Change vertex colors
                if (typeof child.geometry.attributes.color !== "undefined") {
                    for (let i = 0; i < child.geometry.attributes.color.count; i++) {
                        child.geometry.attributes.color.setXYZ(i, 0.5, 0.5, 0.5);
                        child.geometry.attributes.color.needsUpdate = true;
                    }
                }

                volume += getVolume(child.geometry);
                surface += calculateSurfaceAreaSTEP3MF(child.geometry);
            }
        });

        calculateModelPosition(object, camera, orientationLocked);
        scene.add(object);
        loadLighting(scene);

        // get mesh width
        const box = new THREE.Box3().setFromObject(object);
        const size = box.getSize(new THREE.Vector3());
        const meshWidth = size.x;
        const meshHeight = size.y;
        const meshLength = size.z;

        // get biggest dimension
        if (!makeScreenshot) {
            const maxDim = Math.max(meshWidth, meshHeight, meshLength);

            const gridHelper = new THREE.GridHelper(maxDim + maxDim * 0.3, 10, "white", "#022955");
            gridHelper.position.y -= maxDim / 2;
            scene.add(gridHelper);
        }

        // Get model dimensions
        setModelDimensions(getDimensions(object, volume, surface));
        if (typeof stopLoading === "function") {
            stopLoading(makeScreenshot);
        }
    });
};

const getDimensions = (object, volume, surface) => {
    const box = new THREE.Box3().setFromObject(object);
    const size = box.getSize(new THREE.Vector3());
    const modelDimensions = {
        width: size.z.toFixed(2),
        height: size.y.toFixed(2),
        length: size.x.toFixed(2),
        volume: volume.toFixed(2),
        surface: surface.toFixed(2),
    };
    return modelDimensions;
};

export { loadGlbModelFromUrl };
