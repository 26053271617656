import { useEffect, useState, Fragment } from "react";
export default function QuotationBlock(props) {
    const [contentLabel, setContentLabel] = useState();
    useEffect(() => {
        if (!!props.textData && !!fetchAllLabels()) {
            setContentLabel(fetchAllLabels());
        }
    }, [props.textData]);

    const checkAllLabelsEmpty = (labels) => {
        if (labels === null || typeof labels === "undefined") return true;

        let result = true;
        Object.keys(labels).forEach((index) => {
            if (
                labels[index].title !== "" &&
                labels[index].title !== null &&
                typeof labels[index].title !== "undefined"
            ) {
                result = false;
            }
            if (
                labels[index].content !== "" &&
                labels[index].content !== null &&
                typeof labels[index].content !== "undefined"
            ) {
                result = false;
            }
        });
        return result;
    };

    const fetchAllLabels = () => {
        const labels = {};
        for (const [key, value] of Object.entries(props.textData.labels)) {
            if (key.includes("news_block")) {
                const labelParts = key.split("_");
                const labelIndex = labelParts[2]; // Capture the integer value from the label string

                // Create nested object for the label index if it doesn't exist
                if (!labels.hasOwnProperty(labelIndex)) {
                    labels[labelIndex] = {};
                }

                // Assign the value to the corresponding key inside the nested object
                const labelKey = labelParts[3]; // Capture the key after the integer value
                labels[labelIndex][labelKey] = value;
            }
        }
        return labels;
    };

    return (
        <>
            {!!props.textData && !checkAllLabelsEmpty(contentLabel) && (
                <div className="quotation-content-container">
                    <div className="block-head">
                        <h2>{props.textData["title"]}</h2>
                        <p>{props.textData["subtitle"]}</p>
                    </div>
                    {!!contentLabel &&
                        Object.entries(contentLabel).map((label, index) => (
                            <Fragment key={index}>
                                {label[1]["title"] !== "" &&
                                    label[1]["title"] !== null &&
                                    label[1]["content"] !== "" &&
                                    label[1]["content"] !== null && (
                                        <div
                                            className={`
                                                ${"block-body __block-" + index} 
                                                ${
                                                    label[1]["url"] === "" || label[1]["url"] === null
                                                        ? "remove-before-after"
                                                        : ""
                                                }
                                            `}
                                            onClick={() => {
                                                if (label[1]["url"] !== "" && label[1]["url"] !== null) {
                                                    window.open(label[1]["url"], "_blank");
                                                }
                                            }}
                                        >
                                            <p>{label[1]["title"]}</p>
                                            <h4>{label[1]["content"]}</h4>
                                        </div>
                                    )}
                            </Fragment>
                        ))}
                </div>
            )}
        </>
    );
}
