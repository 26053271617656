import axios from "axios";
import { getSettingsId } from "../ProductSettings/Helper";

const calcShippingCosts = (modelsData, productSettings, country = "Netherlands") => {
    if (country === null) {
        country = "Netherlands";
    }

    let reqModels = [];
    if (modelsData.length > 0 && modelsData[0].data.length > 0) {
        modelsData[0].data.forEach((element) => {
            reqModels.push({
                id: element.id,
                amount: typeof element.amount !== "undefined" ? element.amount : element.pivot.amount,
                settings_id: getSettingsId(element.settings, productSettings),
            });
        });
    }

    let reqProducts = [];
    const products = JSON.parse(window.localStorage.getItem("productsData"));
    if (typeof products !== "undefined" && products !== null) {
        products.forEach((product) => {
            reqProducts.push({
                id: product.id,
                amount: product.quantity,
                settings_id: product.settings.settingsId,
            });
        });
    }

    if (reqModels.length > 0 || reqProducts.length > 0) {
        return axios
            .post(window.location.origin + "/api/calculate-shipping-costs-multiple", {
                models: reqModels,
                products: reqProducts,
                country: country,
            })
            .then((response) => {
                return new Promise((resolve) => {
                    resolve(response.data);
                });
            });
    }
    return new Promise((resolve) => {
        resolve(0);
    });
};

export { calcShippingCosts };
