import axios from "axios";

const formatModels = (allModels) => {
    return allModels[0].data.reduce(function (result, element) {
        let model = {
            model_id: element.id,
            application: element.settings.application,
            material: element.settings.material,
            color: element.settings.color,
            polish: element.settings.polish,
            coating: element.settings.coating,
            orientation: element.settings.orientation,
            sterile: element.settings.sterile,
            intendedUse: element.settings.intendedUse,
            clinicalUse: element.settings.clinicalUse,
            custom: element.settings.custom,
            case: element.settings.case,
            practitioner: element.settings.practitioner,
        };
        result.push(model);
        return result;
    }, []);
};

const updateShareUrl = (allModels, uuid) => {
    allModels = formatModels(allModels);
    const ShareAPI = window.location.origin + "/update-shared-models";
    if (typeof allModels !== "undefined" && allModels.length > 0) {
        axios
            .put(ShareAPI, {
                uuid: uuid,
                models: allModels,
            })
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            });
    }
};

const createNewShareUrl = (allModels, loggedInUser) => {
    allModels = formatModels(allModels);
    const ShareAPI = window.location.origin + "/share-models";
    if (typeof allModels !== "undefined" && allModels.length > 0) {
        axios
            .post(ShareAPI, {
                user_id: loggedInUser.id,
                models: allModels,
            })
            .then((response) => {
                const params = new URLSearchParams(window.location.search);
                params.set("share", response.data.uuid);
                window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
            })
            .catch((error) => {
                console.error(error);
            });
    }
};

export { updateShareUrl, createNewShareUrl };
