import { Link } from "react-router-dom";
import React from "react";

export default function ErrorComponent(props) {
    const { error } = props;
    console.error(error);

    const clearCache = () => {
        // Clear cache and reload page
        localStorage.clear();
        window.location.reload();
    };

    return (
        <div className={"error"}>
            <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
            <strong>Er heeft zich een fout voorgedaan tijdens het laden van de configurator.</strong>
            <a href={"#"} onClick={clearCache}>
                probeer het opnieuw
            </a>
        </div>
    );
}
