import * as THREE from "three";
import occtimportjs from "occt-import-js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { calculateModelPosition, loadLighting } from "../CalculateModelPosition";
import convertObjectToGlb from "./ConvertObjectToGlb";
import { calculateSurfaceAreaSTEP3MF, getVolume } from "../CalculateDimensions";

const wasmUrl = "https://cdn.jsdelivr.net/npm/occt-import-js@0.0.18/dist/occt-import-js.wasm";

let occtImportPromise = null;
const loadOcctImport = () => {
    if (occtImportPromise) {
        return occtImportPromise;
    }
    const occt = occtimportjs({
        locateFile: (name) => {
            // return occtimportWasm
            return wasmUrl;
        },
    });
    occtImportPromise = occt;
    return occtImportPromise;
};

const processGeometries = (result) => {
    const targetObject = new THREE.Object3D();

    // process the geometries of the result
    for (let resultMesh of result.meshes) {
        let geometry = new THREE.BufferGeometry();

        geometry.setAttribute("position", new THREE.Float32BufferAttribute(resultMesh.attributes.position.array, 3));
        if (resultMesh.attributes.normal) {
            geometry.setAttribute("normal", new THREE.Float32BufferAttribute(resultMesh.attributes.normal.array, 3));
        }
        const index = Uint32Array.from(resultMesh.index.array);
        geometry.setIndex(new THREE.BufferAttribute(index, 1));
        // geometry = geometry.clone();

        let material = null;
        let defaultColor = [1, 1, 1];
        if (resultMesh.color) {
            const color = new THREE.Color(resultMesh.color[0], resultMesh.color[1], resultMesh.color[2]);
            defaultColor = resultMesh.color;
            material = new THREE.MeshPhongMaterial({ color: color });
        }
        // else {
        // material = new THREE.MeshPhongMaterial({ color: 0xcccccc })
        // material = new THREE.MeshPhongMaterial({ color: '#c8c8c8' })
        // }

        if (resultMesh.face_colors) {
            // console.log(resultMesh.face_colors)
            material = new THREE.MeshPhongMaterial({
                vertexColors: true,
            });
            // For Faces
            // const faceColors = [];
            // for (let faceColorGroup of resultMesh.face_colors) {
            //   const { color, first, last } = faceColorGroup
            //   for (let i = first; i <= last; i++) {
            //     faceColors.push(color[0], color[1], color[2])
            //   }
            // }

            // For vertices
            const indexedGeometry = geometry;
            geometry = geometry.toNonIndexed();
            const faceColors = new Array(geometry.attributes.position.array.length).fill(0);
            for (let faceColorGroup of resultMesh.face_colors) {
                const { color, first, last } = faceColorGroup;
                for (let i = first; i <= last; i++) {
                    // faceColors.push(color[0], color[1], color[2])
                    // faceColors.push(color[0], color[1], color[2])
                    // faceColors.push(color[0], color[1], color[2])

                    // faceColors[i * 9] = color[0]
                    // faceColors[i * 9 + 3] = color[0]
                    // faceColors[i * 9 + 6] = color[0]

                    faceColors.splice(Math.floor(i * 9), 9, ...color, ...color, ...color);
                }
            }

            // console.log('faceColors', faceColors)
            geometry.setAttribute("color", new THREE.Float32BufferAttribute(faceColors, 3));
            // console.log('geometry with face colors', resultMesh.face_colors.length, geometry, indexedGeometry)
            // console.log('position', geometry.attributes.position.count, geometry.attributes.position.array.length)
            // console.log('color', geometry.attributes.color.count, geometry.attributes.color.array.length)
            // console.log('geometry with face colors')
        }

        if (!material) {
            material = new THREE.MeshPhongMaterial({ color: "#c8c8c8" });
        }

        const mesh = new THREE.Mesh(geometry, material);
        targetObject.add(mesh);
    }
    return targetObject;
};

async function loadStepFromBuffer(buffer, setLoadedStepData) {
    // Show loading popup

    const occt = await loadOcctImport();
    // read the imported step file
    let fileBuffer = new Uint8Array(buffer);
    let start = Date.now();
    let result = occt.ReadStepFile(fileBuffer, { linearDeflection: 0.1, angularDeflection: 0.1 });
    let timeTaken = Date.now() - start;

    if (result.success === false) {
        console.log("Error reading step file");
        return;
    }
    setLoadedStepData(result);

    return processGeometries(result);
}

async function loadStepFromUrl(fileUrl, setLoadedStepData) {
    // init occt-import-js
    // const occtimportWasm = await import('occt-import-js/dist/occt-import-js.wasm').then((res) => res.default)
    // console.log('occtimportWasm', occtimportWasm)
    const occt = await loadOcctImport();

    const targetObject = new THREE.Object3D();

    // download a step file
    // let fileUrl = '../test/testfiles/cax-if/as1_pe_203.stp';
    console.log(fileUrl);
    let response = await fetch(fileUrl);
    let buffer = await response.arrayBuffer();

    // read the imported step file
    let fileBuffer = new Uint8Array(buffer);
    let start = Date.now();
    let result = occt.ReadStepFile(fileBuffer, { linearDeflection: 0.1, angularDeflection: 0.1 });
    let timeTaken = Date.now() - start;

    if (result.success === false) {
        console.log("Error reading step file");
        return;
    }
    setLoadedStepData(result);

    return processGeometries(result);
}

const loadStepModelFromBuffer = (
    modelData,
    scene,
    camera,
    formattedColor,
    stopLoading,
    setModelDimensions,
    orientationLocked,
    setLoadedStepData,
    makeScreenshot = false
) => {
    // Convert .stp .step to glb
    loadStepFromBuffer(modelData, setLoadedStepData).then((data) => {
        loadGlb(
            data,
            stopLoading,
            formattedColor,
            scene,
            camera,
            setModelDimensions,
            orientationLocked,
            makeScreenshot
        );
    });
};

const loadStepModelFromUrl = (
    modelData,
    scene,
    camera,
    formattedColor,
    stopLoading,
    setModelDimensions,
    orientationLocked,
    setLoadedStepData,
    makeScreenshot
) => {
    // Convert .stp .step to glb
    loadStepFromUrl(modelData, setLoadedStepData).then((data) => {
        loadGlb(
            data,
            stopLoading,
            formattedColor,
            scene,
            camera,
            setModelDimensions,
            orientationLocked,
            makeScreenshot
        );
    });
};

const loadStepModelFromCache = (
    cachedModel,
    scene,
    camera,
    formattedColor,
    stopLoading,
    setModelDimensions,
    orientationLocked,
    makeScreenshot = false
) => {
    const data = processGeometries(cachedModel);
    loadGlb(data, stopLoading, formattedColor, scene, camera, setModelDimensions, orientationLocked, makeScreenshot);
};

const loadGlb = (
    data,
    stopLoading,
    formattedColor,
    scene,
    camera,
    setModelDimensions,
    orientationLocked,
    makeScreenshot = false
) => {
    convertObjectToGlb(data).then((glb) => {
        const loader = new GLTFLoader();
        loader.load(glb, function (object) {
            object = object.scene.children[0];
            //object.center();

            // get object geometry
            let volume = 0;
            let surface = 0;
            object.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    child.castShadow = true;
                    child.material.color.set(formattedColor);
                    child.material.emissive.set(0x000000); // Reduce emissive light
                    child.material.shininess = 50; // Increase shininess

                    // Change vertex colors
                    if (typeof child.geometry.attributes.color !== "undefined") {
                        for (let i = 0; i < child.geometry.attributes.color.count; i++) {
                            child.geometry.attributes.color.setXYZ(i, 0.5, 0.5, 0.5);
                            child.geometry.attributes.color.needsUpdate = true;
                        }
                    }
                    volume += getVolume(child.geometry);
                    surface += calculateSurfaceAreaSTEP3MF(child.geometry);
                }
            });

            calculateModelPosition(object, camera, orientationLocked);
            scene.add(object);
            loadLighting(scene);

            // get mesh width
            const box = new THREE.Box3().setFromObject(object);
            const size = box.getSize(new THREE.Vector3());
            const meshWidth = size.x;
            const meshHeight = size.y;
            const meshLength = size.z;

            // get biggest dimension
            if (!makeScreenshot) {
                const maxDim = Math.max(meshWidth, meshHeight, meshLength);

                const gridHelper = new THREE.GridHelper(maxDim + maxDim * 0.3, 10, "white", "#022955");
                gridHelper.position.y -= maxDim / 2;
                scene.add(gridHelper);
            }

            // Get model dimensions
            setModelDimensions(getDimensions(object, volume, surface));
        });
    });
    if (typeof stopLoading === "function") {
        stopLoading(makeScreenshot);
    }
};

const getDimensions = (object, volume, surface) => {
    const box = new THREE.Box3().setFromObject(object);
    const size = box.getSize(new THREE.Vector3());
    const modelDimensions = {
        width: size.z.toFixed(2),
        height: size.y.toFixed(2),
        length: size.x.toFixed(2),
        volume: volume.toFixed(2),
        surface: surface.toFixed(2),
    };
    return modelDimensions;
};

export { loadStepModelFromBuffer, loadStepModelFromUrl, loadStepModelFromCache };
