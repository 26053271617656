import axios from "axios";
import { compressToUTF16, decompressFromUTF16 } from "lz-string";

export default function RetrieveProductSettings(props, language = "en") {
    const fetchProductSettings = () => {
        const productSettingsAPI = window.location.origin + "/api/product/options";
        return axios
            .get(productSettingsAPI + `?language=${language}`)
            .then((response) => {
                let mandatoryOptions = [];
                response.data.forEach((application) => {
                    if (
                        typeof application.mandatory_options !== "undefined" &&
                        application.mandatory_options.length > 0
                    ) {
                        mandatoryOptions.push({
                            application: application.name,
                            mandatory_options: application.mandatory_options,
                        });
                    }
                });
                localStorage.setItem("productSettingsLanguage", JSON.stringify(language));
                localStorage.setItem("mandatoryProductOptions", JSON.stringify(mandatoryOptions));
                localStorage.setItem("productSettings", compressToUTF16(JSON.stringify(response.data)));
                localStorage.setItem("date_last_retrieved_product_settings", new Date().toISOString());
                if (typeof props.setMandatoryProductOptions !== "undefined") {
                    props.setMandatoryProductSettings(mandatoryOptions);
                }
                if (typeof props.setProductSettings !== "undefined") {
                    props.setProductSettings(response.data);
                }
                return {
                    productSettings: response.data,
                    mandatoryProductOptions: mandatoryOptions,
                };
            })
            .catch((error) => {
                console.error(error);
            });
    };

    if (
        JSON.parse(localStorage.getItem("productSettingsLanguage")) === language &&
        typeof localStorage.getItem("productSettings") !== "undefined" &&
        localStorage.getItem("productSettings") !== null
    ) {
        if (localStorage.getItem("date_last_retrieved_product_settings") !== null) {
            const dateLastRetrieved = new Date(localStorage.getItem("date_last_retrieved_product_settings"));
            const currentDate = new Date();
            const diffTime = Math.abs(currentDate - dateLastRetrieved);
            const diffMinutes = Math.ceil(diffTime / (1000 * 60));
            if (diffMinutes > 15) {
                return fetchProductSettings(); // Get new data if it's been more than 15 minutes
            }
        } else {
            return fetchProductSettings();
        }

        if (typeof props.setMandatoryProductSettings !== "undefined") {
            props.setMandatoryProductSettings(JSON.parse(localStorage.getItem("mandatoryProductOptions")));
        }
        if (typeof props.setProductSettings !== "undefined") {
            try {
                props.setProductSettings(JSON.parse(decompressFromUTF16(localStorage.getItem("productSettings"))));
            } catch (err) {
                console.error(err, "resetting product settings in local storage and fetching new product settings");
                localStorage.removeItem("productSettings");
                localStorage.removeItem("productSettingsLanguage");
                return fetchProductSettings();
            }
        }

        return new Promise((resolve) => {
            resolve({
                productSettings: JSON.parse(decompressFromUTF16(localStorage.getItem("productSettings"))),
                mandatoryProductOptions: JSON.parse(localStorage.getItem("mandatoryProductOptions")),
            });
        });
    } else {
        return fetchProductSettings();
    }
}
