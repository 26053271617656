import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import * as THREE from "three";

export const loadLabels = (scene, dimensions) => {
    const loader = new FontLoader();
    loader.load(window.location.origin + "/fonts/helvetiker_bold.typeface.json", function (response) {
        let mesh = loadLabel(response, "x", dimensions);
        scene.add(mesh);
        mesh = loadLabel(response, "y", dimensions);
        scene.add(mesh);
        mesh = loadLabel(response, "z", dimensions);
        scene.add(mesh);
    });
};

const loadLabel = (font, text, dimensions) => {
    let biggestDimension;
    if (dimensions.width >= dimensions.length && dimensions.width >= dimensions.height) {
        biggestDimension = dimensions.width;
    } else if (dimensions.length >= dimensions.width && dimensions.length >= dimensions.height) {
        biggestDimension = dimensions.length;
    } else {
        biggestDimension = dimensions.height;
    }

    let textGeo = new TextGeometry(text, {
        font: font,
        size: biggestDimension / 10,
        height: biggestDimension / 100,
        bevelEnabled: false,
    });

    let textMaterial = new THREE.MeshPhongMaterial();
    let mesh = new THREE.Mesh(textGeo, textMaterial);
    mesh.position.x = 0;
    mesh.position.y = 0;
    mesh.position.z = 0;
    switch (text) {
        case "x":
            mesh.position.z += biggestDimension;
            // set color
            textMaterial.color.setHex(0xff0000);
            break;
        case "y":
            mesh.position.x += biggestDimension;
            // set color
            textMaterial.color.setHex(0x00ff00);
            break;
        case "z":
            mesh.position.y += biggestDimension;
            // set color
            textMaterial.color.setHex(0x0000ff);
            break;
        default:
            break;
    }
    return mesh;
};
