import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-regular-svg-icons";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useGlobalState } from "../GlobalContext";

export default function ContactBlock(props) {
    const { generalSettings } = useGlobalState();

    const getTelephone = () => {
        if (
            generalSettings["telephone"] !== null &&
            typeof generalSettings["telephone"] !== "undefined" &&
            generalSettings["telephone"] !== ""
        ) {
            return generalSettings["telephone"];
        } else {
            return props.textData["phone"];
        }
    };

    const getWhatsapp = () => {
        if (
            generalSettings["whatsapp"] !== null &&
            typeof generalSettings["whatsapp"] !== "undefined" &&
            generalSettings["whatsapp"] !== ""
        ) {
            return generalSettings["whatsapp"];
        } else {
            return props.textData["whatsapp"];
        }
    };

    const getEmail = () => {
        if (
            generalSettings["email"] !== null &&
            typeof generalSettings["email"] !== "undefined" &&
            generalSettings["email"] !== ""
        ) {
            return generalSettings["email"];
        } else {
            return props.textData["email"];
        }
    };

    const onlyNumbers = (input) => {
        return input.replace(/[^0-9]/g, "");
    };

    return (
        <>
            {!props.responsive && (
                <div className="contact-content-container">
                    <div className="block-head">
                        <h2>{props.textData["title"]}</h2>
                        <p>{props.textData["subtitle"]}</p>
                    </div>
                    <div className="block-body">
                        <div className="contact-information-container">
                            <div className="phone">
                                <div className="contact-details">
                                    <FontAwesomeIcon icon={faPhone} size="lg" />
                                    <a href={"tel:" + onlyNumbers(getTelephone())} rel="noreferrer" target={"_blank"}>
                                        {getTelephone()}
                                    </a>
                                </div>
                                <div className="contact-status">
                                    <FontAwesomeIcon icon={faCircle} size="sm" />
                                    <p>{props.textData["phone_status"]}</p>
                                </div>
                            </div>
                            <div className="whatsapp">
                                <div className="contact-details">
                                    <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                                    <a
                                        href={"https://wa.me/" + onlyNumbers(getWhatsapp())}
                                        rel="noreferrer"
                                        target={"_blank"}
                                    >
                                        {getWhatsapp()}
                                    </a>
                                </div>
                                <div className="contact-status">
                                    <FontAwesomeIcon icon={faCircle} size="sm" />
                                    <span>{props.textData["whatsapp_status"]}</span>
                                </div>
                            </div>
                            <div className="email">
                                <div className="contact-details">
                                    <FontAwesomeIcon icon={faEnvelope} size="lg" />
                                    <a href={"mailto:" + getEmail()} target={"_blank"}>
                                        {getEmail()}
                                    </a>
                                </div>
                                <div className="contact-status">
                                    <FontAwesomeIcon icon={faCircle} size="sm" />
                                    <span>{props.textData["email_status"]}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!!props.responsive && (
                <div className={props.responsive + "contact-content-container"}>
                    <div className="block-head">
                        <h2>{props.textData["title"]}</h2>
                        <p>{props.textData["subtitle"]}</p>
                    </div>
                    <div className="block-body">
                        <div className="contact-information-container">
                            <div className="phone">
                                <div className="contact-details">
                                    <FontAwesomeIcon icon={faPhone} size="lg" />
                                    <p>{props.textData["phone"]}</p>
                                </div>
                                <div className="contact-status">
                                    <FontAwesomeIcon icon={faCircle} size="sm" />
                                    <span>{props.textData["phone_status"]}</span>
                                </div>
                            </div>
                            <div className="whatsapp">
                                <div className="contact-details">
                                    <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                                    <p>{props.textData["whatsapp"]}</p>
                                </div>
                                <div className="contact-status">
                                    <FontAwesomeIcon icon={faCircle} size="sm" />
                                    <span>{props.textData["whatsapp_status"]}</span>
                                </div>
                            </div>
                            <div className="email">
                                <div className="contact-details">
                                    <FontAwesomeIcon icon={faEnvelope} size="lg" />
                                    <p>{props.textData["email"]}</p>
                                </div>
                                <div className="contact-status">
                                    <FontAwesomeIcon icon={faCircle} size="sm" />
                                    <span>{props.textData["email_status"]}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
