import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { calculateModelPosition, loadLighting } from "../CalculateModelPosition";
import { ThreeVolume } from "three-volume";
import { calculateSurfaceAreaSTL } from "../CalculateDimensions";

const loadStlModelFromUrl = (
    modelUrl,
    scene,
    camera,
    material,
    stopLoading,
    setModelDimensions,
    orientationLocked,
    makeScreenshot = false
) => {
    if (camera === null || scene === null) return;

    const loader = new STLLoader();
    loader.load(
        modelUrl,
        function (geometry) {
            // Center the geometry, so we can easily rotate around the models' center point
            geometry.center();
            const mesh = new THREE.Mesh(geometry, material);
            mesh.castShadow = true;
            mesh.receiveShadow = true;

            // Add some lights so its visible
            loadLighting(scene);
            calculateModelPosition(mesh, camera, orientationLocked, makeScreenshot);

            // get mesh width
            const box = new THREE.Box3().setFromObject(mesh);
            const size = box.getSize(new THREE.Vector3());
            const meshWidth = size.x;
            const meshHeight = size.y;
            const meshLength = size.z;
            scene.add(mesh);

            // get biggest dimension
            if (!makeScreenshot) {
                const maxDim = Math.max(meshWidth, meshHeight, meshLength);

                const gridHelper = new THREE.GridHelper(maxDim + maxDim * 0.3, 10, "white", "#022955");
                gridHelper.position.y -= maxDim / 2;
                scene.add(gridHelper);
            }

            // Get model dimensions
            setModelDimensions(getDimensions(mesh));

            if (typeof stopLoading === "function") {
                stopLoading(makeScreenshot);
            }
        },
        (xhr) => {
            //console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
        },
        (error) => {
            console.log(error);
        }
    );
};

const loadStlModelFromBuffer = (
    modelData,
    scene,
    camera,
    material,
    stopLoading,
    setModelDimensions,
    orientationLocked,
    makeScreenshot = false
) => {
    const loader = new STLLoader();
    const geometry = loader.parse(modelData);
    const mesh = new THREE.Mesh(geometry, material);

    mesh.castShadow = true;
    mesh.receiveShadow = true;
    // Add some lights so its visible
    loadLighting(scene);
    calculateModelPosition(mesh, camera, orientationLocked, makeScreenshot);
    scene.add(mesh);

    // Get model dimensions
    setModelDimensions(getDimensions(mesh));

    if (typeof stopLoading === "function") {
        stopLoading(makeScreenshot);
    }
};

const getDimensions = (mesh) => {
    const box = new THREE.Box3().setFromObject(mesh);
    const size = box.getSize(new THREE.Vector3());
    const volume = ThreeVolume(mesh.geometry);
    const modelDimensions = {
        width: size.z.toFixed(2),
        height: size.y.toFixed(2),
        length: size.x.toFixed(2),
        volume: volume.toFixed(2),
        surface: (calculateSurfaceAreaSTL(mesh.geometry) / 100).toFixed(2),
    };
    return modelDimensions;
};

export { loadStlModelFromUrl, loadStlModelFromBuffer };
