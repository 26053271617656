import * as THREE from "three";
import { ThreeMFLoader } from "three/examples/jsm/loaders/3MFLoader";
import { calculateModelPosition, loadLighting } from "../CalculateModelPosition";
import { calculateSurfaceAreaSTEP3MF, getVolume } from "../CalculateDimensions";

const load3mfModelFromBuffer = (
    modelData,
    scene,
    camera,
    formattedColor = "rgb(150, 150, 150)",
    stopLoading,
    setModelDimensions,
    orientationLocked,
    makeScreenshot = false
) => {
    const loader = new ThreeMFLoader();
    const object = loader.parse(modelData);
    // object.center();

    // get object geometry
    let volume = 0;
    let surfaceArea = 0;
    object.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            child.castShadow = true;
            child.material.color.set(formattedColor);
            child.material.emissive.set(0x000000); // Reduce emissive light
            child.material.shininess = 200; // Increase shininess

            // Change vertex colors
            if (typeof child.geometry.attributes.color !== "undefined") {
                for (let i = 0; i < child.geometry.attributes.color.count; i++) {
                    child.geometry.attributes.color.setXYZ(i, 0.5, 0.5, 0.5);
                }
                child.geometry.attributes.color.needsUpdate = true;
            }
            volume += getVolume(child.geometry);
            surfaceArea += calculateSurfaceAreaSTEP3MF(child.geometry);
        }
    });

    calculateModelPosition(object, camera, orientationLocked);
    loadLighting(scene);
    scene.add(object);

    // get mesh width
    const box = new THREE.Box3().setFromObject(object);
    const size = box.getSize(new THREE.Vector3());
    const meshWidth = size.x;
    const meshHeight = size.y;
    const meshLength = size.z;

    // get biggest dimension
    if (!makeScreenshot) {
        const maxDim = Math.max(meshWidth, meshHeight, meshLength);

        const gridHelper = new THREE.GridHelper(maxDim + maxDim * 0.3, 10, "white", "#022955");
        gridHelper.position.y -= maxDim / 2;
        scene.add(gridHelper);
    }

    // Get model dimensions
    setModelDimensions(getDimensions(object, volume, surfaceArea));

    if (typeof stopLoading === "function") {
        stopLoading(makeScreenshot);
    }
};

const load3mfModelFromUrl = (
    modelUrl,
    scene,
    camera,
    formattedColor,
    stopLoading,
    setModelDimensions,
    orientationLocked,
    makeScreenshot = false
) => {
    if (camera === null || scene === null) return;

    const loader = new ThreeMFLoader();
    loader.load(modelUrl, function (object) {
        // get object geometry
        let volume = 0;
        let surfaceArea = 0;
        object.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
                child.castShadow = true;
                child.material.color.set(formattedColor);
                child.material.emissive.set(0x000000); // Reduce emissive light
                child.material.shininess = 200; // Increase shininess

                // Change vertex colors
                if (typeof child.geometry.attributes.color !== "undefined") {
                    for (let i = 0; i < child.geometry.attributes.color.count; i++) {
                        child.geometry.attributes.color.setXYZ(i, 0.5, 0.5, 0.5);
                        child.geometry.attributes.color.needsUpdate = true;
                    }
                }
                volume += getVolume(child.geometry);
                surfaceArea += calculateSurfaceAreaSTEP3MF(child.geometry);
            }
        });

        // Get model dimensions
        setModelDimensions(getDimensions(object, volume, surfaceArea));

        calculateModelPosition(object, camera, orientationLocked);
        loadLighting(scene);
        scene.add(object);

        // get mesh width
        const box = new THREE.Box3().setFromObject(object);
        const size = box.getSize(new THREE.Vector3());
        const meshWidth = size.x;
        const meshHeight = size.y;
        const meshLength = size.z;

        // get biggest dimension
        if (!makeScreenshot) {
            const maxDim = Math.max(meshWidth, meshHeight, meshLength);

            const gridHelper = new THREE.GridHelper(maxDim + maxDim * 0.3, 10, "white", "#022955");
            gridHelper.position.y -= maxDim / 2;
            scene.add(gridHelper);
        }
    });
    if (typeof stopLoading === "function") {
        stopLoading(makeScreenshot);
    }
};

const getDimensions = (object, volume, surface) => {
    const box = new THREE.Box3().setFromObject(object);
    const size = box.getSize(new THREE.Vector3());
    const modelDimensions = {
        width: size.z.toFixed(2),
        height: size.y.toFixed(2),
        length: size.x.toFixed(2),
        volume: volume.toFixed(2),
        surface: surface.toFixed(2),
    };
    return modelDimensions;
};

export { load3mfModelFromBuffer, load3mfModelFromUrl };
