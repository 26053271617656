import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useGlobalState } from "../GlobalContext";
import { debounce } from "lodash";

const SearchField = (props) => {
    const [isOpened, setIsOpened] = useState(false);
    const { loggedInUser } = useGlobalState();

    useEffect(() => {
        const searchField = document.querySelector(".search-input");
        if (isOpened) {
            searchField.classList.add("search-field-opened");
            searchField.focus();
        } else {
            searchField.classList.remove("search-field-opened");
            searchField.value = "";
        }
    }, [isOpened]);

    const searchForKeyword = (e) => {
        // Make a GET request for the uploaded models
        let searchValue = e.target.value.toLowerCase();
        if (searchValue === "") {
            return;
        }

        props.setLoading(true);

        const quotationAPI = window.location.origin + "/api/orders";
        axios
            .get(quotationAPI, {
                params: {
                    user_id: loggedInUser.user.id,
                    page: props.page,
                    per_page: 5,
                    search: searchValue,
                },
            })
            .then((response) => {
                let result = response.data;
                result.section = "orders";
                props.setOrderData([result]);
                props.setAllOrders([result]);
                props.setTotalPages(response.data.total_pages); // Assuming the API returns total_pages
                props.setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                props.setLoading(false);
            });
    };

    const debouncedSearchForKeyword = useCallback(
        debounce((keyword) => searchForKeyword(keyword), 700),
        []
    );
    const handleChange = (e) => {
        debouncedSearchForKeyword(e);
    };

    return (
        <div className="search-field">
            <button className="search-field-btn" onClick={() => setIsOpened(!isOpened)}></button>
            <input
                className="search-input"
                type="text"
                placeholder={props.textData["account/order_overview"]["search_button_title"]}
                onChange={handleChange}
            />
        </div>
    );
};

export default SearchField;
