import { useState, useEffect } from "react";
import InfoButton from "../InfoButton";

export default function Coating(props) {
    const [selected, setSelected] = useState(null);
    const [buttons, setButtons] = useState([]);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        renderButtons();
    }, [props.orientations]);

    useEffect(() => {
        if (clicked === true) {
            setTimeout(() => {
                // Submit the form
                submit();
            }, 100);
        }
    }, [selected]);

    function clickSelect(e, i) {
        select(e, i);
        setClicked(true);
    }

    function renderButtons() {
        const buttonsArray = Object.entries(props.orientations).map(([key, value], i) => (
            <button
                className={`btn btn-option ${
                    value === "unlocked" ? "lock-icon-unlocked unlocked" : "lock-icon-locked locked"
                } 
                ${props.config.orientation === null && i === 0 ? "selected" : ""}
                ${props.config.orientation === value ? "selected" : ""}
                ${value === "unlocked" ? "unlocked" : ""}
                ${value === "locked" ? "locked" : ""}`}
                key={i}
                onClick={(e) => clickSelect(e, i)}
            >
                {value}
            </button>
        ));
        setButtons(buttonsArray);
    }

    function select(e, i) {
        // Remove selected class from all buttons
        const container = document.getElementsByClassName("orientation-settings-details");
        const buttons = container[0].getElementsByClassName("btn-option");
        for (var i = 0; i < buttons.length; i++) buttons[i].classList.remove("selected");
        // Add selected class to clicked button
        setSelected(e.target);
        e.target.classList.add("selected");
    }

    function submit() {
        if (selected !== null) {
            props.updateSetting("orientation", selected.innerText);
        } else {
            // Check if there is a button with state selected
            let btn = document.getElementsByClassName("btn-option selected")[0];
            if (btn !== null && typeof btn !== "undefined") {
                props.updateSetting("orientation", btn.innerText);
            }
        }
        props.goNext("overview");
        props.clearSettingsThatAreNotAllowed(false, "orientation");
    }

    function goBack() {
        props.goNext("coating");
    }

    return (
        <div className="orientation-settings-details">
            <h1>{props.textData.title}</h1>
            <br />
            <p className="subtitle">{props.textData.subtitle}</p>
            <br />
            <h2 className="h2-info-parent">{props.textData.header}</h2>
            <InfoButton
                info={props.productSettingExplanations.filter((info) => {
                    return info.type === "orientation";
                })}
                textData={props.infoTextData}
            />
            <div className="material-btns">{buttons}</div>
            {props.orientations !== null && props.orientations.length > 0 ? "" : <p>{props.textData.not_available}</p>}
        </div>
    );
}
