import { useState, useEffect, useRef, Fragment, useCallback, useMemo } from "react";
import ProductSettings from "./ProductSettings/ProductSettings";
import Table from "./Table/Table";
import NavigationMenu from "./NavigationMenu";
import Upload from "./Upload/Upload";
import QuotationBlock from "./ContentBlock/QuotationBlock";
import axios from "axios";
import Sidebar from "./Sidebar";
import OrderOverview from "./Account/OrderOverview";
import QuotationOverview from "./Account/QuotationOverview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { useGlobalState } from "./GlobalContext";
import { useMediaQuery } from "react-responsive";
import ProductLibraryOverview from "./Account/ProductLibraryOverview";
import Settings from "./Account/Settings";
import Details from "./Account/Details";
import { useLocation } from "react-router-dom";
import LoginPopup from "./Popups/LoginPopup";
import { updateShareUrl } from "./Functions/shareModels";
import ColorUtil from "./ModelViewer/ColorUtil";
import { updateModelColor } from "./ModelViewer/ColorUpdater";
import RetrieveProductSettings from "./ProductSettings/RetrieveProductSettings";
import { getSettingsId } from "./ProductSettings/Helper";

export default function Home(props) {
    // props from react-router-dom in app.jsx
    const [modelsData, setModelsData] = useState();
    const getColorInRgb = ColorUtil(props);
    const { uploadCount } = useGlobalState();
    const [textData, setTextData] = useState(null);
    const { loggedInUser } = useGlobalState();
    const { activeNavigationMenuItem, setActiveNavigationMenuItem } = useGlobalState();
    const lastUploadedModels = useRef([]);
    // const [activeMenuItem, setActiveMenuItem] = useState(selectedMenuItem);
    const [hasModelsSelected, setHasModelsSelected] = useState(false);
    const [tempModelViewers, setTempModelViewers] = useState([]);
    const { productSettings, mandatoryProductSettings, setProductSettings, setMandatoryProductSettings, currentPage } =
        useGlobalState();
    const [loginModal, setLoginModal] = useState(false);
    const initialLoad = useRef(false);
    const loadedInitModelColors = useRef(false);
    const updatingPrices = useRef(false);
    const [updatingMultipleModels, setUpdatingMultipleModels] = useState(false);
    const updateNextIteration = useRef(false);
    const responsiveClass = "rsp-";
    const { state } = useLocation();
    const [saveSettingsFooter, setSaveSettingsFooter] = useState(null);
    const [productsData, setProductsData] = useState(JSON.parse(window.localStorage.getItem("productsData")));
    const [modelsUpdaterQueue, setModelsUpdaterQueue] = useState([]);
    const [amountOfModelsBeingColored, setAmountOfModelsBeingColored] = useState(0);
    const [disableProductSettings, setDisableProductSettings] = useState(false);
    const [afterModulSettingsUpdate, setAfterModulSettingsUpdate] = useState([]);

    // MediaQuery variables
    // const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1090px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1150px)" });

    useEffect(() => {
        updatingPrices.current = disableProductSettings;
    }, [disableProductSettings]);

    // Update shipping costs after updating the modelsData
    useEffect(() => {
        if (afterModulSettingsUpdate.length > 0) {
            afterModulSettingsUpdate.forEach((model) => {
                // Loop modelsData
                if (modelsData !== undefined && modelsData.length > 0) {
                    modelsData[0].data.forEach((dataModel) => {
                        if (dataModel.id === model.id) {
                            updateShippingCosts(dataModel);
                        }
                    });
                }
            });
            setAfterModulSettingsUpdate([]);
        }
    }, [modelsData]);

    useEffect(() => {
        if (modelsUpdaterQueue.length > 0 && amountOfModelsBeingColored < 1) {
            setAmountOfModelsBeingColored((prev) => {
                return prev + 1;
            });
            let model = modelsUpdaterQueue[0];

            let resultingPromise = null;
            if (
                typeof model.settings !== "undefined" &&
                typeof model.settings.color !== "undefined" &&
                model.settings.color !== null
            ) {
                if (model.color !== null && typeof model.color !== "undefined") {
                    let promise = updateModelColor(model, modelsData, model.color, null, handleScreenshot);
                    resultingPromise = promise;
                } else {
                    resultingPromise = getColorInRgb(model.settings.color).then((rgb) => {
                        return updateModelColor(model, [{ data: modelsData }], rgb, null, handleScreenshot);
                    });
                }
            } else {
                // Dont need to update color
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((stateModel) => {
                        if (stateModel.id === model.id) {
                            stateModel.loading = false;
                        }
                        return stateModel;
                    });
                    let result = [{ ...prevState[0], data: newData }];
                    // set localStorage
                    localStorage.setItem("modelsData", JSON.stringify(result));
                    return result;
                });

                // remove model from queue
                setTimeout(() => {
                    // remove model from queue
                    let newQueue = modelsUpdaterQueue;
                    newQueue.shift();
                    setModelsUpdaterQueue(newQueue);
                    setAmountOfModelsBeingColored((prev) => {
                        return 0;
                    });
                }, 700);
            }

            resultingPromise
                .then((result) => {
                    setTimeout(() => {
                        setTempModelViewers([result]);
                        // remove model from queue
                        let newQueue = modelsUpdaterQueue;
                        newQueue.shift();
                        setModelsUpdaterQueue(newQueue);
                        setAmountOfModelsBeingColored((prev) => {
                            return 0;
                        });
                    }, 700);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [modelsUpdaterQueue, amountOfModelsBeingColored]);

    useEffect(() => {
        RetrieveProductSettings({ setMandatoryProductSettings, setProductSettings });
        setTextData(props.textData);

        if (currentPage !== "home") {
            props.initLoadPage();
        }
    }, []);

    useEffect(() => {
        if (tempModelViewers.length > 0) {
            setTimeout(() => {
                setTempModelViewers([]);
            }, 1000);
        }
    }, [tempModelViewers]);

    useEffect(() => {
        if (updatingMultipleModels) {
            updatePricesForAllModels();
        }
    }, [updatingMultipleModels]);

    // Update shipping costs after updating the modelsData
    useEffect(() => {
        if (afterModulSettingsUpdate.length > 0) {
            afterModulSettingsUpdate.forEach((model) => {
                // Loop modelsData
                if (modelsData !== undefined && modelsData.length > 0) {
                    modelsData[0].data.forEach((dataModel) => {
                        if (dataModel.id === model.id) {
                            updateShippingCosts(dataModel);
                        }
                    });
                }
            });
            setAfterModulSettingsUpdate([]);
        }
    }, [modelsData]);

    // useEffect(() => {
    //     if (!initLoadPrices.current && typeof modelsData !== "undefined" && typeof productSettings !== "undefined") {
    //         updatePrices(false);
    //         initLoadPrices.current = true;
    //     }
    // }, [productSettings, modelsData]);

    useEffect(() => {
        if (uploadCount > 0 && lastUploadedModels.current.length > 0) {
            getModelDataFromApi();
        } else {
            // get modelsData from localstorage
            const models = JSON.parse(window.localStorage.getItem("modelsData"));
            if (models !== null && models.length > 0) {
                models[0].data.forEach((model) => {
                    if (model.delivery === "...") {
                        updateShippingCosts(model);
                    }
                });
                setModelsData(models);
            } else {
                getModelDataFromApi();
            }
        }
    }, [uploadCount]);

    function getModelDataFromApi() {
        // Make a GET request for the uploaded models
        const modelDataAPI = window.location.origin + "/models";
        axios
            .get(modelDataAPI)
            .then((response) => {
                mapModelsToData(response.data)
                    .then((data) => {
                        if (data !== null) {
                            setModelsData([
                                {
                                    section: "quotation",
                                    data: data,
                                },
                            ]);
                        }
                        if (!loadedInitModelColors.current && data !== null) {
                            loadModelColors(data, null, true);
                            loadedInitModelColors.current = true;
                        }
                    })
                    .catch((error) => {
                        console.error("Error retrieving mapModelsToData:", error);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const loadSharedModels = async (uuid) => {
        let result = null;
        await axios
            .get(window.location.origin + "/shared-models/" + uuid)
            .then((response) => {
                // Get shared models from response, format it and set it to localstorage
                let models = response.data.sharedModels.map((sharedModel) => {
                    let result = {
                        id: sharedModel.model.id,
                        path: sharedModel.model.path,
                        file: sharedModel.model.file,
                        image: sharedModel.model.preview_image,
                        settings: {
                            application: sharedModel.application,
                            material: sharedModel.material,
                            color: sharedModel.color,
                            polish: sharedModel.polish,
                            coating: sharedModel.coating,
                            orientation: sharedModel.orientation,
                            sterile: sharedModel.sterile,
                            intendedUse: sharedModel.intendedUse,
                            clinicalUse: sharedModel.clinicalUse,
                            custom: sharedModel.custom,
                            case: sharedModel.case,
                            practitioner: sharedModel.practitioner,
                        },
                        cached_model:
                            typeof sharedModel.model.cached_model !== "undefined"
                                ? JSON.parse(sharedModel.model.cached_model)
                                : null,
                        delivery: "...",
                        amount: 1,
                        price: sharedModel.price,
                    };
                    return result;
                });

                // Set localstorage data
                localStorage.setItem("modelsData", JSON.stringify([{ data: models }]));
                result = models;
                models.forEach((element) => {
                    updateShippingCosts(element);
                });
            })
            .catch((error) => {
                console.log(error);
            });
        return result;
    };

    const loadModelColors = async (models, orientation = null, initial = false) => {
        // Set selected models to loading state
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((stateModel) => {
                models.forEach((model) => {
                    if (stateModel.id === model.id && (stateModel.checked || initial)) {
                        stateModel.loading = true;
                    }
                });
                return stateModel;
            });

            let result = [{ ...prevState[0], data: newData }];
            return result;
        });

        setModelsUpdaterQueue((prevState) => {
            return models;
        });
    };

    const mapModelsToData = async (inputModels) => {
        // Check if there is shared data in url
        const params = new URLSearchParams(window.location.search);
        if (params.get("share") !== null && lastUploadedModels.current.length === 0 && initialLoad.current === false) {
            initialLoad.current = true;
            return await loadSharedModels(params.get("share"));
        }

        // Check if data in local storage
        let localModelsData = JSON.parse(localStorage.getItem("modelsData")) || [];
        localModelsData = typeof localModelsData[0]?.data !== "undefined" ? localModelsData[0].data : [];
        let defaultSettings = {
            application: "Retail & Industrial",
            material: "Oceanz PA12",
            color: "No colouring",
            polish: "No",
            coating: "No",
            orientation: "unlocked",
            sterile: null,
            intendedUse: null,
            clinicalUse: null,
            custom: null,
            case: null,
            practitioner: null,
        };

        // Check if current logged in user default settings are set
        if (loggedInUser?.user !== null && typeof loggedInUser?.user !== "undefined") {
            if (
                loggedInUser?.user?.default_settings !== null &&
                typeof loggedInUser?.user?.default_settings !== "undefined"
            ) {
                defaultSettings = {
                    application: loggedInUser.user.default_settings.application,
                    material: loggedInUser.user.default_settings.material,
                    color: loggedInUser.user.default_settings.color,
                    polish: loggedInUser.user.default_settings.polish,
                    coating: loggedInUser.user.default_settings.coating,
                    orientation: loggedInUser.user.default_settings.orientation,
                    sterile:
                        loggedInUser.user.default_settings.sterile === "No"
                            ? null
                            : loggedInUser.user.default_settings.sterile,
                    intendedUse: loggedInUser.user.default_settings.intendedUse,
                    clinicalUse: loggedInUser.user.default_settings.clinicalUse,
                    custom: loggedInUser.user.default_settings.custom,
                    case:
                        loggedInUser.user.default_settings.custom !== undefined &&
                        (loggedInUser.user.default_settings.custom === "Yes" ||
                            loggedInUser.user.default_settings.custom)
                            ? "-"
                            : null,
                    practitioner:
                        loggedInUser.user.default_settings.custom !== undefined &&
                        (loggedInUser.user.default_settings.custom === "Yes" ||
                            loggedInUser.user.default_settings.custom)
                            ? "-"
                            : null,
                };
            }
        }

        // If a new model is uploaded, get the new model data from API
        if (lastUploadedModels.current.length > 0) {
            const modelDataAPI = window.location.origin + "/models";
            await axios
                .get(modelDataAPI)
                .then((response) => {
                    let newUploadedModels = []; // Array to store the new uploaded models
                    let apiModels = response.data.models;
                    apiModels.map((model) => {
                        let result = {
                            id: model.id,
                            path: model.path,
                            glb_path: model.glb_path ?? model.path,
                            file: model.file,
                            image: model.preview_image,
                            settings: typeof model.settings !== "undefined" ? model.settings : defaultSettings,
                            cached_model:
                                typeof model.cached_model !== "undefined" ? JSON.parse(model.cached_model) : null,
                            delivery: "...",
                            amount: 1,
                            price: "Op aanvraag",
                        };

                        lastUploadedModels.current.forEach((mdl) => {
                            if (model.id === mdl.id) {
                                // Set default settings
                                result.settings = defaultSettings;

                                setModelsData((prev) => {
                                    // Update model data
                                    let newState = prev[0].data.map((stateModel) => {
                                        if (stateModel.tempId === mdl.tempId) {
                                            stateModel = result;
                                        }
                                        return stateModel;
                                    });

                                    localStorage.setItem(
                                        "modelsData",
                                        JSON.stringify([{ ...prev[0], data: newState }])
                                    );
                                    return [{ ...prev[0], data: newState }];
                                });

                                newUploadedModels.push(result);
                                updatePrice(result);
                                updateShippingCosts(result);
                            }
                        });
                        return result;
                    });
                    lastUploadedModels.current = [];
                    if (params.get("share") !== null) {
                        updateShareUrl(JSON.parse(localStorage.getItem("modelsData")), params.get("share"));
                    }
                    return newUploadedModels;
                })
                .catch((error) => {
                    console.error(error);
                });
            // merge modelsData and result
            return null;
        }

        if (!!inputModels["models"]) {
            let models = inputModels["models"].map((model) => {
                let result = {
                    id: model.id,
                    path: model.path,
                    glb_path: model.glb_path ?? model.path,
                    file: model.file,
                    image: model.preview_image,
                    settings: model.settings !== "undefined" ? model.settings : defaultSettings,
                    cached_model: typeof model.cached_model !== "undefined" ? JSON.parse(model.cached_model) : null,
                    delivery: "...",
                    amount: 1,
                    price: "Op aanvraag",
                };

                if (localModelsData.length > 0) {
                    localModelsData.forEach((mdl) => {
                        if (mdl.id === model.id) {
                            if (typeof mdl.settings !== "undefined") {
                                result.settings = mdl.settings;
                                result.image = mdl.image;
                            } else {
                                result.settings = defaultSettings;
                            }
                        }
                    });
                }
                return result;
            });

            try {
                const localStorageData = JSON.parse(window.localStorage.getItem("modelsData"));
                if (
                    localStorageData !== null &&
                    typeof localStorageData !== "undefined" &&
                    localStorageData.length > 0
                ) {
                    models = localStorageData[0].data.map((model) => {
                        let result = model;
                        models.forEach((mdl) => {
                            if (mdl.id === model.id) {
                                result = mdl;
                                result.image = mdl.image;
                            }
                        });
                        return result;
                    });
                } else {
                    models = [];
                }
            } catch (err) {
                console.error(err, "resetting modelsData in local storage");
                // Reset models
                models = [];
                window.localStorage.setItem("modelsData", "[]");
            }

            models.forEach((model) => {
                updateShippingCosts(model);
            });

            return new Promise((resolve, reject) => {
                resolve(models);
            });
        }
    };

    const savedQuotationData = [
        {
            section: "savedQuotation",
            data: [
                {
                    creation_date: "20-02-2023",
                    reference: "Tandarts nijkerk 19",
                    status: "Offerte",
                    price: "59,41",
                },
            ],
        },
    ];

    const orderData = [
        {
            section: "orders",
            orders: [
                {
                    projectnr: 2023001,
                    orderDate: "03-02-2023",
                    reference: "Tandarts nijkerk 19",
                    status: "In productie",
                    price: "59,41",
                },
                {
                    projectnr: 2024001,
                    orderDate: "05-02-2023",
                    reference: "Knieprothese",
                    status: "Verzonden",
                    price: "59,41",
                },
                {
                    projectnr: 2025001,
                    orderDate: "12-06-2023",
                    reference: "Schaakstukken",
                    status: "Afgerond",
                    price: "59,41",
                },
            ],
        },
    ];

    const [orders, setOrders] = useState(orderData);
    const [savedQuotations, setSavedQuotations] = useState(savedQuotationData);

    // This useEffect is used to set the active menu item in the primary menu based on the current url. This is necessary if user is using
    // forward and back buttons in the browser.
    useEffect(() => {
        setActiveNavigationMenuItem(props.selectedMenuItem);
    }, [props.selectedMenuItem]);

    // This useEffect is used to set the modelsData state when the getModels state is updated.
    // useEffect(() => {
    //     if (!!getModels) {
    //         setModelsData(quotationData);
    //     }
    // }, [getModels, uploadCount]);

    // This useEffect is used to set a scrollable page for the quotation section.
    useEffect(() => {
        const appContainer = document.getElementById("app");
        if (activeNavigationMenuItem === "navigate-quotation" && isTabletOrMobile) {
            appContainer.style.overflowY = "scroll";
            appContainer.style.height = "calc(95vh - 100px)";
        } else {
            appContainer.style.overflowY = "auto";
            appContainer.style.height = "100%";
        }
    }, [activeNavigationMenuItem, window.innerWidth]);

    // Callback function to receive updated settings from the product settings component.
    function updateModelSettings(config) {
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.checked === true) {
                    setAfterModulSettingsUpdate((prev) => {
                        return [...prev, model];
                    });
                    return { ...model, settings: Object.assign({}, config) };
                } else {
                    return model;
                }
            });
            let result = [{ ...prevState[0], data: newData }];
            return result;
        });
    }

    // Update the left part of screen with either the product settings or the upload component.
    useEffect(() => {
        if (updateNextIteration.current === true) {
            updatePrices();
            updateNextIteration.current = false;
        }

        let result = false;
        if (modelsData !== undefined && modelsData.length > 0) {
            if (modelsData[0].data !== undefined && modelsData[0].data.length > 0) {
                modelsData[0].data.forEach((model) => {
                    if (model.checked === true) {
                        result = true;
                    }
                });
            }
        }
        setHasModelsSelected(result);
    }, [modelsData]);

    // Handle the screenshot from the modelviewer and update the model in the database.
    function handleScreenshot(imageData, modelId, color) {
        // Set the model data locally
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.id === modelId) {
                    model.image = imageData;
                    model.color = color;
                    model.loading = false;
                }
                return model;
            });
            let result = [{ ...prevState[0], data: newData }];
            return result;
        });
    }

    function updateSelectedModelsColor(color) {
        // Get all checked models
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                // Update localstate
                setModelsData((prevState) => {
                    let models = [];
                    const newData = prevState[0].data.map((model) => {
                        if (model.checked) {
                            model.color = color;
                            model.loading = true; // set loading to true in order to show spinning loader
                            models.push(model);
                        }
                        return { ...model };
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    loadModelColors(models);
                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                    return result;
                });
            }
        }
    }

    const updateModelDeliveryTime = (value) => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((model) => {
                        if (model.checked === true) {
                            return { ...model, delivery: value };
                        }
                        return { ...model };
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                    return result;
                });
            }
        }
    };

    const updateModelOrientation = (value) => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                loadModelColors(modelsData[0].data, value);
            }
        }
    };

    function saveProductSettings(chosenConfig) {
        // Check how many models are selected
        let selectedModels = 0;
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                modelsData[0].data.forEach((model) => {
                    if (model.checked === true) {
                        selectedModels++;
                    }
                });
            }
        }

        if (selectedModels > 1) {
            updatePricesForAllModels(modelsData[0].data);
        }

        // Unselect all models.
        document.getElementsByClassName("checkbox--select-all")[0].checked = false;
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.checked === true) {
                    return { ...model, checked: false, settings: chosenConfig };
                }
                return { ...model, checked: false };
            });
            const result = [{ ...prevState[0], data: newData }];
            window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage

            return result;
        });
    }

    function afterUpload(realId, tempId) {
        lastUploadedModels.current.push({ id: realId, tempId: tempId });
    }

    const updatePricesForAllModels = (models = null) => {
        updatingPrices.current = true;
        // Set model state to loading
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.checked === true) {
                    model.loadingPrice = true;
                }
                return model;
            });
            return [{ ...prevState[0], data: newData }];
        });

        let modelIds = [];
        if (models === null) {
            // Update the prices for all models in one single call
            if (typeof modelsData !== "undefined" && modelsData.length > 0) {
                if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                    modelsData[0].data.forEach((model) => {
                        if (model.checked === true) {
                            modelIds.push({
                                id: model.id,
                                settings: getSettingsId(model.settings, productSettings),
                                amount: model.amount,
                            });
                        }
                    });
                }
            }
        } else {
            models.forEach((model) => {
                if (model.checked === true) {
                    modelIds.push({
                        id: model.id,
                        settings: getSettingsId(model.settings, productSettings),
                        amount: model.amount,
                    });
                }
            });
        }

        if (modelIds.length > 0) {
            axios
                .post(window.location.origin + `/api/all-product-prices`, {
                    models: modelIds,
                })
                .then((response) => {
                    if (typeof response.data.data !== "undefined") {
                        const modelLookup = modelsData[0].data.reduce((acc, model) => {
                            acc[model.id] = model;
                            return acc;
                        }, {});

                        response.data.data.forEach((element) => {
                            const model = modelLookup[element.model_id];
                            if (model) {
                                model.price = parseFloat(element.unit_price) > 0 ? element.unit_price : "Op aanvraag";
                                model.discount_multiplier = element.discount_multiplier;
                                setModelsData((prevState) => {
                                    const newData = prevState[0].data.map((stateModel) => {
                                        if (stateModel.id === model.id) {
                                            return {
                                                ...stateModel,
                                                price: model.price,
                                                loadingPrice: false,
                                                discount_multiplier: model.discount_multiplier,
                                            };
                                        }
                                        return stateModel;
                                    });
                                    const result = [{ ...prevState[0], data: newData }];
                                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                                    return result;
                                });
                            }
                        });
                    }
                })
                .catch((error) => {
                    setModelsData((prevState) => {
                        const newData = prevState[0].data.map((model) => {
                            if (model.checked === true) {
                                model.loadingPrice = false;
                                model.price = "Op aanvraag";
                            }
                            return model;
                        });
                        return [{ ...prevState[0], data: newData }];
                    });
                })
                .finally(() => {
                    updatingPrices.current = false;
                });
        }
        setUpdatingMultipleModels(false);
    };

    // Update a single model price
    const updatePrice = (element) => {
        updatingPrices.current = true;
        // Set model state to loading
        setModelsData((prevState) => {
            const newData = prevState[0].data.map((model) => {
                if (model.id === element.id) {
                    model.loadingPrice = true;
                }
                return model;
            });
            return [{ ...prevState[0], data: newData }];
        });

        let params = "";
        let found = false;
        for (let setting in element.settings) {
            if (setting === "intendedUse") {
                found = true;
            }

            if (
                element.settings[setting] !== "" &&
                element.settings[setting] !== null &&
                typeof element.settings[setting] !== "undefined"
            ) {
                params +=
                    "&" + encodeURIComponent(`${setting}`) + "=" + encodeURIComponent(`${element.settings[setting]}`);
            } else {
                params += "&" + encodeURIComponent(`${setting}`) + "=null";
            }
        }
        if (!found) {
            params += "&intendedUse=null";
        }
        axios
            .get(window.location.origin + `/api/product-price?model_id=${element.id}${params}&amount=${element.amount}`)
            .then((response) => {
                // Cant get price, show default
                if (typeof response.data.message === "undefined" && typeof response.data.data.length === "undefined") {
                    element.price = "Op aanvraag";
                } else {
                    // Loop through the returned models and update the price
                    response.data.data.forEach((item) => {
                        if (item.model_id === element.id) {
                            element.price = item.unit_price;
                            element.discount_multiplier = item.discount_multiplier;
                        }
                    });
                }
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((model) => {
                        if (model.id === element.id) {
                            model.price = element.price;
                            model.loadingPrice = false;
                            model.discount_multiplier = element.discount_multiplier;
                        }
                        return model;
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                    return result;
                });
                updatingPrices.current = false;
            })
            .catch((error) => {
                element.loadingPrice = false;
                setModelsData((prevState) => {
                    const newData = prevState[0].data.map((model) => {
                        if (model.id === element.id) {
                            model.loadingPrice = false;
                            model.price = "Op aanvraag";
                        }
                        return model;
                    });
                    const result = [{ ...prevState[0], data: newData }];
                    window.localStorage.setItem("modelsData", JSON.stringify(result)); // set local storage
                    return result;
                });
                updatingPrices.current = false;
            })
            .finally(() => {
                updatingPrices.current = false;
                setDisableProductSettings(false);
            });
        return element;
    };

    const updatePricesOnNextIteration = () => {
        if (updatingMultipleModels !== false) {
            return;
        }

        // Update the prices the next time modelsData gets updated.
        updateNextIteration.current = true;
    };

    // Update all selected models
    const updatePrices = (selectedOnly = true) => {
        updatingPrices.current = true;
        let selectedModels = [];
        if (selectedOnly) {
            if (typeof modelsData !== "undefined" && modelsData.length > 0) {
                if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                    selectedModels = modelsData[0].data.filter((model) => model.checked === true);
                }
            }
        } else {
            selectedModels = modelsData[0].data;
        }

        if (modelsData[0].data.length === 0) {
            let models = JSON.parse(localStorage.getItem("modelsData"));
            if (typeof models === "undefined" || models === null || models.length === 0) {
                updatingPrices.current = false;
                return;
            } else {
                setModelsData((prev) => {
                    return [{ data: models, section: "quotation" }];
                });
            }
        }

        // Get new price
        selectedModels.map((element) => {
            return updatePrice(element);
        });
    };

    const updateShippingCosts = async (model, fromTableRow = false) => {
        if (typeof modelsData !== "undefined" && modelsData[0].data.length === 0) {
            // get from localstorage
            const models = JSON.parse(window.localStorage.getItem("modelsData"));
            if (models !== null && models.length > 0) {
                setModelsData(models);
            } else {
                return;
            }
        }

        // Show the model loading
        // if (fromTableRow) {
        //     setModelsData((prevState) => {
        //         const newData = prevState[0].data.map((stateModel) => {
        //             if (stateModel.id === model.id) {
        //                 stateModel.delivery = "...";
        //             }
        //             return stateModel;
        //         });

        //         let result = [{ ...prevState[0], data: newData }];
        //         return result;
        //     });
        // }
        if (getSettingsId(model.settings, productSettings) !== "") {
            axios
                .post(window.location.origin + `/api/calculate-shipping-time`, {
                    model_id: model.id,
                    country: "Netherlands",
                    settings_id: getSettingsId(model.settings, productSettings),
                    amount: model.amount,
                })
                .then((response) => {
                    setModelsData((prevState) => {
                        const newData = prevState[0].data.map((stateModel) => {
                            if (
                                stateModel.id === model.id &&
                                response.data !== null &&
                                response.data.delivery_time !== undefined
                            ) {
                                stateModel.delivery = parseInt(response.data.delivery_time);
                            }

                            // Check if error
                            if (response.data.error !== undefined && stateModel.id === model.id) {
                                stateModel.delivery = 0;
                            }

                            return stateModel;
                        });

                        let result = [{ ...prevState[0], data: newData }];
                        return result;
                    });
                });
        }
    };

    const getAmountOfSelectedModels = () => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                let selectedModels = modelsData[0].data.filter((model) => model.checked === true);
                return selectedModels.length;
            }
        }
        return 0;
    };

    const isSafari = (naviUserAgent = navigator.userAgent) => {
        if (/^((?!Chrome|CriOS|FxiOS|EdgiOS|OPiOS|mercury).)*Safari/i.test(naviUserAgent)) {
            return "isSClass";
        } else {
            return "";
        }
    };

    return (
        <>
            {!isTabletOrMobile && (
                <div className="root">
                    {activeNavigationMenuItem === "navigate-quotation" && !!modelsData && (
                        <>
                            {!props.authenticated && loginModal && (
                                <LoginPopup
                                    textData={!!textData && textData["popups/login"]}
                                    toggleLoginModal={setLoginModal}
                                    navigateToPageName={props.navigateToPageName}
                                />
                            )}
                            <div className="screen-left">
                                {!hasModelsSelected && (
                                    <Upload
                                        textData={!!textData && textData["sections/quotation"]}
                                        afterUpload={afterUpload}
                                        setModelsData={setModelsData}
                                        setUpdatingMultipleModels={setUpdatingMultipleModels}
                                    />
                                )}
                                {hasModelsSelected && (
                                    <ProductSettings
                                        modelsData={modelsData}
                                        updateModelSettings={updateModelSettings}
                                        textData={!!textData && textData}
                                        saveProductSettings={saveProductSettings}
                                        updateSelectedModelsColor={updateSelectedModelsColor}
                                        loggedInUser={loggedInUser}
                                        updateModelColor={updateModelColor}
                                        updateModelDeliveryTime={updateModelDeliveryTime}
                                        updateModelOrientation={updateModelOrientation}
                                        updatePricesOnNextIteration={updatePricesOnNextIteration}
                                        productSettings={productSettings}
                                        setSaveFooter={setSaveSettingsFooter}
                                        saveFooter={saveSettingsFooter}
                                        setUpdatingMultipleModels={setUpdatingMultipleModels}
                                        disableProductSettings={disableProductSettings}
                                    />
                                )}
                                <QuotationBlock textData={!!textData && textData["content_blocks/quotation"]} />
                            </div>
                            <div className="screen-right">
                                <div className="header">
                                    <div>
                                        <h1>{!!textData && textData["sections/quotation"].title}</h1>
                                        {getAmountOfSelectedModels() > 0 && (
                                            <>
                                                <span className="amount-of-models-icon"></span>
                                                <span className="amount-of-models">
                                                    {getAmountOfSelectedModels() + " "}
                                                    {!!textData && textData["header"].of}
                                                    {" " + modelsData[0].data.length + " "}
                                                    {!!textData && textData["header"].selected}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <NavigationMenu
                                        textData={!!textData && textData}
                                        authenticated={props.authenticated}
                                        toggleLoginModal={setLoginModal}
                                        navigateToPageName={props.navigateToPageName}
                                    />
                                </div>
                                <Table
                                    data={!!modelsData && modelsData}
                                    productsData={!!productsData && productsData}
                                    setProductsData={setProductsData}
                                    onModelChange={setModelsData}
                                    textData={!!textData && textData["sections/quotation"]}
                                    textDataLabel={!!textData && textData["sections/label"]}
                                    checkoutState={!!state && state}
                                    popupData={!!textData && textData["popups/save_quotation"]}
                                    loginData={!!textData && textData["popups/login"]}
                                    mandatoryProductOptions={mandatoryProductSettings}
                                    navigateToPageName={props.navigateToPageName}
                                    getPageUrlByName={props.getPageUrlByName}
                                    allTextData={!!textData && textData}
                                    updateShippingCosts={updateShippingCosts}
                                    updatePrice={updatePrice}
                                    setSaveFooterSettings={setSaveSettingsFooter}
                                    updatingPrices={updatingPrices.current}
                                    changedQuantity={setDisableProductSettings}
                                />
                            </div>
                        </>
                    )}
                    {activeNavigationMenuItem === "navigate-account" && (
                        <>
                            <div className="screen-left">
                                <Sidebar getPageUrlByName={props.getPageUrlByName} textData={!!textData && textData} />
                            </div>
                            <div className={`screen-right ${isSafari()}`}>
                                <div className="header">
                                    <div className="header-title">
                                        {(props.currentPage === "account_orders" ||
                                            props.currentPage === "accountsettings") && (
                                            <>
                                                <FontAwesomeIcon icon={faUser} size="xl" />
                                                <h1>{props.title}</h1>
                                            </>
                                        )}
                                    </div>
                                    <NavigationMenu
                                        textData={!!textData && textData}
                                        authenticated={props.authenticated}
                                        navigateToPageName={props.navigateToPageName}
                                    />
                                </div>
                                {props.currentPage === "account_orders" && (
                                    <OrderOverview
                                        navigateToPageName={props.navigateToPageName}
                                        orderData={orders}
                                        textData={!!textData && textData}
                                    />
                                )}
                                {props.currentPage === "account_quotations" && (
                                    <QuotationOverview
                                        navigateToPageName={props.navigateToPageName}
                                        quotationData={savedQuotations}
                                        textData={!!textData && textData}
                                        loggedInUser={loggedInUser}
                                    />
                                )}
                                {props.currentPage === "product_library" && (
                                    <ProductLibraryOverview
                                        setProductsData={setProductsData}
                                        textData={!!textData && textData}
                                        navigateToPageName={props.navigateToPageName}
                                    />
                                )}
                                {props.currentPage === "account_settings" && (
                                    <Settings
                                        navigateToPageName={props.navigateToPageName}
                                        textData={!!textData && textData}
                                    />
                                )}
                                {props.currentPage === "account_details" && (
                                    <Details
                                        navigateToPageName={props.navigateToPageName}
                                        textData={!!textData && textData}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
            {isTabletOrMobile && (
                <div
                    className="root"
                    style={activeNavigationMenuItem === "navigate-account" ? { overflowY: "auto", height: "auto" } : {}}
                >
                    {activeNavigationMenuItem === "navigate-quotation" && !!modelsData && (
                        <>
                            <div className={responsiveClass + "header"}>
                                <NavigationMenu
                                    textData={!!textData && textData}
                                    authenticated={props.authenticated}
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                    toggleLoginModal={setLoginModal}
                                />
                            </div>
                            {!hasModelsSelected && (
                                <Upload
                                    responsive={responsiveClass}
                                    textData={!!textData && textData["sections/quotation"]}
                                    afterUpload={afterUpload}
                                    setModelsData={setModelsData}
                                />
                            )}
                            {hasModelsSelected && (
                                <ProductSettings
                                    modelsData={modelsData}
                                    updateModelSettings={updateModelSettings}
                                    textData={!!textData && textData}
                                    saveProductSettings={saveProductSettings}
                                    loggedInUser={loggedInUser}
                                    updateModelColor={updateModelColor}
                                    productSettings={productSettings}
                                    updateSelectedModelsColor={updateSelectedModelsColor}
                                    updateModelDeliveryTime={updateModelDeliveryTime}
                                    updateModelOrientation={updateModelOrientation}
                                    updatePricesOnNextIteration={updatePricesOnNextIteration}
                                    saveFooter={saveSettingsFooter}
                                    setSaveFooter={setSaveSettingsFooter}
                                    disableProductSettings={disableProductSettings}
                                />
                            )}
                            <Table
                                responsive={responsiveClass}
                                data={!!modelsData && modelsData}
                                onModelChange={setModelsData}
                                textData={!!textData && textData["sections/quotation"]}
                                textDataLabel={!!textData && textData["sections/label"]}
                                navigateToPageName={props.navigateToPageName}
                                getPageUrlByName={props.getPageUrlByName}
                                allTextData={!!textData && textData}
                                updateShippingCosts={updateShippingCosts}
                                updatePrice={updatePrice}
                                checkoutState={!!state && state}
                                popupData={!!textData && textData["popups/save_quotation"]}
                                loginData={!!textData && textData["popups/login"]}
                                mandatoryProductOptions={mandatoryProductSettings}
                                setSaveFooterSettings={setSaveSettingsFooter}
                                changedQuantity={setDisableProductSettings}
                            />
                        </>
                    )}
                    {activeNavigationMenuItem === "navigate-account" && !!orders && (
                        <>
                            <div className={responsiveClass + "header"}>
                                <NavigationMenu
                                    textData={!!textData && textData}
                                    authenticated={props.authenticated}
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                />
                            </div>
                            <Sidebar
                                getPageUrlByName={props.getPageUrlByName}
                                textData={!!textData && textData}
                                responsive={responsiveClass}
                            />
                            {props.currentPage === "account_orders" && (
                                <OrderOverview
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                    orderData={orders}
                                    textData={!!textData && textData}
                                />
                            )}
                            {props.currentPage === "account_quotations" && (
                                <QuotationOverview
                                    responsive={responsiveClass}
                                    quotationData={savedQuotations}
                                    textData={!!textData && textData}
                                    loggedInUser={loggedInUser}
                                    navigateToPageName={props.navigateToPageName}
                                />
                            )}
                            {props.currentPage === "account_details" && (
                                <Details
                                    responsive={responsiveClass}
                                    navigateToPageName={props.navigateToPageName}
                                    textData={!!textData && textData}
                                />
                            )}
                            {props.currentPage === "product_library" && (
                                <ProductLibraryOverview
                                    navigateToPageName={props.navigateToPageName}
                                    responsive={responsiveClass}
                                    textData={!!textData && textData}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
            {tempModelViewers.map((modelViewer, index) => {
                return <Fragment key={index}>{modelViewer}</Fragment>;
            })}
        </>
    );
}
