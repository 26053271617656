import * as THREE from "three";
import { setRotationToLongestPoint } from "./CalculateRotation";

const loadLighting = (scene) => {
    const light = new THREE.PointLight(0xffffff, 0.6, 0, 0.5);
    light.position.set(100, 100, 100);
    const light2 = new THREE.PointLight(0xffe3d4, 0.7, 0, 0.5);
    light2.position.set(-100, -100, -100);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.7);

    scene.add(directionalLight);
    scene.add(light2);
    scene.add(light);
};

const calculateModelPosition = (object, camera, orientationLocked, screenshot = false) => {
    // Set rotation
    let box = new THREE.Box3().setFromObject(object);
    const x = Math.abs(box.max.x - box.min.x);
    const y = Math.abs(box.max.y - box.min.y);
    const z = Math.abs(box.max.z - box.min.z);

    if (!orientationLocked) {
        setRotationToLongestPoint(object);
    } else {
        object.rotation.x = 0;
        object.rotation.y = -Math.PI / 2;
        object.rotation.z = 0;

        let rotation = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1.0, 0.0, 0.0), -Math.PI / 2.0);
        object.quaternion.multiply(rotation);
    }

    // Center the model
    box = new THREE.Box3().setFromObject(object);
    const center = new THREE.Vector3();
    box.getCenter(center);
    object.position.sub(center);

    // Get the model's dimensions
    const size = new THREE.Vector3();
    box.getSize(size);
    const maxDim = Math.max(size.x, size.y, size.z);

    // Set the camera position
    const fov = camera.fov * (Math.PI / 180);
    let cameraZ = Math.abs((maxDim / 4) * Math.tan(fov * 2));
    cameraZ *= 4; // zoom out a bit
    camera.position.z = cameraZ;
    camera.position.y = cameraZ;
    camera.position.x = -cameraZ;
    const minZ = box.min.z + 500;
    const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;
    camera.far = cameraToFarEdge * 5;
    camera.updateProjectionMatrix();
};

export { calculateModelPosition, loadLighting };
