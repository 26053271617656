import React from "react";
import { Formik } from "formik";
import Button from "../Button";
import { handleInputType } from "../Functions/functions";

export default function Settings(props) {
    const { textData, responsive } = props;

    const initialValues = {
        email: "",
    };

    const handleSubmit = (values) => {
        const formData = {
            email: values.email,
        };
    };

    return (
        <div className={!!responsive ? responsive + "main-form-container my-account-container" : "main-form-container"}>
            {!!textData && (
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {({ handleSubmit, handleChange }) => (
                        <>
                            {!!textData["account/my_account"]["forms"] &&
                                Object.values(textData["account/my_account"]["forms"]).map((form, index) => (
                                    <div key={index} className="form-container">
                                        <h2>{form.title}</h2>
                                        <form id="my-account-form" onSubmit={handleSubmit}>
                                            {Object.keys(form.data).length > 0 && (
                                                <div
                                                    className={
                                                        !!responsive
                                                            ? responsive + "form-group form-group"
                                                            : "form-group"
                                                    }
                                                >
                                                    {Object.entries(form.data).map(
                                                        ([fieldName, fieldLabel], fIndex) => (
                                                            <div key={fIndex}>
                                                                <label htmlFor={`${fieldName}`}>{fieldLabel}</label>
                                                                <input
                                                                    id={`${fieldName}`}
                                                                    name={`${fieldName}`}
                                                                    type={handleInputType(fieldName)}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                ))}
                            <Button
                                navigateToPageName={props.navigateToPageName}
                                data={{
                                    class: "save-button",
                                    name: "save-account",
                                    form: "my-account-form",
                                    type: "submit",
                                    text: textData["account/my_account"]["save_button"],
                                }}
                            />
                        </>
                    )}
                </Formik>
            )}
        </div>
    );
}
