import { useState, useEffect } from "react";
import InfoButton from "../InfoButton";

export default function Coating(props) {
    const [selected, setSelected] = useState(null);
    const [buttons, setButtons] = useState([]);
    const [clicked, setClicked] = useState(false);
    const [polishes, setPolishes] = useState([]);

    useEffect(() => {
        // Make none the first option
        if (props.polishes.find((item) => item === "None") === undefined) {
            setPolishes(props.polishes);
        } else {
            setPolishes([
                props.polishes.find((item) => item === "None"),
                ...props.polishes.filter((item) => item !== "None"),
            ]);
        }
    }, [props.polishes]);

    useEffect(() => {
        renderButtons();
    }, [polishes]);

    useEffect(() => {
        if (clicked === true) {
            setTimeout(() => {
                // Submit the form
                submit();
            }, 100);
        }
    }, [selected]);

    function clickSelect(e, i) {
        select(e, i);
        setClicked(true);
    }

    const order = ["No", "Barrel", "Vapor"];

    function renderButtons() {
        const buttonsArray = Object.entries(polishes)
            .sort((a, b) => {
                const aValue = a[1].split(" ")[0];
                const bValue = b[1].split(" ")[0];
                return order.indexOf(aValue) - order.indexOf(bValue);
            })
            .map(([key, value], i) => (
                <div className="material-container" key={i}>
                    {value.image_url !== null && value.image_url !== undefined ? (
                        <img src={"./storage/" + value.image_url} alt="tst" />
                    ) : (
                        <span className="color-preview" style={{ backgroundColor: "#808080" }}></span>
                    )}
                    <button
                        className={`btn btn-option 
                    ${props.config.polish === null && i === 0 ? "selected" : ""}
                    ${props.config.polish == value ? "selected" : ""}`}
                        key={i}
                        onClick={(e) => clickSelect(e, i)}
                    >
                        {value}
                    </button>
                </div>
            ));
        setButtons(buttonsArray);
    }

    function select(e, i) {
        // Remove selected class from all buttons
        const container = document.getElementsByClassName("materials-settings-details");
        const buttons = container[0].getElementsByClassName("btn-option");
        for (var i = 0; i < buttons.length; i++) buttons[i].classList.remove("selected");
        // Add selected class to clicked button
        setSelected(e.target);
        e.target.classList.add("selected");
    }

    function submit() {
        if (selected !== null) {
            props.updateSetting("polish", selected.innerText);
        } else {
            // Check if there is a button with state selected
            let btn = document.getElementsByClassName("btn-option selected")[0];
            if (btn !== null && typeof btn !== "undefined") {
                props.updateSetting("polish", btn.innerText);
            }
        }
        props.goNext("overview");
        props.clearSettingsThatAreNotAllowed(false, "polish");
    }

    function goBack() {
        props.goNext("color");
    }

    return (
        <div className="materials-settings-details">
            <h1>{props.textData.title}</h1>
            <br />
            <p className="subtitle">{props.textData.subtitle}</p>
            <br />
            <h2 className="h2-info-parent">{props.textData.header}</h2>
            <InfoButton
                info={props.productSettingExplanations.filter((info) => {
                    return info.type === "polish";
                })}
                textData={props.infoTextData}
            />
            <div className="material-btns">{buttons}</div>
            {polishes !== null && polishes.length > 0 ? "" : <p>{props.textData.not_available}</p>}
        </div>
    );
}
