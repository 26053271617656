import * as THREE from "three";
import React from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useState, useEffect, useRef } from "react";
import { createScreenshot } from "./PreviewMaker";
import { load3mfModelFromBuffer, load3mfModelFromUrl } from "./Loaders/3mf/3mfLoader";
import { loadStlModelFromBuffer, loadStlModelFromUrl } from "./Loaders/Stl/StlLoader";
import { loadStepModelFromBuffer, loadStepModelFromUrl, loadStepModelFromCache } from "./Loaders/Step/StepLoader";
import { loadGlbModelFromUrl } from "./Loaders/Glb/GlbLoader";
import { loadLabels } from "./TextLoader";
import axios from "axios";

const ModelViewer = function ModelViewer(props) {
    // Set default color
    const { color } = props;
    const defaultColor = "rgb(150, 150, 150)";
    const formattedColor = color ? `rgb(${color.r}, ${color.g}, ${color.b})` : defaultColor;

    // Set threejs variables
    const material = new THREE.MeshStandardMaterial({
        color: new THREE.Color(formattedColor),
        roughness: 0.1,
        envMapIntensity: 5.0,
    });
    const canvas = useRef(null);
    const modelDimensions = useRef(null);
    const [camera, setCamera] = useState(null);
    const cameraTarget = useRef(null);
    const [scene, setScene] = useState(null);
    const [renderer, setRenderer] = useState(null);
    const [controls, setControls] = useState(null);
    const [loadedStepData, setLoadedStepData] = useState(false);
    const [orientationLocked, setOrientationLocked] = useState(true);

    //const [modelSize, setModelDimensions] = useState(null);
    const initialWidth = !!props.width ? props.width : 95;
    const initialHeight = !!props.width ? props.width : 95;

    useEffect(() => {
        if (props.container === null || typeof props.container === "undefined") {
            return;
        }
        init();
    }, []);

    // Create screenshot when model is loaded
    useEffect(() => {
        if (
            loadedStepData !== false &&
            props.modelData !== null &&
            typeof props.modelData !== "undefined" &&
            typeof props.handleScreenshot !== "function"
        ) {
            animate();
            setTimeout(() => {
                createScreenshot(props, renderer, scene, loadedStepData, modelDimensions.current);
                stopImportLoading();
            }, 200);
        }
    }, [loadedStepData]);

    function setModelDimensions(size) {
        modelDimensions.current = size;

        if (typeof props.returnProductDimensions !== "undefined" && props.returnProductDimensions !== null) {
            props.returnProductDimensions(size, props.productId);
            return;
        }

        if (typeof props.showXYZ !== "undefined") {
            // Add text labels to axes
            loadLabels(scene, size);
        }
        try {
            let stats = document.getElementById(props.container).querySelector(".modelviewer-stats");
            if (stats !== null && typeof stats !== "undefined") {
                stats.innerHTML += `<p>Surface: ${size.surface} cm<sup>2</sup></p>`;
                stats.innerHTML += `<p>Volume: ${size.volume} cm<sup>3</sup></p>`;
                stats.innerHTML += `<p>Length (x): ${size.width} mm</p>`;
                stats.innerHTML += `<p>Width (y): ${size.length} mm</p>`;
                stats.innerHTML += `<p>Height (z): ${size.height} mm</p>`;
                stats.style.display = "block";
            }
        } catch (e) {}
    }

    const init = () => {
        const screenWidth = initialWidth;
        const screenHeight = initialHeight;
        let scene = new THREE.Scene();
        if (typeof props.handleScreenshot !== "undefined" || typeof props.handleScreenshotOnUpload !== "undefined") {
            scene.background = new THREE.Color("#ffffff");
        } else {
            scene.background = new THREE.Color("#f1f0ee");
        }
        setScene(scene);
        let camera = new THREE.PerspectiveCamera(70, screenWidth / screenHeight, 1, 1000);
        camera.position.set(40, 40, 40);
        setCamera(camera);
        cameraTarget.current = new THREE.Vector3(0, 0, 0);
        let renderer = new THREE.WebGLRenderer();
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.setSize(initialWidth, initialHeight);
        setRenderer(renderer);
        canvas.current = document.getElementById(props.container).appendChild(renderer.domElement);
        canvas.current.setAttribute("class", "canvasElement");
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.addEventListener("change", render);
        controls.enablePan = false;
        controls.target.set(0, 0, 0);
        controls.update();
        setControls(controls);

        if (typeof props.showXYZ !== "undefined") {
            let axes = new THREE.AxesHelper(99999);

            axes.setColors(
                new THREE.Color("rgb(0,255,0)"),
                new THREE.Color("rgb(0,0,255)"),
                new THREE.Color("rgb(255,0,0)")
            );
            scene.add(axes);
        }

        // Check if we need to screenshot and hide canvas if this is the case
        if (typeof props.handleScreenshot !== "undefined" || typeof props.handleScreenshotOnUpload !== "undefined") {
            document.getElementById(props.container).style.opacity = 0;
            document.getElementById(props.container).style.position = "absolute";
        } else {
            // Not screenshotting, show loading animation
            document.getElementById(props.container).setAttribute("class", "modelviewer-loading");
            document.getElementById(props.container).getElementsByClassName("canvasElement")[0].style.display = "none";
            let spinner = document.createElement("span");
            spinner.setAttribute("class", "spinner");
            document.getElementById(props.container).appendChild(spinner);
        }
    };

    const animate = () => {
        requestAnimationFrame(animate);
        render();
    };

    const render = () => {
        if (camera && renderer) {
            camera.lookAt(cameraTarget.current);
            renderer.render(scene, camera);
        }
    };

    // Removes loading spinner
    const stopLoading = (makeScreenshot = false) => {
        stopImportLoading();
        try {
            document.getElementById(props.container).removeAttribute("class", "modelviewer-loading");
            document.getElementById(props.container).getElementsByClassName("spinner")[0].remove();
            document.getElementById(props.container).getElementsByClassName("canvasElement")[0].style.display = "block";
        } catch (e) {}

        if (makeScreenshot) {
            animate();
            // TODO: Find better way to make the screenshot
            // If model is loading slow it might not work?

            // Get dimensions from three.js
            setTimeout(() => {
                createScreenshot(props, renderer, scene, null, modelDimensions.current);
            }, 300);
        }
    };

    const startImportLoading = () => {
        if (props.showLoadingPopup) {
            // Create popup
            let popup = document.createElement("div");
            popup.setAttribute("id", "popup-import-loading");
            document.getElementById(props.container).getElementsByClassName("canvasElement")[0].style.display = "none";
            // Add popup to container
            document.body.appendChild(popup);
        }
    };

    const stopImportLoading = () => {
        // Remove popup
        try {
            document.getElementById("popup-import-loading").remove();
        } catch (e) {}
    };

    // Load the uploaded model
    const loadModel = () => {
        if (camera === null || scene === null) return;

        // Check if model url has parameters
        let url = "";
        if (props.model !== null && props.model !== undefined) {
            url = props.model;
            if (props.model.includes("?expires")) {
                // remove parameters
                url = props.model.split("?expires")[0];
            }
        }

        // Remove parameters from extension if there are any like so: .stp?expires=1234 -> .stp
        let extension = props.extension ? props.extension : "";
        if (props.extension === null || typeof props.extension === "undefined") {
            if (url !== "") {
                extension = /[^.]+$/.exec(url)[0].trim();
            } else {
                if (props.modelData !== undefined && props.modelData !== null) {
                } else {
                    extension = /[^.]+$/.exec(props.model)[0].trim();
                }
            }
        }
        // Check which model type we are loading
        startImportLoading();
        setTimeout(() => {
            if (typeof props.returnProductDimensions !== "undefined" && props.returnProductDimensions !== null) {
                loadGlbModelFromUrl(
                    url,
                    scene,
                    camera,
                    formattedColor,
                    stopLoading,
                    setModelDimensions,
                    orientationLocked,
                    true
                );
            } else {
                switch (extension.toLowerCase()) {
                    case "stp":
                    case "step":
                        let makeScreenshot =
                            typeof props.handleScreenshot !== "undefined" ||
                            typeof props.handleScreenshotOnUpload !== "undefined";
                        // Load step file
                        if (typeof props.cachedData === "undefined" || props.cachedData !== true) {
                            if (!loadedStepData) {
                                // Show loading popup
                                // Import model into the scene
                                if (props.modelData !== undefined && props.modelData !== null && scene !== null) {
                                    loadStepModelFromBuffer(
                                        props.modelData,
                                        scene,
                                        camera,
                                        formattedColor,
                                        stopLoading,
                                        setModelDimensions,
                                        orientationLocked,
                                        setLoadedStepData,
                                        makeScreenshot
                                    );
                                } else {
                                    //TODO: Check if cached version exists
                                    loadStepModelFromUrl(
                                        url,
                                        scene,
                                        camera,
                                        formattedColor,
                                        stopLoading,
                                        setModelDimensions,
                                        orientationLocked,
                                        setLoadedStepData,
                                        makeScreenshot
                                    );
                                }
                            }
                        } else if (
                            (typeof props.cachedData !== "undefined" && props.cachedData === true) ||
                            !loadedStepData
                        ) {
                            // Get cached model data
                            const cachedModelAPI = window.location.origin + "/api/cached-model?id=" + props.modelId;
                            axios
                                .get(cachedModelAPI)
                                .then((response) => {
                                    if (response.data.cachedData !== null && response.data.cachedData !== false) {
                                        if (typeof props.handleScreenshot !== "undefined") {
                                            // Color update
                                            let cachedData = JSON.parse(response.data.cachedData);
                                            loadStepModelFromCache(
                                                cachedData,
                                                scene,
                                                camera,
                                                formattedColor,
                                                stopLoading,
                                                setModelDimensions,
                                                orientationLocked,
                                                true
                                            );
                                        } else {
                                            // Model upload
                                            let cachedData = JSON.parse(response.data.cachedData);
                                            loadStepModelFromCache(
                                                cachedData,
                                                scene,
                                                camera,
                                                formattedColor,
                                                stopLoading,
                                                setModelDimensions,
                                                orientationLocked
                                            );
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        }
                        break;
                    case "stl":
                        // Load STL model from buffer instead of URL
                        if (props.modelData !== undefined && props.modelData !== null && scene !== null) {
                            let makeScreenshot =
                                typeof props.handleScreenshot !== "undefined" ||
                                typeof props.handleScreenshotOnUpload !== "undefined";
                            if (!makeScreenshot) {
                                makeScreenshot =
                                    typeof props.returnProductDimensions !== "undefined" &&
                                    props.returnProductDimensions !== null;
                            }
                            loadStlModelFromBuffer(
                                props.modelData,
                                scene,
                                camera,
                                material,
                                stopLoading,
                                setModelDimensions,
                                orientationLocked,
                                makeScreenshot
                            );
                            break;
                        }
                        // Load STL model from url
                        let makePrev =
                            typeof props.handleScreenshot !== "undefined" ||
                            typeof props.handleScreenshotOnUpload !== "undefined";
                        if (!makePrev) {
                            makePrev =
                                typeof props.returnProductDimensions !== "undefined" &&
                                props.returnProductDimensions !== null;
                        }
                        loadStlModelFromUrl(
                            url,
                            scene,
                            camera,
                            material,
                            stopLoading,
                            setModelDimensions,
                            orientationLocked,
                            makePrev
                        );
                        break;
                    case "3mf":
                        // Load 3MF model from buffer instead of URL
                        if (props.modelData !== undefined && props.modelData !== null && scene !== null) {
                            //load3MFModelFromBuffer(material, props.modelData);
                            let makeScreenshot =
                                typeof props.handleScreenshot !== "undefined" ||
                                typeof props.handleScreenshotOnUpload !== "undefined";
                            if (!makeScreenshot) {
                                makeScreenshot =
                                    typeof props.returnProductDimensions !== "undefined" &&
                                    props.returnProductDimensions !== null;
                            }
                            load3mfModelFromBuffer(
                                props.modelData,
                                scene,
                                camera,
                                formattedColor,
                                stopLoading,
                                setModelDimensions,
                                orientationLocked,
                                makeScreenshot
                            );
                            break;
                        }
                        // Load 3MF model from url
                        load3mfModelFromUrl(
                            url,
                            scene,
                            camera,
                            formattedColor,
                            stopLoading,
                            setModelDimensions,
                            orientationLocked
                        );
                        break;
                    case "glb":
                        let makePreview =
                            typeof props.handleScreenshot !== "undefined" ||
                            typeof props.handleScreenshotOnUpload !== "undefined";
                        if (!makePreview) {
                            makePreview =
                                typeof props.returnProductDimensions !== "undefined" &&
                                props.returnProductDimensions !== null;
                        }
                        // Load 3MF model from url
                        loadGlbModelFromUrl(
                            url,
                            scene,
                            camera,
                            formattedColor,
                            stopLoading,
                            setModelDimensions,
                            orientationLocked,
                            makePreview
                        );
                        break;
                    default:
                        console.log("Unsupported extension: " + props.extension);
                        break;
                }
            }
        }, 20);
    };
    loadModel();
    animate();
};

export default ModelViewer;
