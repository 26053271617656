import Popup from "reactjs-popup";
import React, { useEffect, useState } from "react";
import { Formik, useFormik, Field } from "formik";
import Button from "../Button";
import { showFormLabel } from "../Functions/functions";
import axios from "axios";

const fetchCountries = async () => {
    try {
        const response = await axios.get(window.location.origin + "/api/countries");
        const error = response.data.error;
        if (!error) {
            return new Promise((resolve) => {
                resolve(response.data.countries);
            });
        }
    } catch (error) {
        console.error(error);
    }
};

const ReferenceForm = ({ formik, errorMessage, textData, formButton, navigateToPageName }) => {
    return (
        <>
            {!!errorMessage && (
                <div className="error-messages">
                    {Object.values(errorMessage).map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            )}
            <div className="form-container">
                <form className="login-user-form" onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <div className={!!errorMessage["email"] ? "error-input" : ""}>
                            <label className="form-label hide-label" htmlFor="email">
                                {textData["form"]["email"]}
                            </label>
                            <input
                                id="reference"
                                name="reference"
                                type="text"
                                placeholder={textData["form"]["reference"]}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    showFormLabel(e);
                                }}
                            />
                        </div>
                        <div className="content-footer">
                            <Button navigateToPageName={navigateToPageName} data={formButton} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

const LoginUserForm = ({ formik, errorMessage, textData, formButton, registerButton, navigateToPageName }) => {
    return (
        <>
            {!!errorMessage && (
                <div className="error-messages">
                    {Object.values(errorMessage).map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            )}
            <div className="form-container">
                <form className="login-user-form" onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <div className={!!errorMessage["email"] ? "error-input" : ""}>
                            <label className="form-label hide-label" htmlFor="email">
                                {textData["form"]["email"]}
                            </label>
                            <input
                                id="email"
                                name="email"
                                type="text"
                                placeholder={textData["form"]["email"]}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    showFormLabel(e);
                                }}
                            />
                        </div>
                        <div className="content-footer">
                            <button
                                className="login-register-btn"
                                type="button"
                                onClick={() => {
                                    localStorage.setItem("registration-email", formik.values["email"]);
                                    navigateToPageName(registerButton.path);
                                }}
                            >
                                {registerButton.name}
                            </button>
                            <Button navigateToPageName={navigateToPageName} data={formButton} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

const RegisterUserForm = ({ formik, errorMessage, textData, formButton, cancelButton, navigateToPageName }) => {
    const [countries, setCountries] = useState([]);

    if (countries.length === 0) {
        fetchCountries().then((result) => {
            setCountries(result);
        });
    }

    const togglePrivateUse = (e) => {
        let privateUse = document.getElementById("private");
        if (privateUse.checked) {
            document.getElementById("company").parentNode.style.display = "none";
            document.getElementById("kvk").parentNode.style.display = "none";
            document.getElementById("btw").parentNode.style.display = "none";
        } else {
            document.getElementById("company").parentNode.style.display = "grid";
            document.getElementById("kvk").parentNode.style.display = "grid";
            document.getElementById("btw").parentNode.style.display = "grid";
        }
    };

    return (
        <>
            <div className="form-container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group ">
                        {Object.entries(textData["form"]).map(([key, value], index) => (
                            <div
                                className={
                                    !!errorMessage[key] ||
                                    (!!errorMessage["firstname"] && key === "full_name") ||
                                    (!!errorMessage["lastname"] && key === "full_name")
                                        ? "error-input"
                                        : ""
                                }
                                key={index}
                            >
                                {key === "private" ? (
                                    <>
                                        <input type="checkbox" id="private" name="private" onClick={togglePrivateUse} />
                                        <p>{textData["form"]["private"]}</p>
                                    </>
                                ) : (
                                    <>
                                        {key === "country" ? (
                                            <>
                                                <React.Fragment key={index}>
                                                    <select
                                                        id="country"
                                                        name="country"
                                                        className="input-country"
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                    >
                                                        {countries.map((country, index) => (
                                                            <option key={index} value={country}>
                                                                {country}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </React.Fragment>
                                            </>
                                        ) : (
                                            <React.Fragment key={index}>
                                                <label className="form-label hide-label" htmlFor={key}>
                                                    {value}
                                                </label>
                                                <input
                                                    id={key}
                                                    name={key}
                                                    type="text"
                                                    placeholder={value}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        showFormLabel(e);
                                                    }}
                                                    value={formik.values[key]}
                                                />
                                            </React.Fragment>
                                        )}
                                    </>
                                )}
                            </div>
                        ))}
                        <div className="content-footer">
                            <button
                                className="login-register-btn"
                                type="button"
                                onClick={() => {
                                    navigateToPageName(cancelButton.path);
                                }}
                            >
                                {cancelButton.name}
                            </button>
                            <Button navigateToPageName={navigateToPageName} data={formButton} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

const QuotationForm = ({ formik, errorMessage, textData, formButton }) => {
    const [countries, setCountries] = useState([]);
    if (!textData || formik.values === null) {
        return;
    }

    if (countries.length === 0) {
        fetchCountries().then((result) => {
            setCountries(result);
        });
    }

    return (
        <>
            {!!errorMessage && (
                <div className="error-messages">
                    {Object.values(errorMessage).map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            )}
            {countries.length > 0 && (
                <div className="form-container">
                    <form id="quotation-form" className="quotation-form" onSubmit={formik.handleSubmit}>
                        <div className={!!errorMessage && errorMessage["email"] ? "error-input" : ""}>
                            <div className="checkout">
                                <h2>Details</h2>
                                <div className="details form-group-edit">
                                    {Object.keys(formik.values).map((element, index) => {
                                        if (element === "country") {
                                            return (
                                                <React.Fragment key={index}>
                                                    <select
                                                        id="country"
                                                        name="country"
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                    >
                                                        {countries.map((country, index) => (
                                                            <option key={index} value={country}>
                                                                {country}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </React.Fragment>
                                            );
                                        } else if (element === "email") {
                                            return (
                                                <React.Fragment key={index}>
                                                    <input
                                                        type="text"
                                                        name={element}
                                                        placeholder={textData["checkout/form"]["form_details"][element]}
                                                        readOnly={true}
                                                        defaultValue={
                                                            formik.values[element] === null
                                                                ? ""
                                                                : formik.values[element]
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        } else if (element !== "makeAccount" && element !== "differentAddress") {
                                            return (
                                                <React.Fragment key={index}>
                                                    <input
                                                        type="text"
                                                        name={element}
                                                        placeholder={textData["checkout/form"]["form_details"][element]}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        defaultValue={
                                                            formik.values[element] === null
                                                                ? ""
                                                                : formik.values[element]
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export { LoginUserForm, RegisterUserForm, QuotationForm, ReferenceForm };
