import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Formik, useFormik } from "formik";
import axios from "axios";
import { LoginUserForm } from "./Forms/forms";
import { RegisterUserForm } from "./Forms/forms";
import { useGlobalState } from "./GlobalContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faGlobe } from "@fortawesome/pro-regular-svg-icons";
import { useMediaQuery } from "react-responsive";

export default function Login(props) {
    const { language } = useGlobalState();
    const [formButton, setFormButton] = useState(null);
    const [created, setCreated] = useState(false);
    const [loginForm, setLoginForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [handleLogin, setHandleLogin] = useState(false);
    const [featuredCases, setFeaturedCases] = useState({});
    const [loginContent, setLoginContent] = useState({});
    const [activeCase, setActiveCase] = useState(0);
    const [playVideo, setPlayVideo] = useState(false);
    const isResponsive = useMediaQuery({ query: "(max-width: 1056px)" });

    useEffect(() => {
        const fetchFeaturedCases = async () => {
            try {
                const response = await axios.get(window.location.origin + "/api/featured-cases");
                setFeaturedCases(response.data["featuredCases"]);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchLoginContent = async () => {
            try {
                const response = await axios.get(window.location.origin + "/api/login-content");
                setLoginContent(response.data["loginContent"]);
            } catch (error) {
                console.error(error);
            }
        };
        fetchFeaturedCases();
        fetchLoginContent();
    }, []);

    useEffect(() => {
        if (!!props.textData && !formButton) {
            if (!loginForm) {
                const saveQuotationBtnData = {
                    name: "save-quotation",
                    className: "save-quotation-btn",
                    icon: "save-quotation-icon",
                    text: props.textData["request_btn"],
                    type: "submit",
                };
                setFormButton(saveQuotationBtnData);
            }
        }
    }, [props.textData, loginForm]);

    const extractFirstNameAndLastName = (fullName) => {
        const [firstName, ...lastNameArray] = fullName.trim().split(" ");
        const lastName = lastNameArray.join(" ");

        return {
            firstName,
            lastName,
        };
    };

    const getCharactersFromString = (str, whiteSpaceIndex = str.indexOf(" ")) => {
        let characters = str.match(/[a-zA-Z]/g);
        characters.splice(whiteSpaceIndex, 0, " ");
        return characters ? characters.join("") : "";
    };

    const getNumbersFromString = (str) => {
        let numbers = str.match(/\d+/g);
        return numbers ? numbers.join("") : "";
    };

    const handleLoginSection = () => {
        setLoginForm(true);
        setErrorMessage({});
    };

    const formikLoginUserFormHandler = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: (values) => {
            const loginFormData = new FormData();
            loginFormData.append("email", values["email"]);

            axios
                .post(window.location.origin + "/api/login", loginFormData)
                .then(function (response) {
                    setHandleLogin(true);
                    localStorage.removeItem("registration-email");
                })
                .catch(function (error) {
                    setErrorMessage([props.textData["popups/login"]["error_message"]]);
                    localStorage.setItem("registration-email", values["email"]);
                });
        },
    });

    const handleNextCase = (event) => {
        const totalCases = Object.keys(featuredCases).length;

        if (totalCases <= 0) {
            return;
        }
        const eventAction = event.target.dataset.action;
        setActiveCase((prevActiveCase) => {
            if (eventAction === "previous") {
                return (prevActiveCase - 1 + totalCases) % totalCases;
            } else if (eventAction === "next") {
                return (prevActiveCase + 1) % totalCases;
            }
        });
    };

    return (
        <>
            {!!Object.keys(featuredCases).length && !!Object.keys(loginContent).length && !!language && (
                <>
                    {!isResponsive ? (
                        <div className="login-container">
                            <div className="left-side">
                                <div
                                    className="case-container"
                                    style={{ backgroundImage: `url(${featuredCases[activeCase].image[language]})` }}
                                >
                                    <div className="case-card">
                                        <div className="card-arrows">
                                            <span
                                                onClick={(e) => handleNextCase(e)}
                                                className="arrow-left"
                                                data-action="previous"
                                            ></span>
                                            <span
                                                onClick={(e) => handleNextCase(e)}
                                                className="arrow-right"
                                                data-action="next"
                                            ></span>
                                        </div>
                                        <div className="card-header">
                                            <h2 className="title">{featuredCases[activeCase].title[language]}</h2>
                                            <p className="description">
                                                {featuredCases[activeCase].description[language]}
                                            </p>
                                        </div>
                                        <div className="card-body">
                                            <a href={featuredCases[activeCase]["case_link"]} target="_blank">
                                                <div className="case-label">
                                                    <p className="subtitle">
                                                        {featuredCases[activeCase]["case_subtitle"][language]}
                                                    </p>
                                                    <h4 className="title">
                                                        {featuredCases[activeCase]["case_title"][language]}
                                                    </h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="login-header">
                                    <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                                    {/*@TODO: Fix hardcoded href */}
                                    <a href="https://www.oceanz.eu/">
                                        <div className="navigation-link">
                                            <div className="icon-container">
                                                <FontAwesomeIcon icon={faGlobe} size="xs" />
                                            </div>
                                            <p className="navi-text">Naar Oceanz.eu</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="content-container">
                                    <div className="video">
                                        <div className="player-wrapper">
                                            <a
                                                onClick={() => {
                                                    if (!playVideo) {
                                                        setPlayVideo(true);
                                                    }
                                                }}
                                                href="#"
                                                className={`play-button ${playVideo === true ? "hidden" : ""}`}
                                            ></a>
                                            <ReactPlayer
                                                url={`${window.location.origin}/storage/${loginContent["video_file"]}`}
                                                className="react-player"
                                                width="100%"
                                                height="auto"
                                                playing={playVideo}
                                                loop={true}
                                                controls={playVideo}
                                                onPlay={() => {
                                                    setPlayVideo(true);
                                                }}
                                                onPause={() => {
                                                    setPlayVideo(false);
                                                }}
                                            />
                                        </div>
                                        <div className="content">
                                            <h1 className="title">{loginContent["video_title"][language]}</h1>
                                            {parse(loginContent["video_content"][language])}
                                        </div>
                                    </div>
                                    <div className="interaction">
                                        <div className="login-component">
                                            {!created ? (
                                                <>
                                                    <h1>{props.textData["popups/login"]["title"]}</h1>
                                                    <div className="header">
                                                        {!handleLogin ? (
                                                            <>
                                                                <div className="description">
                                                                    {props.textData["popups/login"]["subtitle"]}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="description">
                                                                {props.textData["popups/login"]["success_message"]}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="content">
                                                        {!handleLogin && (
                                                            <>
                                                                <div className="content-header">
                                                                    <div className="title">
                                                                        {
                                                                            props.textData["popups/login"][
                                                                                "account_login_title"
                                                                            ]
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="content-body">
                                                                    <LoginUserForm
                                                                        formik={formikLoginUserFormHandler}
                                                                        errorMessage={errorMessage}
                                                                        textData={props.textData["popups/login"]}
                                                                        formButton={{
                                                                            name: "save-quotation",
                                                                            className: "save-quotation-btn",
                                                                            icon: "save-quotation-icon",
                                                                            text: props.textData["popups/login"][
                                                                                "login_btn"
                                                                            ],
                                                                            type: "submit",
                                                                        }}
                                                                        registerButton={{
                                                                            name: props.textData["popups/login"][
                                                                                "register_btn"
                                                                            ],
                                                                            path: props.textData["urls/register"][
                                                                                "unique_name"
                                                                            ],
                                                                        }}
                                                                        navigateToPageName={props.navigateToPageName}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="header">
                                                        {/*@TODO: add translation fields instead of hardcoded text */}
                                                        <div className="title">
                                                            {props.textData["popups/login"]["account_created"]}
                                                        </div>
                                                        <div className="description">
                                                            {props.textData["popups/login"]["check_email_to_login"]}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="contact-card">
                                            {!!loginContent["contact_name"][language] &&
                                            !!loginContent["contact_subtitle"][language] ? (
                                                <>
                                                    <div className="card-header">
                                                        <p className="description">
                                                            {loginContent["contact_text"][language]}
                                                        </p>
                                                    </div>
                                                    <div className="card-body">
                                                        <a
                                                            href={loginContent["contact_link"][language]}
                                                            target="_blank"
                                                        >
                                                            <div className="case-label">
                                                                <img
                                                                    className="contact-thumbnail"
                                                                    src={`${window.location.origin}/storage/${loginContent["contact_image"][language]}`}
                                                                />
                                                                <div className="contact-info">
                                                                    <p className="subtitle">
                                                                        {loginContent["contact_subtitle"][language]}
                                                                    </p>
                                                                    <h4 className="title">
                                                                        {loginContent["contact_name"][language]}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <a href={loginContent["contact_link"][language]} target="_blank">
                                                        <img
                                                            src={"/Oceanz_logo_nieuw_FC.png"}
                                                            className="logo"
                                                            alt="logo"
                                                        />
                                                    </a>
                                                    <button
                                                        onClick={() => {
                                                            window.location.replace(
                                                                "https://www.oceanz.eu/your-oceanz-is-vernieuwd/"
                                                            );
                                                        }}
                                                        className="temp-btn"
                                                    >
                                                        Your Oceanz is vernieuwd
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mobile-container">
                            <div className="login-header">
                                <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                                {/*@TODO: Fix hardcoded href */}
                                <a href="https://www.oceanz.eu/">
                                    <div className="navigation-link">
                                        <div className="icon-container">
                                            <FontAwesomeIcon icon={faGlobe} size="xs" />
                                        </div>
                                        {/* @TODO: Make section for content inside login navigation menu */}
                                        <p className="navi-text">Naar Oceanz.eu</p>
                                    </div>
                                </a>
                            </div>
                            <div className="login-component">
                                {!created ? (
                                    <>
                                        <h1>{props.textData["popups/login"]["title"]}</h1>
                                        <div className="header">
                                            {!handleLogin ? (
                                                <>
                                                    <div className="description">
                                                        {props.textData["popups/login"]["subtitle"]}
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="description">
                                                    {props.textData["popups/login"]["success_message"]}
                                                </div>
                                            )}
                                        </div>
                                        <div className="content">
                                            {!handleLogin && (
                                                <>
                                                    <div className="content-header">
                                                        <div className="title">
                                                            {props.textData["popups/login"]["account_login_title"]}
                                                        </div>
                                                    </div>
                                                    <div className="content-body">
                                                        <LoginUserForm
                                                            formik={formikLoginUserFormHandler}
                                                            errorMessage={errorMessage}
                                                            textData={props.textData["popups/login"]}
                                                            formButton={{
                                                                name: "save-quotation",
                                                                className: "save-quotation-btn",
                                                                icon: "save-quotation-icon",
                                                                text: props.textData["popups/login"]["login_btn"],
                                                                type: "submit",
                                                            }}
                                                            registerButton={{
                                                                name: props.textData["popups/login"]["register_btn"],
                                                                path: props.textData["urls/register"]["unique_name"],
                                                            }}
                                                            navigateToPageName={props.navigateToPageName}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="header">
                                            {/*@TODO: add translation fields instead of hardcoded text */}
                                            <div className="title">
                                                {props.textData["popups/login"]["account_created"]}
                                            </div>
                                            <div className="description">
                                                {props.textData["popups/login"]["check_email_to_login"]}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="contact-card">
                                {!!loginContent["contact_name"][language] &&
                                !!loginContent["contact_subtitle"][language] ? (
                                    <>
                                        <div className="card-header">
                                            <p className="description">{loginContent["contact_text"][language]}</p>
                                        </div>
                                        <div className="card-body">
                                            <a href={loginContent["contact_link"][language]} target="_blank">
                                                <div className="case-label">
                                                    <img
                                                        className="contact-thumbnail"
                                                        src={`${window.location.origin}/storage/${loginContent["contact_image"][language]}`}
                                                    />
                                                    <div className="contact-info">
                                                        <p className="subtitle">
                                                            {loginContent["contact_subtitle"][language]}
                                                        </p>
                                                        <h4 className="title">
                                                            {loginContent["contact_name"][language]}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <a href={loginContent["contact_link"][language]} target="_blank">
                                            <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                                        </a>
                                        <button
                                            onClick={() => {
                                                window.location.replace(
                                                    "https://www.oceanz.eu/your-oceanz-is-vernieuwd/"
                                                );
                                            }}
                                            className="temp-btn"
                                        >
                                            Your Oceanz is vernieuwd
                                        </button>
                                    </>
                                )}
                            </div>
                            <div className="cases-container">
                                <div
                                    className="case-container"
                                    style={{ backgroundImage: `url(${featuredCases[activeCase].image[language]})` }}
                                >
                                    <div className="case-card">
                                        <div className="card-arrows">
                                            <span
                                                onClick={(e) => handleNextCase(e)}
                                                className="arrow-left"
                                                data-action="previous"
                                            ></span>
                                            <span
                                                onClick={(e) => handleNextCase(e)}
                                                className="arrow-right"
                                                data-action="next"
                                            ></span>
                                        </div>
                                        <div className="card-header">
                                            <h2 className="title">{featuredCases[activeCase].title[language]}</h2>
                                            <p className="description">
                                                {featuredCases[activeCase].description[language]}
                                            </p>
                                        </div>
                                        <div className="card-body">
                                            <a href={featuredCases[activeCase]["case_link"]} target="_blank">
                                                <div className="case-label">
                                                    <p className="subtitle">
                                                        {featuredCases[activeCase]["case_subtitle"][language]}
                                                    </p>
                                                    <h4 className="title">
                                                        {featuredCases[activeCase]["case_title"][language]}
                                                    </h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="video">
                                <div className="player-wrapper">
                                    <a
                                        onClick={() => {
                                            if (!playVideo) {
                                                setPlayVideo(true);
                                            }
                                        }}
                                        href="#"
                                        className={`play-button ${playVideo === true ? "hidden" : ""}`}
                                    ></a>
                                    <ReactPlayer
                                        url={`${window.location.origin}/storage/${loginContent["video_file"]}`}
                                        className="react-player"
                                        width="100%"
                                        height="auto"
                                        playing={playVideo}
                                        loop={true}
                                        controls={playVideo}
                                        onPlay={() => {
                                            setPlayVideo(true);
                                        }}
                                        onPause={() => {
                                            setPlayVideo(false);
                                        }}
                                    />
                                </div>
                                <div className="content">
                                    <h1 className="title">{loginContent["video_title"][language]}</h1>
                                    {parse(loginContent["video_content"][language])}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
