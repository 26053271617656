export default function Label(props) {
    const { data, responsive } = props;

    const settings = () => {
        let settings = [];
        let count = 0;
        const getKey = () => {
            return (count = count + 1);
        };

        const pushSetting = (data, key) => {
            if (data[key] !== undefined && data[key] !== null) {
                if (data[key] === false || data[key] === "false") {
                    return;
                }
                let value = data[key];
                if (key === "custom") {
                    if (value === true || value === "Yes") {
                        value = "Custom";
                    } else {
                        return;
                    }
                } else if (key === "clinicalUse") {
                    if (value === true || value === "Yes") {
                        value = "Clinical";
                    } else {
                        return;
                    }
                } else if (key === "case") {
                    value = "Case: " + value;
                } else if (key === "practitioner") {
                    value = "Practitioner: " + value;
                } else if (key === "sterile" && typeof props.textDataLabel !== "undefined") {
                    value =
                        value === "true" || value === true || value === "Yes"
                            ? props.textDataLabel["sterile"].sterile
                            : props.textDataLabel["sterile"].not_sterile;
                } else if (value.toLowerCase().trim() === "no") {
                    return;
                } else if (value.toLowerCase().trim() === "no colouring") {
                    return;
                } else if (key === "application") {
                    // Check if this application type has mandatory options
                    if (props.mandatoryProductOptions !== null && props.mandatoryProductOptions !== undefined) {
                        const applications = props.mandatoryProductOptions.filter((app) => {
                            return app.application === value;
                        });

                        if (applications.length > 0) {
                            applications.forEach((app) => {
                                app.mandatory_options.forEach((mandatoryOption, index) => {
                                    if (
                                        (value === "Medical" &&
                                            (data["sterile"] === "true" || data["sterile"] === "Yes")) ||
                                        data["sterile"] === true ||
                                        value !== "Medical"
                                    ) {
                                        settings.push(
                                            <span key={`mandatory${key}-${index}`}>
                                                {`${props.textDataLabel["mandatory"]}`}
                                            </span>
                                        );
                                    }
                                });
                            });
                        }
                    }
                }
                if (value !== null && value !== undefined && value !== "") {
                    settings.push(<span key={data[key] + getKey()}>{value}</span>);
                }
            }
        };

        if (data !== undefined && data !== null) {
            const keys = [
                "application",
                "material",
                "color",
                "polish",
                "coating",
                "orientation",
                "sterile",
                "intendedUse",
            ];
            if (data.application === "Medical") {
                keys.push("clinicalUse");
                keys.push("custom");
                if (data.custom === true) {
                    keys.push("case");
                    keys.push("practitioner");
                }
            }

            keys.forEach((key) => {
                pushSetting(data, key);
            });
        }

        if (settings.length === 0) {
            // Check if data in local storage
            let modelsData = JSON.parse(localStorage.getItem("modelsData")) || [];
            if (modelsData.length > 0) {
                Object.keys(modelsData[0].data).forEach((key) => {
                    const dataSettings = modelsData[0].data[key].settings;
                    if (dataSettings !== null && dataSettings !== undefined) {
                        Object.keys(dataSettings).forEach((settingKey) => {
                            const settingValue = dataSettings[settingKey];
                            if (settingValue !== null && settingValue !== undefined) {
                                if (props.model && props.modelId === modelsData[0].data[key].id) {
                                    settings.push(<span key={settingKey}>{settingValue}</span>);
                                }
                            }
                        });
                    }
                });
            }
        }

        if (!props.showall) {
            if (settings.length > 3) {
                let len = settings.length;
                settings = settings.slice(0, 3);
                settings.push(<span key={"meer"}>+{len - 3} meer</span>);
            }
        }

        return settings;
    };

    return (
        <div>
            {!responsive && (
                <>
                    {typeof data === "object" && (
                        <>
                            {!!data &&
                                data.length > 0 &&
                                data.slice(0, 3).map((item, index) => <span key={index}>{item}</span>)}
                            {data.length >= 4 ? <span>+{data.length - 3} meer</span> : null}
                            {settings()}
                        </>
                    )}
                    {typeof data === "string" && (
                        <>
                            {!!data && (
                                <span className={"label-" + data.toLowerCase().replace(/\s+/g, "-")}>{data}</span>
                            )}
                        </>
                    )}
                    {typeof data === "undefined" && <>{settings()}</>}
                </>
            )}
            {!!responsive && (
                <>
                    {typeof data === "object" && (
                        <>
                            {!!data &&
                                data.length > 0 &&
                                data.slice(0, 2).map((item, index) => <span key={index}>{item}</span>)}
                            {data.length >= 4 ? <span>+{data.length - 3} meer</span> : null}
                            {settings()}
                        </>
                    )}
                    {typeof data === "string" && (
                        <>
                            {!!data && (
                                <span className={"label-" + data.toLowerCase().replace(/\s+/g, "-")}>{data}</span>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}
