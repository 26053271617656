import axios from "axios";
import ModelViewer from "./ModelViewer";

const updateModelColor = async (mdl, modelsData, color, orientation, handleScreenshot) => {
    return new Promise((resolve, reject) => {
        if (typeof modelsData !== "undefined" && modelsData.length > 0) {
            if (typeof modelsData[0].data !== "undefined" && modelsData[0].data.length > 0) {
                modelsData[0].data.forEach((model) => {
                    if (model.id === mdl.id) {
                        // Set orientation
                        let orient;
                        if (orientation !== null && mdl.checked === true) {
                            orient = orientation;
                        } else if (mdl.settings.orientation === "unlocked") {
                            orient = false;
                        } else {
                            orient = true;
                        }

                        // Make post request for each checked model to get the model data
                        const extension = /[^.]+$/.exec(model.file)[0];
                        if (model.cached_model === true) {
                            console.log(extension);
                            resolve(
                                <div div id={"modelviewer-container-" + model.id}>
                                    <ModelViewer
                                        extension={extension}
                                        modelData={[]}
                                        container={"modelviewer-container-" + model.id}
                                        handleScreenshot={handleScreenshot} // Callback to send the new model image data
                                        color={color}
                                        modelId={model.id}
                                        orientationLocked={orient}
                                        cachedData={true}
                                    />
                                </div>
                            );
                        } else {
                            let path = "";
                            if (typeof mdl.glb_path !== "undefined" && mdl.glb_path !== null) {
                                path = mdl.glb_path;
                            } else {
                                path = model.path;
                            }

                            let result = axios
                                .get(path, {
                                    responseType: "blob",
                                })
                                .then((response) => {
                                    const reader = new FileReader();
                                    reader.readAsArrayBuffer(response.data);
                                    return new Promise((resolve) => {
                                        reader.onload = function (event) {
                                            // Create a new modelviewer to render the model with the new color
                                            const arrayBuffer = reader.result;

                                            if (typeof mdl.glb_path !== "undefined") {
                                                resolve(
                                                    <div id={"modelviewer-container-" + model.id}>
                                                        <ModelViewer
                                                            model={path}
                                                            container={"modelviewer-container-" + model.id}
                                                            handleScreenshot={handleScreenshot} // Callback to send the new model image data
                                                            orientationLocked={orient}
                                                            color={color}
                                                            modelId={model.id}
                                                        />
                                                    </div>
                                                );
                                            } else {
                                                resolve(
                                                    <div id={"modelviewer-container-" + model.id}>
                                                        <ModelViewer
                                                            extension={extension}
                                                            modelData={arrayBuffer}
                                                            container={"modelviewer-container-" + model.id}
                                                            handleScreenshot={handleScreenshot} // Callback to send the new model image data
                                                            orientationLocked={orient}
                                                            color={color}
                                                            modelId={model.id}
                                                        />
                                                    </div>
                                                );
                                            }
                                        };
                                    });
                                });
                            result.then((result) => {
                                resolve(result);
                            });
                        }
                    }
                });
            }
        }
    });
};

export { updateModelColor };
