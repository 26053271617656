import { useEffect, useRef, useState } from "react";
import { useGlobalState } from "../GlobalContext";
import axios from "axios";

export default function Payment(props) {
    const allowedPayments = ["ideal", "paypal", "banktransfer"];
    const [paymentMethods, setPaymentMethods] = useState([]);
    const { loggedInUser } = useGlobalState();

    const hiddenSelects = useRef([]);

    useEffect(() => {
        // Get payments methods
        const mollieAPI = window.location.protocol + "//" + window.location.host + "/api/payment-methods";
        axios
            .get(mollieAPI)
            .then((response) => {
                // Filter out the allowed payments
                let filtered = Object.keys(response.data.paymentMethods).filter(function (payment) {
                    let allow = false;
                    allowedPayments.forEach(function (allowed) {
                        if (response.data.paymentMethods[payment].id === allowed) {
                            allow = true;
                        }
                    });
                    return allow;
                });

                filtered = filtered.map(function (payment) {
                    return response.data.paymentMethods[payment];
                });

                // Add pay by wire (op rekening) option, and disregard the other options
                if (loggedInUser.company !== null && loggedInUser.company.allow_pay_by_wire == true) {
                    filtered = [
                        {
                            description: props.textData["checkout/form"]["pay_by_wire"],
                            id: "paybywire",
                        },
                    ];
                }
                setPaymentMethods(filtered);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        props.setPaymentMethods(paymentMethods);
    }, [paymentMethods]);

    function changePayment(e) {
        // Hide all
        hiddenSelects.current.forEach(function (select) {
            select.style.display = "none";
        });

        // Show issuers if needed
        let find = document.getElementsByName("issuers-" + e.target.value);
        if (find.length > 0) {
            hiddenSelects.current.push(find[0].parentNode);
            find[0].parentNode.style.display = "grid";
        }

        let issuerSelect = document.getElementsByClassName("payment-issuer")[0];
        let computedStyle = issuerSelect.style.display;
        props.setPaymentChoice((prev) => {
            return {
                ...prev,
                service: e.target.parentNode.innerText,
                issuer:
                    computedStyle === "grid"
                        ? document.getElementsByClassName("payment-issuer")[0].firstChild.value
                        : null,
            };
        });
    }

    function selectIssuer(e) {
        props.setPaymentChoice((prev) => {
            return {
                ...prev,
                issuer: e.target.value,
            };
        });
    }

    return (
        <div>
            <div className="payment">
                {Object.keys(paymentMethods).map(function (key, index) {
                    let expand = false;
                    if (paymentMethods[index].issuers && paymentMethods[index].issuers.length > 0) {
                        expand = paymentMethods[index].issuers.map(function (issuer) {
                            return (
                                <option value={issuer.id} key={issuer.id}>
                                    {issuer.name}
                                </option>
                            );
                        });
                    }

                    return (
                        <div key={paymentMethods[index].id}>
                            <label className="custom-checkbox">
                                <input type="radio" name="payment" value={index} onChange={changePayment} />
                                <span className="checkmark"></span>
                                {paymentMethods[index].description}
                            </label>
                            <div className="payment-issuer" onChange={selectIssuer}>
                                {expand && <select name={"issuers-" + index}>{expand}</select>}
                            </div>
                        </div>
                    );
                })}
                {paymentMethods.length === 0 && (
                    <>
                        <div className="generic-spinner">
                            <span className="spinner"></span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
