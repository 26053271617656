function excludeSettings(productSettingsExclusions, allowedCombinations, productSettings) {
    // Filter productSettings and set state
    if (productSettingsExclusions.length > 0) {
        // Get all allowed applications so that we can keep these (since they are explicitly allowed, we cannot filter these out)
        const allowedApplications = allowedCombinations
            .map((combination) => {
                const keysExceptApplication = Object.keys(combination).filter(
                    (key) => key !== "application" && key !== "id" && key !== "isAllowed"
                );
                const allOthersAreNull = keysExceptApplication.every((key) => combination[key] === null);
                if (allOthersAreNull && combination.isAllowed) {
                    return combination.application;
                }
                return null;
            })
            .filter((application) => application !== null);

        // Filter applications first (meaning, only the application is added for the filter and no other options)
        const applicationExclusions = productSettingsExclusions.filter((exclusion) => {
            const keysExceptApplication = Object.keys(exclusion).filter(
                (key) => key !== "application" && key !== "id" && key !== "isAllowed"
            );
            const allOthersAreNull = keysExceptApplication.every((key) => exclusion[key] === null);

            if (allOthersAreNull && !allowedApplications.includes(exclusion.application)) {
                return allOthersAreNull;
            }
        });

        let filteredSettings = productSettings.filter((settings) => {
            let found = false;
            applicationExclusions.forEach((exclusion) => {
                if (
                    settings.name === exclusion.application &&
                    !isInAllowedCombinations(allowedCombinations, settings)
                ) {
                    found = true;
                }
            });
            return !found;
        });

        // Filter each application's settings
        filteredSettings = filteredSettings.map((application) => {
            const filtered = application.settings.filter((combination) => {
                if (isInAllowedCombinations(allowedCombinations, combination)) {
                    return true;
                }

                let found = false;
                productSettingsExclusions.forEach((exclusion) => {
                    // Filter out the fields that are null and thus don't need to be checked
                    const filteredFields = Object.keys(exclusion)
                        .filter(
                            (key) =>
                                key !== "id" && key !== "isAllowed" && exclusion[key] !== null && key !== "application"
                        ) // we filter application
                        .reduce((acc, key) => {
                            acc[key] = exclusion[key];
                            return acc;
                        }, {});

                    // Check if the combination matches the exclusion
                    const matches = Object.keys(filteredFields).map((key) => {
                        if (typeof combination[key] === "object" && combination[key] !== null) {
                            return combination[key].name === filteredFields[key];
                        } else {
                            if (typeof filteredFields[key] === "boolean") {
                                return (
                                    combination[key] === filteredFields[key] ||
                                    combination[key] === null ||
                                    combination[key] === undefined
                                );
                            }
                            return combination[key] === filteredFields[key];
                        }
                    });

                    // If all fields match, the combination is excluded
                    if (matches.length > 0 && matches.every((match) => match === true)) {
                        // console.log("excluded", combination);
                        // Check if application also matches (if present)
                        if (
                            (exclusion.application === undefined || exclusion.application === null) &&
                            exclusion.application === application.name
                        ) {
                            found = true;
                        } else if (
                            exclusion.application === undefined ||
                            exclusion.application === null ||
                            exclusion.application === application.name
                        ) {
                            found = true;
                        }
                    }
                });
                return !found;
            });

            return {
                ...application,
                settings: filtered,
            };
        });
        return filteredSettings;
    }
    return productSettings;
}

const isInAllowedCombinations = (allowedCombinations, combination) => {
    // Define the key mapping
    const keyMapping = {
        clinicalUse: "clinical_use",
        coating: "coating",
        color: "color",
        custom: "custom",
        id: "id",
        intendedUse: "intended_use",
        material: "material",
        orientation: "orientation",
        polish: "polishing",
        sterile: "sterile",
    };

    let found = false;
    allowedCombinations.forEach((allowedCombination) => {
        const matches = Object.keys(keyMapping)
            .filter((key) => key !== "id" && key !== "isAllowed")
            .map((key) => {
                const allowedKey = keyMapping[key];
                if (typeof combination[key] === "object" && combination[key] !== null) {
                    return (
                        combination[key].name === allowedCombination[allowedKey] ||
                        allowedCombination[allowedKey] === null
                    );
                } else {
                    return (
                        combination[key] === allowedCombination[allowedKey] || allowedCombination[allowedKey] === null
                    );
                }
            });

        if (matches.every((match) => match === true)) {
            found = true;
        }
    });
    return found;
};

export { excludeSettings };
