import * as THREE from "three";

const setRotationToLongestPoint = (mesh) => {
    // Get the dimensions of the model
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const dimensions = new THREE.Vector3();
    boundingBox.getSize(dimensions);

    // Determine the largest side
    let largestSide;
    if (dimensions.x >= dimensions.y && dimensions.x >= dimensions.z) {
        largestSide = "x";
    } else if (dimensions.y >= dimensions.x && dimensions.y >= dimensions.z) {
        largestSide = "y";
    } else {
        largestSide = "z";
    }

    // 2. Calculate the rotation axis perpendicular to the largest side
    let rotationAxis;

    switch (largestSide) {
        case "x":
            // rotate so that the model is facing up
            mesh.rotateZ(THREE.MathUtils.degToRad(90));
            break;
        case "z":
            mesh.rotateX(THREE.MathUtils.degToRad(-90));
            break;
        case "y":
        default:
            // Do nothing, no rotation required
            break;
    }
};

export { setRotationToLongestPoint };
