const createScreenshot = (props, renderer, scene, loadedGltf = null, dimensions = null) => {
    if (typeof props.handleScreenshot !== "undefined" && props.handleScreenshot !== null) {
        if (typeof props.tempId !== "undefined" && props.tempId !== null) {
            props.handleScreenshot(renderer.domElement.toDataURL("image/png"), props.tempId);
        } else {
            props.handleScreenshot(
                renderer.domElement.toDataURL("image/png"),
                props.modelId ? props.modelId : null,
                props.color
            );
        }
    } else if (typeof props.handleScreenshotOnUpload !== "undefined" && props.handleScreenshotOnUpload !== null) {
        // .stp and .step files
        if (loadedGltf !== false && loadedGltf !== null) {
            if (typeof props.tempId !== "undefined" && props.tempId !== null) {
                props.handleScreenshotOnUpload(
                    renderer.domElement.toDataURL("image/png"),
                    props.tempId,
                    loadedGltf,
                    dimensions
                );
            } else {
                props.handleScreenshotOnUpload(
                    renderer.domElement.toDataURL("image/png"),
                    props.modelId ? props.modelId : null,
                    loadedGltf,
                    dimensions
                );
            }
        } else {
            if (typeof props.tempId !== "undefined" && props.tempId !== null) {
                props.handleScreenshotOnUpload(
                    renderer.domElement.toDataURL("image/png"),
                    props.tempId,
                    null,
                    dimensions
                );
            }
        }
    }
    cleanUp(props, scene);
};

const createScreenshotStep = (props, renderer, scene, loadedGltf) => {
    if (typeof props.handleScreenshotOnUpload !== "undefined" && props.handleScreenshotOnUpload !== null) {
        // .stp and .step files
        if (loadedGltf !== false) {
            if (typeof props.tempId !== "undefined" && props.tempId !== null) {
                props.handleScreenshotOnUpload(renderer.domElement.toDataURL("image/png"), props.tempId, loadedGltf);
            } else {
                props.handleScreenshotOnUpload(
                    renderer.domElement.toDataURL("image/png"),
                    props.modelId ? props.modelId : null,
                    loadedGltf
                );
            }
        }
    }
    cleanUp(props, scene);
};

const cleanUp = (props, scene) => {
    // Remove the canvas
    if (
        document.getElementById(props.container) !== null &&
        document.getElementById(props.container).firstChild !== null
    ) {
        document.getElementById(props.container).firstChild.remove();
    }

    // Clear scene
    while (scene.children.length > 0) {
        scene.remove(scene.children[0]);
    }
};

export { createScreenshot, createScreenshotStep };
