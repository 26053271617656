import Popup from "reactjs-popup";
import React, { useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import axios from "axios";
import { ReferenceForm } from "../Forms/forms";
import { useGlobalState } from "../GlobalContext";
import RetrieveProductSettings from "../ProductSettings/RetrieveProductSettings";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Link } from "react-router-dom";
import { getSettingsId } from "../ProductSettings/Helper";

export default function QuotationPopup(props) {
    const { productSettings, setProductSettings, setMandatoryProductSettings, loggedInUser } = useGlobalState();
    const [formButton, setFormButton] = useState(null);
    const [created, setCreated] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [handleLogin, setHandleLogin] = useState(false);
    const [lastCreation, setLastCreation] = useState(null);

    useEffect(() => {
        loadProductSettings();
    }, []);

    useEffect(() => {
        if (!!props.textData && !formButton) {
            const saveQuotationBtnData = {
                name: "save-quotation",
                className: "save-quotation-btn popup-quotation-btn",
                icon: "save-quotation-icon",
                text: props.textData["request_btn"],
                type: "submit",
            };
            setFormButton(saveQuotationBtnData);
        }
    }, [props.textData]);

    const loadProductSettings = () => {
        return RetrieveProductSettings({ setProductSettings, setMandatoryProductSettings });
    };

    const extractFirstNameAndLastName = (fullName) => {
        const [firstName, ...lastNameArray] = fullName.trim().split(" ");
        const lastName = lastNameArray.join(" ");

        return {
            firstName,
            lastName,
        };
    };

    const getCharactersFromString = (str, whiteSpaceIndex = str.indexOf(" ")) => {
        let characters = str.match(/[a-zA-Z]/g);
        characters.splice(whiteSpaceIndex, 0, " ");
        return characters ? characters.join("") : "";
    };

    const getNumbersFromString = (str) => {
        let numbers = str.match(/\d+/g);
        return numbers ? numbers.join("") : "";
    };

    const onPopupClose = () => {
        props.closePopup(false);
        setErrorMessage({});
        setHandleLogin(false);
    };

    const formikReferenceFormHandler = useFormik({
        initialValues: {
            reference: "",
        },
        onSubmit: (values) => {
            onlyCreateQuotation(values["reference"]);
        },
    });

    const openPopup = () => {
        props.closePopup(true);
    };

    const onlyCreateQuotation = (reference) => {
        // Check if lastCreation date was older than a minute
        if (lastCreation !== null && new Date() - lastCreation < 5000) {
            return;
        }
        props.showLoadingSpinner();
        setLastCreation(new Date());

        // Check if user is impersonating
        // get id impersonating
        const impersonating =
            document.getElementById("impersonate") !== null &&
            typeof document.getElementById("impersonate") !== "undefined";

        createQuotationRequest(loggedInUser.id, reference, impersonating);
    };

    const createQuotationRequest = (userid, reference, impersonating = false) => {
        const quotationAPI = window.location.origin + "/api/quotation";
        let modelsData = JSON.parse(localStorage.getItem("modelsData")) || [];
        let productsData = JSON.parse(localStorage.getItem("productsData")) || [];

        if (modelsData.length === 0 && productsData.length === 0) {
            return;
        }
        const quotationData = {
            user_id: userid,
            reference: reference,
            models: [],
            products: [],
            impersonating,
        };

        if (productsData.length > 0) {
            quotationData.products = productsData;
        }

        let containsPriceOnRequest = false;
        if (modelsData.length > 0 && modelsData[0].data.length > 0) {
            modelsData[0].data.forEach((model) => {
                if (/[a-z]/i.test(model.price)) {
                    containsPriceOnRequest = true;
                }
                // @TODO: add discount codes in quotationData object
                const settingsId = getSettingsId(model.settings, productSettings);
                const modelData = {
                    model_id: model.id,
                    settings: settingsId,
                    delivery_time: model.delivery + "",
                    amount: model.amount,
                    price: model.price + "",
                    discount_multiplier: model.discount_multiplier,
                };

                if (typeof model.settings.clinicalUse !== "undefined" && model.settings.clinicalUse !== null) {
                    modelData.case = model.settings.case;
                    modelData.practitioner = model.settings.practitioner;
                }

                quotationData.models.push(modelData);
            });
        }

        // Save new shipping costs to db
        axios
            .post(quotationAPI, quotationData)
            .then((response) => {
                localStorage.setItem("modelsData", JSON.stringify([]));
                props.onModelChange((prev) => {
                    console.log({
                        ...prev,
                        data: [],
                    });
                    return [{ ...prev[0], data: [] }];
                });

                if (impersonating) {
                    axios.post(window.location.origin + "/stopimpersonating").then((response) => {
                        window.location.href = window.location.origin + "/admin/aanvragen";
                    });
                } else {
                    props.navigateToPageName("account_quotations");
                }

                console.log(props);
                toast.success(props.textData["saved_quotation"], {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                if (containsPriceOnRequest) {
                    toast.success(props.textData["we_are_working_on_price_on_request"], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
            .catch((error) => {
                toast.error(props.textData["error_quotation"], {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    };

    return (
        <>
            {!!props.textData && (
                <Popup open={props.openState} modal nested className="quotation-popup" onClose={() => onPopupClose()}>
                    {(close) => (
                        <div className="modal">
                            <button className="close" onClick={close}>
                                &times;
                            </button>
                            {!created ? (
                                <>
                                    <Link to={"/"}>
                                        <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                                    </Link>
                                    <div className="header">
                                        {!handleLogin ? (
                                            <>
                                                <div className="title">{props.textData["title"]}</div>
                                                <div className="description">{props.textData["subtitle"]}</div>
                                            </>
                                        ) : (
                                            <div className="description">{props.loginData["success_message"]}</div>
                                        )}
                                    </div>

                                    <div className="content">
                                        {!handleLogin && (
                                            <>
                                                <div className="content-header">
                                                    <div className="title">{props.textData["reference"]}</div>
                                                </div>
                                                <div className="content-body">
                                                    <ReferenceForm
                                                        formik={formikReferenceFormHandler}
                                                        errorMessage={errorMessage}
                                                        textData={props.textData}
                                                        formButton={formButton}
                                                        navigateToPageName={props.navigateToPageName}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Link to={"/"}>
                                        <img src={"/Oceanz_logo_nieuw_FC.png"} className="logo" alt="logo" />
                                    </Link>
                                    <div className="header">
                                        {/*@TODO: add translation fields instead of hardcoded text */}
                                        <div className="title">{props.textData["account_created"]}</div>
                                        <div className="description">{props.textData["check_email_to_login"]}</div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </Popup>
            )}
        </>
    );
}
