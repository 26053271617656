import Table from "../Table/Table";
import React, { useEffect, useState } from "react";
import ContactBlock from "../ContentBlock/ContactBlock";
import { useGlobalState } from "../GlobalContext";
import axios from "axios";

export default function ProductLibraryOverview(props) {
    const { textData, responsive } = props;
    const [libraryData, setLibraryData] = useState();
    const [filteredLibraryData, setFilteredLibraryData] = useState();
    const [selectedFilter, setSelectedFilter] = useState("all");
    const { loggedInUser } = useGlobalState();

    useEffect(() => {
        axios
            .get(window.location.origin + "/api/product/libraries", {
                params: {
                    company_id: loggedInUser.company.id,
                },
            })
            .then((response) => {
                let result = response.data;
                result.section = "library";
                setLibraryData([result]);
                setFilteredLibraryData([result]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const selectFilter = (filter) => {
        let filters = getFilters();

        if (!filters.includes(filter)) {
            return;
        }

        let result = libraryData;
        if (typeof libraryData !== "undefined" && libraryData !== null) {
            setSelectedFilter(filter.toLowerCase());

            // If filter is all, return all libraries
            if (filter === "all") {
                result.section = "library";
                setFilteredLibraryData(result);
                return;
            }

            // Get all libraries where the filter is in the categories
            let libraries = libraryData[0].filter((library) => {
                if (typeof library.categories !== "undefined" && library.categories !== null) {
                    library.categories = library.categories.map((category) => {
                        return category.toLowerCase();
                    });
                    return library.categories.includes(filter);
                }
            });

            // Set the section to library
            result = libraries;
            result.section = "library";
            setFilteredLibraryData([result]);
        }
    };

    const getFilters = () => {
        let filters = [];
        if (typeof libraryData !== "undefined" && libraryData !== null) {
            libraryData[0].forEach((library) => {
                if (typeof library.categories !== "undefined" && library.categories !== null) {
                    library.categories.forEach((filter) => {
                        if (
                            typeof filter !== "undefined" &&
                            filter !== null &&
                            !filters.includes(filter.toLowerCase())
                        ) {
                            filters.push(filter.toLowerCase());
                        }
                    });
                }
            });
        }
        filters.unshift("all");
        return filters;
    };

    return (
        <>
            <h1>{textData["account/product_library"]["menu_item_title"]}</h1>
            <br />
            {!!filteredLibraryData && (
                <>
                    {getFilters().length > 0 && (
                        // Loop filters and return button for each
                        <div className="filter-buttons">
                            {getFilters().map((filter, index) => {
                                return (
                                    <button
                                        key={index}
                                        className={
                                            selectedFilter === filter.toLowerCase()
                                                ? "filter-button selected"
                                                : "filter-button"
                                        }
                                        onClick={() => {
                                            selectFilter(filter);
                                        }}
                                    >
                                        {filter.charAt(0).toUpperCase() + filter.slice(1)}
                                    </button>
                                );
                            })}
                        </div>
                    )}
                    <div className="checkout_button_container">
                        <button
                            className="to_checkout_button"
                            onClick={() => {
                                props.navigateToPageName("checkout");
                            }}
                        >
                            {textData["account/product_library"]["to_checkout"]}
                        </button>
                        <button
                            className="create_quotation_button"
                            onClick={() => {
                                props.navigateToPageName("home");
                            }}
                        >
                            {textData["account/product_library"]["create_quotation"]}
                        </button>
                    </div>
                    <Table
                        setProductsData={props.setProductsData}
                        responsive={responsive}
                        data={filteredLibraryData}
                        textData={!!textData && textData["account/product_library"]}
                        allTextData={textData}
                        textDataLabel={!!textData && textData["sections/label"]}
                        navigateToPageName={props.navigateToPageName}
                    />
                    {!!responsive && !!textData && !!textData["content_blocks/contact"] && (
                        <ContactBlock responsive={responsive} textData={textData["content_blocks/contact"]} />
                    )}
                </>
            )}
            {(typeof libraryData === "undefined" || libraryData === null) && (
                <>
                    <div className="generic-spinner">
                        <span className="spinner"></span>
                    </div>
                </>
            )}
        </>
    );
}
