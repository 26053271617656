import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactDOM from "react-dom/client";
import Home from "./components/Home";
import Checkout from "./components/Checkout/Checkout";
import { GlobalProvider, useGlobalState } from "./components/GlobalContext";
import ProtectedRoute from "./components/ProtectedRoute";
import "../css/app.scss";
import { useEffect, useRef, useState } from "react";
import EditQuotation from "./components/EditQuotation";
import Page from "./components/Page";
import RetrieveProductSettings from "./components/ProductSettings/RetrieveProductSettings";
import { ToastContainer } from "react-toastify";
import ErrorComponent from "./components/ErrorComponent";
import Login from "./components/Login";
import Register from "./components/Register";

import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY ?? "",
    plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const App = () => {
    return (
        <ErrorBoundary FallbackComponent={ErrorComponent}>
            <GlobalProvider>
                <Router>
                    <Inner />
                </Router>
            </GlobalProvider>
        </ErrorBoundary>
    );
};

const Inner = () => {
    const {
        setCurrentPage,
        setProductSettings,
        setMandatoryProductSettings,
        setLoggedInUser,
        generalSettings,
        setGeneralSettings,
    } = useGlobalState();
    const [authenticated, setAuthenticated] = useState(false);
    const [textData, setTextData] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const [maxWidthText, setMaxWidthText] = useState(0);
    const stagingWarning = useRef();

    const fetchTextData = () => {
        // Make a GET request for the text sections.
        const textDataAPI = window.location.origin + "/api/content/data";
        return axios
            .get(textDataAPI)
            .then((response) => {
                setTextData(response.data);
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        // Check if user is logged in
        const fetchLoginData = async () => {
            try {
                const response = await axios.get(window.location.origin + "/currentuser");
                const loggedIn = !response.data.error;
                setAuthenticated(loggedIn);
                setLoggedInUser(response.data);
                if (response && response.data) {
                    Bugsnag.setUser(response?.data?.user?.id, response?.data?.user?.email, response?.data?.user?.name);
                    Bugsnag.addMetadata("company", {
                        id: response?.data?.company?.id,
                        name: response?.data?.company?.name,
                    });
                }
                initGeneralSettings();
            } catch (error) {
                console.error(error);
                setAuthenticated(false);
            } finally {
                initLoadPage();
            }
        };
        fetchLoginData();
    }, []);

    // Calculate the maximum width of the div for the development warning message
    useEffect(() => {
        const div = stagingWarning.current;
        const handleResize = () => {
            if (div) {
                const divWidth = div.clientWidth;

                // Calculate the maximum number of characters that can fit within the div's width
                const avgCharWidth = 8; // Adjust this value based on your font size
                const maxChars = Math.floor(divWidth / avgCharWidth);

                // Generate a repeated string to fill the calculated width, with a space at the end
                const repeatedText = "Dit is een ontwikkelingsomgeving-".repeat(
                    Math.ceil(maxChars / "Dit is een ontwikkelingsomgeving-".length)
                );

                setMaxWidthText(repeatedText);
            }
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const initLoadPage = () => {
        // Load product settings if not set
        RetrieveProductSettings({ setMandatoryProductSettings, setProductSettings }, "en");

        // Load text data if not set
        if (textData === null || typeof textData === "undefined") {
            fetchTextData().then((data) => {
                setCurrentPage(getCurrentRouteName(location.pathname, data));
            });
        } else {
            setCurrentPage(getCurrentRouteName(location.pathname, textData));
        }
    };

    const initGeneralSettings = () => {
        // Set general settings if not set
        if (generalSettings.length === 0 || generalSettings === null || typeof generalSettings === "undefined") {
            let lang = "en"; // todo: make dynamic
            axios
                .get(window.location.origin + "/api/general-settings/" + lang)
                .then((response) => {
                    setGeneralSettings(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const navigateToPageName = (unique_name, optional_params = null) => {
        if (textData !== null && typeof textData !== "undefined") {
            let page = getPageUrlByName(unique_name);
            if (optional_params !== null) {
                navigate(page.path + optional_params);
            } else {
                navigate(page.path);
            }
        }
    };

    const getPageUrlByName = (unique_name) => {
        let urls = getUrlsFromTextData(textData);
        let titles = getTitles(urls);
        let page = titles.find((item) => item.unique_name === unique_name);
        return page;
    };

    const getUrlsFromTextData = (txtData) => {
        let urls = [];
        // Get all urls from textData that start with urls/ in the key
        for (const key in txtData) {
            if (key.startsWith("urls/")) {
                urls.push(txtData[key]);
            }
        }
        return urls;
    };

    // Returns the route name based on the current path
    const getCurrentRouteName = (path, txtData) => {
        let urls = getUrlsFromTextData(txtData);
        let titles = getTitles(urls);
        let currentRouteName = titles.find((item) => item.path === path);
        if (typeof currentRouteName !== "undefined") {
            return currentRouteName.unique_name;
        } else {
            return null;
        }
    };

    // Loop over the textData and return all title and path pairs
    const getTitles = (pages) => {
        let titles = [];

        for (const page of pages) {
            if (page.title) {
                titles.push({ title: page.title, path: page.path, unique_name: page.unique_name });
            }

            for (const key in page) {
                if (typeof page[key] === "object" && page[key] !== null) {
                    const subTitles = getTitles([page[key]]);
                    titles = titles.concat(subTitles);
                }
            }
        }

        return titles;
    };

    return (
        <>
            {(window.location.origin === "http://localhost" ||
                window.location.origin === "https://your-oceanz.draad.dev") && (
                <div ref={stagingWarning} className="staging-warning">
                    {maxWidthText}
                </div>
            )}
            {!!textData && (
                <Routes>
                    <Route
                        exact
                        path={textData["urls/login"]["path"]}
                        title={textData["urls/login"]["title"]}
                        element={
                            <Login
                                title={textData["urls/login"]["title"]}
                                currentPage={textData["urls/login"]["unique_name"]}
                                authenticated={authenticated}
                                textData={textData}
                                navigateToPageName={navigateToPageName}
                            />
                        }
                    />
                    <Route
                        exact
                        path={textData["urls/register"]["path"]}
                        title={textData["urls/register"]["title"]}
                        element={
                            <Register
                                title={textData["urls/register"]["title"]}
                                currentPage={textData["urls/register"]["unique_name"]}
                                authenticated={authenticated}
                                textData={textData}
                                navigateToPageName={navigateToPageName}
                            />
                        }
                    />
                    <Route element={<ProtectedRoute isAuthenticated={authenticated} />}>
                        <Route
                            exact
                            path={textData["urls/home"]["path"]}
                            title={textData["urls/home"]["title"]}
                            element={
                                <Home
                                    selectedMenuItem={"navigate-quotation"}
                                    title={textData["urls/home"]["title"]}
                                    currentPage={textData["urls/home"]["unique_name"]}
                                    authenticated={authenticated}
                                    textData={textData}
                                    navigateToPageName={navigateToPageName}
                                    getPageUrlByName={getPageUrlByName}
                                    initLoadPage={initLoadPage}
                                />
                            }
                        />
                        <Route
                            exact
                            title={textData["urls/checkout"]["title"]}
                            path={textData["urls/checkout"]["path"]}
                            element={
                                <Checkout
                                    authenticated={authenticated}
                                    title={textData["urls/checkout"]["title"]}
                                    currentPage={textData["urls/checkout"]["unique_name"]}
                                    selectedMenuItem={"navigate-account"}
                                    navigateToPageName={navigateToPageName}
                                    getPageUrlByName={getPageUrlByName}
                                    textData={textData}
                                />
                            }
                        />
                        <Route
                            exact
                            title={textData["urls/account"]["subpages"]["orders"]["title"]}
                            path={textData["urls/account"]["subpages"]["orders"]["path"]}
                            element={
                                <Home
                                    currentPage={textData["urls/account"]["subpages"]["orders"]["unique_name"]}
                                    selectedMenuItem={"navigate-account"}
                                    authenticated={authenticated}
                                    title={textData["urls/account"]["subpages"]["orders"]["title"]}
                                    navigateToPageName={navigateToPageName}
                                    getPageUrlByName={getPageUrlByName}
                                    textData={textData}
                                    initLoadPage={initLoadPage}
                                />
                            }
                        />
                        <Route
                            exact
                            title={textData["urls/account"]["subpages"]["quotations"]["overview"]["title"]}
                            path={textData["urls/account"]["subpages"]["quotations"]["overview"]["path"]}
                            element={
                                <Home
                                    currentPage={
                                        textData["urls/account"]["subpages"]["quotations"]["overview"]["unique_name"]
                                    }
                                    authenticated={authenticated}
                                    title={textData["urls/account"]["subpages"]["quotations"]["overview"]["title"]}
                                    selectedMenuItem={"navigate-account"}
                                    navigateToPageName={navigateToPageName}
                                    getPageUrlByName={getPageUrlByName}
                                    textData={textData}
                                    initLoadPage={initLoadPage}
                                />
                            }
                        />
                        <Route
                            exact
                            title={textData["urls/account"]["subpages"]["quotations"]["edit"]["title"]}
                            path={textData["urls/account"]["subpages"]["quotations"]["edit"]["path"]}
                            element={
                                <EditQuotation
                                    currentPage={
                                        textData["urls/account"]["subpages"]["quotations"]["edit"]["unique_name"]
                                    }
                                    authenticated={authenticated}
                                    title={textData["urls/account"]["subpages"]["quotations"]["edit"]["title"]}
                                    selectedMenuItem={"navigate-quotation"}
                                    navigateToPageName={navigateToPageName}
                                    getPageUrlByName={getPageUrlByName}
                                    textData={textData}
                                    initLoadPage={initLoadPage}
                                />
                            }
                        />
                        <Route
                            exact
                            title={textData["urls/account"]["subpages"]["products"]["library"]["title"]}
                            path={textData["urls/account"]["subpages"]["products"]["library"]["path"]}
                            element={
                                <Home
                                    currentPage={
                                        textData["urls/account"]["subpages"]["products"]["library"]["unique_name"]
                                    }
                                    authenticated={authenticated}
                                    title={textData["urls/account"]["subpages"]["products"]["library"]["title"]}
                                    selectedMenuItem={"navigate-account"}
                                    navigateToPageName={navigateToPageName}
                                    getPageUrlByName={getPageUrlByName}
                                    textData={textData}
                                    initLoadPage={initLoadPage}
                                />
                            }
                        />
                        <Route
                            exact
                            path={textData["urls/account"]["subpages"]["settings"]["path"]}
                            title={textData["urls/account"]["subpages"]["settings"]["title"]}
                            element={
                                <Home
                                    currentPage={textData["urls/account"]["subpages"]["settings"]["unique_name"]}
                                    authenticated={authenticated}
                                    title={textData["urls/account"]["subpages"]["settings"]["title"]}
                                    selectedMenuItem={"navigate-account"}
                                    navigateToPageName={navigateToPageName}
                                    getPageUrlByName={getPageUrlByName}
                                    textData={textData}
                                    initLoadPage={initLoadPage}
                                />
                            }
                        />
                        <Route
                            exact
                            path={textData["urls/account"]["subpages"]["details"]["path"]}
                            title={textData["urls/account"]["subpages"]["details"]["title"]}
                            element={
                                <Home
                                    currentPage={textData["urls/account"]["subpages"]["details"]["unique_name"]}
                                    authenticated={authenticated}
                                    title={textData["urls/account"]["subpages"]["details"]["title"]}
                                    selectedMenuItem={"navigate-account"}
                                    navigateToPageName={navigateToPageName}
                                    getPageUrlByName={getPageUrlByName}
                                    textData={textData}
                                    initLoadPage={initLoadPage}
                                />
                            }
                        />
                        <Route path={"/:slug"} element={<Page textData={textData} />} />
                    </Route>
                </Routes>
            )}
            <ToastContainer />
        </>
    );
};

ReactDOM.createRoot(document.getElementById("app")).render(<App />);
