import { useState, useEffect } from "react";
import InfoButton from "../InfoButton";
import InformationPopup from "../../Popups/InformationPopup";

export default function Sterile(props) {
    const [selected, setSelected] = useState(null);
    const [buttons, setButtons] = useState([]);
    const [clicked, setClicked] = useState(false);
    const [popupButtonHtml, setPopupButtonHtml] = useState(null);
    const [popupHtml, setPopupHtml] = useState(null);

    useEffect(() => {
        renderButtons();
    }, [props.sterileOptions]);

    useEffect(() => {
        if (clicked === true) {
            setTimeout(() => {
                // Submit the form
                submit();
            }, 100);
        }
    }, [selected]);

    function clickSelect(e, i) {
        let included = false;
        props.sterileOptions.forEach((option) => {
            if (option === true) {
                included = true;
            }
        });

        // bugfix for when the user clicks on the same button after popup
        if (!included && typeof (selected === "undefined" || selected === null)) {
            props.updateSetting("sterile", false);
            props.goNext("overview");
            props.clearSettingsThatAreNotAllowed(false, "sterile");
        }

        select(e, i);
        setClicked(true);
    }

    function closeInfoPopup() {
        setPopupHtml(null);
        let modals = document.getElementsByClassName("modal-background");
        if (typeof modals !== "undefined" && modals.length > 0) {
            modals[0].remove();
        }
    }

    function openPopup(e) {
        const body = document.getElementsByTagName("body")[0];
        const modalBackground = document.createElement("div");
        modalBackground.classList.add("modal-background");
        modalBackground.addEventListener("click", closeInfoPopup);
        body.appendChild(modalBackground);

        setPopupHtml(
            <InformationPopup
                content={
                    <div class="sterile-popup">
                        <div className="header-img-container --no-bg">
                            <h1>Information regarding 3D Medical Models</h1>
                            <h3>ISO-13485 certified platform for medical class IIa devices</h3>
                        </div>
                        <div className="info-text">
                            <span className="close-btn" onClick={closeInfoPopup}></span>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `<p>
                            To meet all requirements and guidelines of the MDR, we ask you to order your 3D-printed medical devices
                            for sterile use (class IIa), from Oceanz 3D Medical Models B.V.
                        </p>
                        <p>
                            You can contact 3D Medical Models for more information, and you can create an account at<br>
                            <a href="https://oceanz.3d-customize.com/" target="_blank">https://oceanz.3d-customize.com/</a>
                        </p><br>
                        <div class="sterile-photos">
                            <img src="/photo1.jpeg" width="150" />
                            <img src="/photo2.jpeg" width="150" />
                        </div>
                        <div class="sterile-info">
                            <img src="/3dmedical.png" />
                            <div class="contact-info">
                                <p>
                                    <span class="label">t</span> <span class="value">+31(0) 852 017 406</span>
                                </p>
                                <p>
                                    <span class="label">e</span> <span class="value"><a href="mailto:info@3dmedicalmodels.nl">info@3dmedicalmodels.nl</a></span>
                                </p>
                                <p>
                                    <span class="label">w</span> <span class="value"><a href="http://www.3dmedicalmodels.nl" target="_blank">www.3dmedicalmodels.nl</a></span>
                                </p>
                            </div>
                        </div>
                        `,
                                }}
                            />
                        </div>
                    </div>
                }
            />
        );
        console.log("openPopup");
        setClicked(false);
    }

    function includePopupLinkIfNoYesButton() {
        // Check if the yes options is included in sterileOptions
        let included = false;
        props.sterileOptions.forEach((option) => {
            if (option === true) {
                included = true;
            }
        });

        if (!included) {
            return (
                <div className="material-container">
                    <button
                        className={`btn btn-option`}
                        value={"Yes"}
                        onClick={(e) => {
                            openPopup(e);
                        }}
                    >
                        {props.textData.yes_btn}
                    </button>
                </div>
            );
        }
    }

    function renderButtons() {
        const buttonsArray = Object.entries(props.sterileOptions).map(([key, value], i) => (
            <div className="material-container" key={i}>
                <button
                    className={`btn btn-option 
                    ${props.config.sterile === null && i === 0 ? "selected" : ""}
                    ${JSON.parse(props.config.sterile) === value ? "selected" : ""}`}
                    value={value}
                    onClick={(e) => clickSelect(e, i)}
                >
                    {value ? props.textData.yes_btn : props.textData.no_btn}
                </button>
            </div>
        ));
        setPopupButtonHtml(includePopupLinkIfNoYesButton());
        setButtons(buttonsArray);
        // set selected to selected
        if (props.config.sterile !== null) {
            setSelected(document.getElementsByClassName("btn-option selected")[0]);
        }
    }

    function select(e, i) {
        // Remove selected class from all buttons
        const container = document.getElementsByClassName("materials-settings-details");
        const buttons = container[0].getElementsByClassName("btn-option");
        for (var i = 0; i < buttons.length; i++) buttons[i].classList.remove("selected");
        // Add selected class to clicked button
        setSelected(e.target);
        e.target.classList.add("selected");
    }

    function submit() {
        if (selected !== null) {
            props.updateSetting("sterile", selected.value);
        } else {
            // Check if there is a button with state selected
            let btn = document.getElementsByClassName("btn-option selected")[0];
            if (btn !== null && typeof btn !== "undefined") {
                props.updateSetting("sterile", btn.value);
            }
        }
        props.goNext("overview");
        props.clearSettingsThatAreNotAllowed(false, "sterile");
    }

    function goBack() {
        props.goNext("application");
    }

    return (
        <div className="materials-settings-details">
            {popupHtml}
            <h1>{props.textData.title}</h1>
            <br />
            <p className="subtitle">{props.textData.subtitle}</p>
            <br />
            <h2 className="h2-info-parent">{props.textData.header}</h2>
            <InfoButton
                info={props.productSettingExplanations.filter((info) => {
                    return info.type === "sterile";
                })}
                textData={props.infoTextData}
            />
            <div className="material-btns">
                {buttons}
                {popupButtonHtml}
            </div>
            {props.sterileOptions !== null && props.sterileOptions.length > 0 ? (
                ""
            ) : (
                <p>{props.textData.not_available}</p>
            )}
        </div>
    );
}
