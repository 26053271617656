import * as THREE from "three";

const calculateTriangleArea = (a, b, c) => {
    const ab = new THREE.Vector3();
    const ac = new THREE.Vector3();
    ab.subVectors(b, a);
    ac.subVectors(c, a);
    ab.cross(ac);
    return 0.5 * ab.length();
};

export const calculateSurfaceAreaSTL = (geometry) => {
    // Calculate the surface area
    let surfaceArea = 0.0;
    const vertices = geometry.attributes.position.array;
    for (let i = 0; i < vertices.length; i += 9) {
        const a = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
        const b = new THREE.Vector3(vertices[i + 3], vertices[i + 4], vertices[i + 5]);
        const c = new THREE.Vector3(vertices[i + 6], vertices[i + 7], vertices[i + 8]);
        surfaceArea += calculateTriangleArea(a, b, c);
    }
    return surfaceArea;
};

export const calculateSurfaceAreaSTEP3MF = (geometry) => {
    if (geometry.index !== null && typeof geometry.index !== "undefined") {
        return calculateSurfaceAreaIndexed(geometry) / 100;
    } else {
        return calculateSurfaceAreaNonIndexed(geometry) / 100;
    }
};

const calculateSurfaceAreaNonIndexed = (geometry) => {
    let surfaceArea = 0.0;
    const vertices = geometry.attributes.position.array;
    for (let i = 0; i < vertices.length; i += 9) {
        const a = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
        const b = new THREE.Vector3(vertices[i + 3], vertices[i + 4], vertices[i + 5]);
        const c = new THREE.Vector3(vertices[i + 6], vertices[i + 7], vertices[i + 8]);
        surfaceArea += calculateTriangleArea(a, b, c);
    }
    return surfaceArea;
};

const calculateSurfaceAreaIndexed = (geometry) => {
    let surfaceArea = 0.0;
    const indices = geometry.index.array;
    const positions = geometry.attributes.position.array;

    for (let i = 0; i < indices.length; i += 3) {
        const vertexIndexA = indices[i] * 3;
        const vertexIndexB = indices[i + 1] * 3;
        const vertexIndexC = indices[i + 2] * 3;

        const a = new THREE.Vector3(positions[vertexIndexA], positions[vertexIndexA + 1], positions[vertexIndexA + 2]);
        const b = new THREE.Vector3(positions[vertexIndexB], positions[vertexIndexB + 1], positions[vertexIndexB + 2]);
        const c = new THREE.Vector3(positions[vertexIndexC], positions[vertexIndexC + 1], positions[vertexIndexC + 2]);

        surfaceArea += calculateTriangleArea(a, b, c);
    }

    return surfaceArea;
};

export const getVolume = (geometry) => {
    if (!geometry.isBufferGeometry) {
        console.log("'geometry' must be an indexed or non-indexed buffer geometry");
        return 0;
    }
    var isIndexed = geometry.index !== null;
    let position = geometry.attributes.position;
    let sum = 0;
    let p1 = new THREE.Vector3(),
        p2 = new THREE.Vector3(),
        p3 = new THREE.Vector3();
    if (!isIndexed) {
        let faces = position.count / 3;
        for (let i = 0; i < faces; i++) {
            p1.fromBufferAttribute(position, i * 3 + 0);
            p2.fromBufferAttribute(position, i * 3 + 1);
            p3.fromBufferAttribute(position, i * 3 + 2);
            sum += signedVolumeOfTriangle(p1, p2, p3);
        }
    } else {
        let index = geometry.index;
        let faces = index.count / 3;
        for (let i = 0; i < faces; i++) {
            p1.fromBufferAttribute(position, index.array[i * 3 + 0]);
            p2.fromBufferAttribute(position, index.array[i * 3 + 1]);
            p3.fromBufferAttribute(position, index.array[i * 3 + 2]);
            sum += signedVolumeOfTriangle(p1, p2, p3);
        }
    }
    return sum / 1000;
};

function signedVolumeOfTriangle(p1, p2, p3) {
    return p1.dot(p2.cross(p3)) / 6.0;
}
