import { useState, useEffect } from "react";
import InfoButton from "../InfoButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export default function ClinicalUse(props) {
    const [selected, setSelected] = useState(null);
    const [buttons, setButtons] = useState([]);
    const [clicked, setClicked] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [showTextFields, setShowTextFields] = useState(false);

    useEffect(() => {
        renderButtons();
    }, [props.clinicalUseOptions]);

    useEffect(() => {
        // if (clicked === true) {
        //     let btn = document.getElementsByClassName("btn-option selected")[0];
        //     if (btn !== null && typeof btn !== "undefined") {
        //         if (btn.value === "true") {
        //             setShowCustom(true);
        //         }
        //     }
        // }
    }, [selected]);

    function clickSelect(e, i) {
        select(e, i);
        setClicked(true);
    }

    function renderButtons() {
        const buttonsArray = Object.entries(props.clinicalUseOptions).map(([key, value], i) => (
            <button
                className={`btn btn-option btn-option-clinical 
                ${props.config.clinicalUse === null && i === 0 ? "selected" : ""}
                ${props.config.clinicalUse === value ? "selected" : ""}`}
                key={i}
                onClick={(e) => clickSelect(e, i)}
                value={value}
            >
                {value === true ? props.textData.yes_btn : props.textData.no_btn}
            </button>
        ));
        setButtons(buttonsArray);
    }

    function select(e, i) {
        // Remove selected class from all buttons
        if (Array.from(e.target.classList).includes("btn-option-clinical")) {
            const container = document.getElementsByClassName("application-settings-details");
            const buttons = container[0].getElementsByClassName("btn-option-clinical");
            for (var i = 0; i < buttons.length; i++) buttons[i].classList.remove("selected");
            if (e.target.value === "true") {
                setShowCustom(true);
            } else {
                setShowCustom(false);
            }
        } else if (Array.from(e.target.classList).includes("btn-option-custom")) {
            const container = document.getElementsByClassName("application-settings-details");
            const buttons = container[0].getElementsByClassName("btn-option-custom");
            for (var i = 0; i < buttons.length; i++) buttons[i].classList.remove("selected");
            if (e.target.value === "true") {
                setShowTextFields(true);
            } else {
                setShowTextFields(false);
            }
        }
        // Add selected class to clicked button
        setSelected(e.target);
        e.target.classList.add("selected");
        // setTimeout(() => {
        //     // Submit the form
        //     submit();
        // }, 100);
    }

    function submit() {
        let custom = null;
        let clinicalUse = null;
        let _case = null;
        let practitioner = null;

        let submit = false;
        // Check if it is clinical use
        let btn = document.getElementsByClassName("btn-option-clinical selected")[0];
        if (btn !== null && typeof btn !== "undefined") {
            submit = true;
            clinicalUse = btn.value === "true" ? true : false;
        }

        // Check if it is custom
        btn = document.getElementsByClassName("btn-option-custom selected")[0];
        if (btn !== null && typeof btn !== "undefined") {
            btn.value === "true" ? (submit = false) : (submit = true);
            custom = btn.value === "true" ? true : false;
        }

        // Check if textfields are filled in
        if (document.getElementsByClassName("text-fields").length) {
            let textFields = Array.from(document.getElementsByClassName("text-fields")[0].children);
            let allTrue = true;
            textFields.forEach((field) => {
                if (field.name === "case") {
                    _case = field.value;
                } else if (field.name === "practitioner") {
                    practitioner = field.value;
                }

                if (field.value === "" || field.value === null) {
                    allTrue = false;
                    field.classList.add("border-error");
                    toast.error(props.textData[field.id + "_error"], {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            });
            if (allTrue) {
                submit = true;
            }
        }

        if (submit) {
            props.updateSetting("clinicalForm", {
                case: _case,
                custom: custom,
                practitioner: practitioner,
                clinicalUse: clinicalUse,
            });
            props.goNext("overview");
            props.clearSettingsThatAreNotAllowed(false, "clinicalUse");
        }
    }

    function goBack() {
        props.goNext("application");
    }

    return (
        <div className="application-settings-details">
            <h1>{props.textData.title}</h1>
            <br />
            <p className="subtitle">{props.textData.subtitle}</p>
            <br />
            <h2 className="h2-info-parent">{props.textData.header}</h2>
            <InfoButton
                info={props.productSettingExplanations.filter((info) => {
                    return info.type === "clinicalUse";
                })}
                textData={props.infoTextData}
            />
            <div className="application-btns">{buttons}</div>
            {props.clinicalUseOptions !== null && props.clinicalUseOptions.length > 0 ? (
                ""
            ) : (
                <p>{props.textData.not_available}</p>
            )}
            {(showCustom || props.config.clinicalUse === true) && (
                <>
                    <br></br>
                    <h2 className="h2-info-parent">{props.textData.custom_header}</h2>
                    <div className="application-btns">
                        <button
                            className={
                                props.config.custom !== null && props.config.custom === false
                                    ? "btn btn-option btn-option-custom selected"
                                    : "btn btn-option btn-option-custom"
                            }
                            onClick={(e) => clickSelect(e, 0)}
                            value={false}
                        >
                            {props.textData.no_btn}
                        </button>
                        <button
                            className={
                                props.config.custom !== null && props.config.custom === true
                                    ? "btn btn-option btn-option-custom selected"
                                    : "btn btn-option btn-option-custom"
                            }
                            onClick={(e) => clickSelect(e, 1)}
                            value={true}
                        >
                            {props.textData.yes_btn}
                        </button>
                    </div>
                </>
            )}
            {((showCustom && showTextFields) ||
                (props.config.clinicalUse === true && showTextFields) ||
                (props.config.custom !== null && props.config.custom === true)) && (
                <>
                    <br></br>
                    <h2 className="h2-info-parent">{props.textData.please_fill_in}</h2>
                    <div className="text-fields">
                        <input
                            type="text"
                            name="case"
                            id="case"
                            placeholder={props.textData.case}
                            defaultValue={
                                typeof props.config.case !== "undefined" && props.config.case !== null
                                    ? props.config.case
                                    : ""
                            }
                        />
                        <input
                            type="text"
                            name="practitioner"
                            id="practitioner"
                            placeholder={props.textData.practitioner}
                            defaultValue={
                                typeof props.config.practitioner !== "undefined" && props.config.practitioner !== null
                                    ? props.config.practitioner
                                    : ""
                            }
                        />
                    </div>
                </>
            )}
            <button className="btn btn-save" onClick={submit}>
                {props.textData.save_btn}
            </button>
        </div>
    );
}
